<template>
  <div v-if="content" class="section">
    <template v-for="(section, index) in content">
      <spar-accordion
        v-if="section.content?.[0]?.component === RegisteredComponents.SparAccordion"
        :key="'section' + index"
        v-bind="getProps<AccordionSection>(section)"
      />
      <spar-anchor
        v-else-if="section.content?.[0]?.component === RegisteredComponents.SparAnchor"
        :key="'spar-anchor' + index"
        v-bind="getProps<AnchorSection>(section)"
      />
      <spar-banner
        v-else-if="section.content?.[0]?.component === RegisteredComponents.SparBanner"
        :key="'spar-banner' + index"
        v-bind="getProps<Banner>(section)"
      />
      <spar-contact-form
        v-else-if="section.content?.[0]?.component === RegisteredComponents.SparContactForm"
        :key="'spar-contact-form' + index"
        v-bind="getProps<ContactForm>(section)"
      />
      <spar-content-slider
        v-else-if="section.content?.[0]?.component === RegisteredComponents.SparContentSlider"
        :key="'spar-content-slider' + index"
        v-bind="getProps<ContentSliderSection>(section)"
      />
      <spar-cookie-list
        v-else-if="section.content?.[0]?.component === RegisteredComponents.SparCookieList"
        :key="'spar-cookie-list' + index"
        v-bind="getProps<CookielistSection>(section)"
      />
      <spar-flexible-form
        v-else-if="section.content?.[0]?.component === RegisteredComponents.SparFlexibleForm"
        :key="'spar-flexible-form' + index"
        v-bind="getProps<SparFlexibleFormProps>(section)"
      />
      <spar-heading
        v-else-if="section.content?.[0]?.component === RegisteredComponents.SparHeading"
        :key="'spar-heading' + index"
        v-bind="getProps<HeadingSection>(section)"
      />
      <spar-hero
        v-else-if="section.content?.[0]?.component === RegisteredComponents.SparHero"
        :key="'spar-hero' + index"
        v-bind="getProps<HeroSection>(section)"
      />
      <spar-image-teaser
        v-else-if="section.content?.[0]?.component === RegisteredComponents.SparImageTeaser"
        :key="'spar-image-teaser' + index"
        v-bind="getProps<ImageTeaser>(section)"
      />
      <spar-image-text-teaser
        v-else-if="section.content?.[0]?.component === RegisteredComponents.SparImageTextTeaser"
        :key="'spar-image-text-teaser' + index"
        v-bind="getProps<ImageTextTeaser>(section)"
      />
      <spar-inspiration-world
        v-else-if="section.content?.[0]?.component === RegisteredComponents.SparInspirationWorld"
        :key="'spar-inspiration-world' + index"
        v-bind="getProps<InspirationWorldsSection>(section)"
      />
      <spar-teaserblock
        v-else-if="section.content?.[0]?.component === RegisteredComponents.SparTeaserblock"
        :key="'spar-teaserblock' + index"
        v-bind="getProps<TeaserblockSection>(section)"
      />
      <spar-text-image-video
        v-else-if="section.content?.[0]?.component === RegisteredComponents.SparTextImageVideo"
        :key="'spar-text-image-video' + index"
        v-bind="getProps<TextImageVideoSection>(section)"
      />
      <spar-text-teaser
        v-else-if="section.content?.[0]?.component === RegisteredComponents.SparTextTeaser"
        :key="'spar-text-teaser' + index"
        v-bind="getProps<TextTeaser>(section)"
      />
      <spar-usp
        v-else-if="section.content?.[0]?.component === RegisteredComponents.SparUsp"
        :key="'spar-usp' + index"
        v-bind="getProps<UspSection>(section)"
      />

      <!-- This DIV is used to trigger a error log for an unregistered component-->
      <div
        v-else
        :key="'not-found' + index"
        :html="logError(section.content?.[0]?.component || '')"
      />
    </template>
  </div>
</template>

<script lang="ts" setup>
import type {
  AccordionSection,
  AnchorSection,
  Banner,
  ContactForm,
  ContentSliderSection,
  CookielistSection,
  FlexibleForm,
  HeadingSection,
  HeroSection,
  ImageTeaser,
  ImageTextTeaser,
  InspirationWorldsSection,
  Reference,
  ReferenceContentSections,
  TeaserblockSection,
  TextImageVideoSection,
  TextTeaser,
  UspSection,
} from "@shop-storefront/utils";
import SparAccordion from "~/components/sections/SparAccordion/SparAccordion.vue";
import SparAnchor from "~/components/sections/SparAnchor/SparAnchor.vue";
import SparBanner from "~/components/sections/SparBanner/SparBanner.vue";
import SparContactForm from "~/components/sections/SparContactForm/SparContactForm.vue";
import SparContentSlider from "~/components/sections/SparContentSlider/SparContentSlider.vue";
import SparCookieList from "~/components/sections/SparCookieList/SparCookieList.vue";
import type {
  FieldsType,
  SparFlexibleFormProps,
} from "~/components/sections/SparFlexibleForm/SparFlexibleForm.types";
import SparFlexibleForm from "~/components/sections/SparFlexibleForm/SparFlexibleForm.vue";
import SparHeading from "~/components/sections/SparHeading/SparHeading.vue";
import SparHero from "~/components/sections/SparHero/SparHero.vue";
import SparImageTeaser from "~/components/sections/SparImageTeaser/SparImageTeaser.vue";
import SparImageTextTeaser from "~/components/sections/SparImageTextTeaser/SparImageTextTeaser.vue";
import SparInspirationWorld from "~/components/sections/SparInspirationWorld/SparInspirationWorld.vue";
import SparTeaserblock from "~/components/sections/SparTeaserblock/SparTeaserblock.vue";
import SparTextImageVideo from "~/components/sections/SparTextImageVideo/SparTextImageVideo.vue";
import SparTextTeaser from "~/components/sections/SparTextTeaser/SparTextTeaser.vue";
import SparUsp from "~/components/sections/SparUsp/SparUsp.vue";
import { RegisteredComponents } from "./RenderSections.types";

defineProps({
  content: {
    type: Array as PropType<Reference[]>,
    default: () => [],
  },
});

function getProps<T extends ReferenceContentSections | SparFlexibleFormProps>(
  section: Reference,
): T {
  switch (section.content?.[0]?.component) {
    // Flexible Form needs special treatment because the fields contain Contentstack built-in
    // fields which are not automatically typed by Contentstack TS-gen
    case RegisteredComponents.SparFlexibleForm:
      return {
        emailConfiguration: (section.content[0] as FlexibleForm).emailConfiguration,
        formConfiguration: (section.content[0] as FlexibleForm).formConfiguration,
        confirmation: (section.content[0] as FlexibleForm).confirmation,
        fields: (section.content[0] as FlexibleForm).fields as FieldsType,
      } as unknown as T;

    default:
      return section.content?.[0] as T;
  }
}

const logError = (component: string) => {
  Log.error(LogArea.app, "Unregistered component", component);
};
</script>
