<template>
  <div v-if="!loading" class="spar-search">
    <ul class="spar-search__categories">
      <spar-search-category
        v-for="category in categories"
        :key="category.key"
        :label="category.label"
        :count="searchResults[category.key].totalHits || 0"
        @click="jumpTo(category.key)"
      />
    </ul>

    <div class="spar-search__categories-header">
      <h3 :id="SearchCategory.products">
        {{ $t("search.groups.products.title") }} ({{
          searchResults[SearchCategory.products].totalHits
        }})
      </h3>
      <spar-link
        v-if="searchResults[SearchCategory.products].totalHits"
        icon="arrow-right"
        icon-position="right"
        :variant="LinkVariant.linkIcon"
        tosca-prefix="plp-showall"
        @click="onProductsLinkClick"
      >
        {{ $t("search.actions.show_all_generic") }}
      </spar-link>
    </div>

    <section v-if="searchResults[SearchCategory.products].totalHits" class="spar-search__results">
      <div class="spar-search__results-tiles">
        <spar-product-tile
          v-for="(product, index) in products"
          v-bind="product"
          :key="index"
        ></spar-product-tile>
      </div>
      <div class="spar-search__results-button">
        <spar-button
          :label="
            $t('search.actions.show_all', {
              count: searchResults[SearchCategory.products].totalHits?.toString() || '0',
              group: $t('search.groups.products.title'),
            })
          "
          data-tosca="plp-showall-btn"
          @click="onProductsLinkClick"
        />
      </div>
    </section>
    <p v-else>{{ $t("search.groups.products.no_results") }}</p>

    <div class="spar-search__content-results">
      <div class="spar-search__content-results-tabs">
        <ul class="spar-search__categories spar-search__categories--vertical">
          <spar-search-category
            v-for="category in categoriesWithoutProducts"
            :key="category.key"
            :label="category.label"
            :count="searchResults[category.key].totalHits || 0"
          />
        </ul>
      </div>
      <div class="spar-search__content-results-data">
        <p>This is a placeholder for search results</p>
      </div>
    </div>
  </div>
  <!-- TODO: Needs refinement for A11y AND optimization for loader per category -->
  <div v-else class="spar-search__loader">
    <spar-loader />
  </div>
</template>

<script setup lang="ts">
import {
  SparButton,
  LinkVariant,
  SparLink,
  SparLoader,
  SparSearchCategory,
} from "~/components/shared";
import SparProductTile from "~/components/shared/SparProductTile/SparProductTile.vue";
import useI18n from "~/composables/i18n/useI18n";
import { useSearchStore } from "~/stores/search.store";
import { scrollTo } from "~/utils/ui";
import { SearchCategory } from "./SparSearch.types";

const { $t } = useI18n();
const { searchResults } = useSearchStore();
const { loading } = storeToRefs(useSearchStore());

const categories = computed(() => {
  const keys = Object.keys(SearchCategory);
  const res = keys.map((key) => {
    return {
      key,
      label: $t(`search.groups.${key}.title`),
    };
  });
  return res;
});

const categoriesWithoutProducts = computed(() =>
  categories.value.filter((category) => category.key !== SearchCategory.products),
);

const products = computed(() => {
  const { resolvedProducts } = searchResults[SearchCategory.products];
  if (!resolvedProducts) return [];
  return resolvedProducts.slice(0, 4);
});

const jumpTo = (anchor: string) => {
  const elem = document.getElementById(anchor);
  if (!elem) return;
  scrollTo(elem);
};

const onProductsLinkClick = () => {
  alert("Fact Finder PLP not implemented yet");
};
</script>

<style lang="scss">
@use "./SparSearch.scss";
@use "~/pages/category/category.scss";
</style>
