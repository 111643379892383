import type { RequiredPaymentDetailsProps } from "@vsf-enterprise/sapcc-types";
import {
  type InitPaymentProviderResponse,
  type PayOneTokenizer,
  type PaymentModes,
  PaymentProvider,
  PaymentStatus,
  type PaymentProviderPayment,
} from "~/components/feature/SparCheckout/SparCheckoutPayment/SparCheckoutPayment.types";
import useNotification, {
  NotificationTimeout,
  NotificationType,
} from "~/components/shared/SparNotification/useNotification";
import { useLoadScript } from "~/composables/load-script/useLoadScript";
import { useCheckoutStore } from "~/stores/checkout.store";
import { CartError, handleCommerceError } from "~/utils/error";
import type { PaymentDetails } from "../../types/occ.types";
import type { PaymentDetailsBody } from "./payment.types";

export default function usePaymentModes() {
  const { loadScript } = useLoadScript();
  const { cartContext } = storeToRefs(useCheckoutStore());
  const runtimeConfig = useRuntimeConfig();
  const router = useRouter();
  const { sdk } = useVsfSdk();
  const { $t } = useNuxtApp();
  const { pushNotification } = useNotification();

  const selectedPaymentMode: Ref<null | string> = ref(null);
  const selectedPaymentProvider: Ref<null | PaymentProvider> = ref(null);
  const paymentModeStatus: Ref<PaymentStatus | undefined> = ref(undefined);
  const initPaymentProviderResponse: Ref<InitPaymentProviderResponse | null> = ref(null);
  const isPaymentModeConfirmed: Ref<boolean | undefined> = ref(undefined);
  const paymentModes = ref<PaymentModes | null>(null);
  const getPaymentModesLoading = ref(false);

  // PayOne
  const isPayOneLoaded = ref(false);
  const isPayOneError = ref(false); // Error while loading script
  const isPayOneTokenizerError = ref(false); // Data error
  const payOneLoadTimeout: Ref<NodeJS.Timeout | null> = ref(null);
  const payOneTokenizer: Ref<PayOneTokenizer | null> = ref(null);
  const payOneHostedTokenizationId: Ref<string | undefined> = ref(undefined);
  const payOneSavedCards: Ref<PaymentProviderPayment[]> = ref([]);
  const payOneAddedCards: Ref<PaymentProviderPayment[]> = ref([]);

  //PayPal
  const isPayPalError = ref(false);
  const savedPayPalAccounts: Ref<PaymentProviderPayment[]> = ref([]);
  const isPayPalSave = ref(false);
  const isPayPalLoading = ref(false);

  const initPayOne = async () => {
    if (isPayOneError.value || !cartContext.value || !selectedPaymentMode.value)
      return (paymentModeStatus.value = PaymentStatus.error);

    selectedPaymentProvider.value = PaymentProvider.payOne;

    // If external PAYONE script is not loaded yet, skip execution of this function and re-run after a short timeout
    if (!isPayOneLoaded.value) {
      payOneLoadTimeout.value = setTimeout(() => {
        if (!selectedPaymentMode.value) return;
        selectPaymentMode(selectedPaymentMode.value);
      }, 500);
      return;
    }

    interface FetchInitPaymentProviderResult {
      data: InitPaymentProviderResponse;
    }

    try {
      const { data: initPaymentProvider } = (await sdk.commerce.initPaymentProvider(
        cartContext.value.getCartId(),
        cartContext.value.getUserId(),
        selectedPaymentMode.value,
        cartContext.value.bs,
        true,
      )) as FetchInitPaymentProviderResult;

      initPaymentProviderResponse.value = initPaymentProvider;
      extractSavedPayOneCards();
      paymentModeStatus.value = PaymentStatus.initialized;
    } catch (error) {
      handleCommerceError(error as Error);
      paymentModeStatus.value = PaymentStatus.error;
    }
  };

  interface SavedPayments {
    payments: PaymentProviderPayment[];
  }

  interface SavedPayPalAccountsResult {
    data: SavedPayments;
  }

  const initPayPal = async (paymentMode: string, isSetPayPalPayment = true) => {
    const isValidPaymentMode = Object.values(PaymentProvider).some(
      (provider) => provider.toString() === paymentMode,
    );
    if (
      !isValidPaymentMode ||
      isPayPalError.value ||
      !cartContext.value ||
      !selectedPaymentMode.value
    )
      return (paymentModeStatus.value = PaymentStatus.error);

    // reset paypal vars because we can have multiple instances
    isPayPalError.value = false;
    savedPayPalAccounts.value = [];
    isPayPalLoading.value = false;

    // Check if saved Paypal account exists
    try {
      const { data: savedPayments } = (await sdk.commerce.getPaymentDetails(
        cartContext.value.getUserId(),
        selectedPaymentMode.value,
        cartContext.value.bs,
      )) as SavedPayPalAccountsResult;

      paymentModeStatus.value = PaymentStatus.initialized;
      savedPayPalAccounts.value = [...savedPayments.payments];
      isPayPalSave.value = savedPayPalAccounts.value.length > 0;
      selectedPaymentProvider.value = paymentMode as PaymentProvider;

      if (isSetPayPalPayment) {
        setPayPalPayment();
      }
    } catch (error) {
      handleCommerceError(error as Error);
      paymentModeStatus.value = PaymentStatus.error;
    }
  };

  const showWidget = async (tokenId = "") => {
    // Check if widget was initialized before -> reinitialize!
    if (paymentModeStatus.value === PaymentStatus.ready && selectedPaymentMode.value) {
      await selectPaymentMode(selectedPaymentMode.value);
    }

    paymentModeStatus.value = PaymentStatus.initialized;

    // PAYONE
    if (selectedPaymentProvider.value === PaymentProvider.payOne) {
      try {
        await payOneTokenize(tokenId);
        paymentModeStatus.value = PaymentStatus.ready;
      } catch (error: unknown) {
        paymentModeStatus.value = PaymentStatus.error;
      }
    }
  };

  const extractSavedPayOneCards = () => {
    const paymentDetails = initPaymentProviderResponse.value?.paymentDetails?.payments;

    if (!paymentDetails || !paymentDetails.length) {
      return;
    }

    payOneSavedCards.value = paymentDetails;
  };

  const clearPayOneLoadTimeout = () => {
    if (payOneLoadTimeout.value) clearTimeout(payOneLoadTimeout.value);
  };

  // Throws errors which are caught in calling function
  const payOneTokenize = async (tokenId = "") => {
    if (!initPaymentProviderResponse.value) return;

    const hostedTokenizationUrl =
      initPaymentProviderResponse.value.initPaymentProviderResponse.paymentProviderUrl;
    const widgetContainer = "payment-content-" + (tokenId || selectedPaymentMode.value);
    const widgetContainerElement = document.getElementById(widgetContainer);
    if (!widgetContainerElement) throw new Error("Container not found");
    // Clear existing widget
    widgetContainerElement.innerHTML = "";

    payOneTokenizer.value = new window.Tokenizer(
      hostedTokenizationUrl,
      widgetContainer,
      {
        hideCardholderName: false,
      },
      tokenId,
    ) as PayOneTokenizer;

    await payOneTokenizer.value.initialize();
  };

  const selectPaymentMode = async (paymentMode: string) => {
    paymentModeStatus.value = PaymentStatus.loading;
    selectedPaymentMode.value = paymentMode;
    isPaymentModeConfirmed.value = undefined;
    isPayOneTokenizerError.value = false;

    // PAYONE
    if (paymentMode.substring(0, 6) === "payone") {
      await initPayOne();
    }
    // PayPal
    else if (paymentMode.substring(0, 6) === "paypal") {
      await initPayPal(paymentMode);
    } else if (paymentMode === "payment_dummy") {
      // TODO: REMOVE THIS BEFORE GO-LIVE !!!
      setDummyPayment();
      selectedPaymentProvider.value = PaymentProvider.paymentDummy;
    } else {
      pushNotification(
        $t("checkout.step.payment.error.select_provider"),
        NotificationType.Error,
        NotificationTimeout.Medium,
      );
      selectedPaymentProvider.value = null;
      paymentModeStatus.value = PaymentStatus.ready;
    }
  };

  const unselectPaymentMode = (paymentMode: string) => {
    clearPayOneLoadTimeout();
    if (selectedPaymentMode.value === paymentMode) {
      selectedPaymentMode.value = null;
    }
  };

  // TODO: REMOVE THIS BEFORE GO-LIVE !!!
  const setDummyPayment = async () => {
    const paymentInformation: PaymentDetails = {
      subscriptionId: "payment_dummy:123123",
      defaultPayment: true,
      // VSF stuff below
      accountHolderName: "Maria Rodriguez",
      cardNumber: "0123012301230123",
      cardType: {
        code: "012",
        name: "Maria Rodriguez",
      },
      expiryMonth: "7",
      expiryYear: "2028",
    };
    // @ts-expect-error Only a dummy; will be removed in the future
    await cartContext.value?.setPaymentDetails(paymentInformation);
  };

  /**
   * Create or update payment details on cart.
   *
   * @param card - the payment info, undefined if new, non-null if exisiting card data
   * @returns {Promise<void>}
   */
  const createPaymentCard = async (card?: PaymentProviderPayment) => {
    // PAYONE
    if (selectedPaymentProvider.value === PaymentProvider.payOne) {
      if (!payOneTokenizer.value) return;
      paymentModeStatus.value = PaymentStatus.busy;

      isPayOneTokenizerError.value = false;
      isPaymentModeConfirmed.value = false;
      try {
        const result = await payOneTokenizer.value.submitTokenization();
        if (result.success && result.hostedTokenizationId) {
          payOneHostedTokenizationId.value = result.hostedTokenizationId;
          await setOrUpdateCartPaymentDetails(card?.id);
          isPaymentModeConfirmed.value = true;
          if (cartContext.value?.cart?.paymentInfo) {
            payOneAddedCards.value.push(cartContext.value.cart.paymentInfo);
          }
        } else {
          throw new Error();
        }
      } catch (e: unknown) {
        isPaymentModeConfirmed.value = false;
        isPayOneTokenizerError.value = true;
      } finally {
        paymentModeStatus.value = PaymentStatus.ready;
      }
    }
  };

  const selectPayment = (card: PaymentProviderPayment) => {
    // PAYONE
    if (selectedPaymentProvider.value === PaymentProvider.payOne) {
      const isAddedCard = payOneAddedCards.value.find(
        (addedCard) => addedCard.subscriptionId === card.subscriptionId,
      );
      if (isAddedCard) {
        // Card was added by user in this session; subscriptionId is still valid
        payOneHostedTokenizationId.value = card.subscriptionId;
        setOrUpdateCartPaymentDetails(card.id);
      } else {
        // Card is saved from a previous checkout; new subscriptionId required
        showWidget(card.tokenId);
      }
    }
  };

  // Can throw errors which must be catched in calling function
  const setOrUpdateCartPaymentDetails = async (paymentDetailsId?: string) => {
    // PAYONE
    if (selectedPaymentProvider.value === PaymentProvider.payOne) {
      paymentModeStatus.value = PaymentStatus.busy;
      if (paymentDetailsId) {
        await cartContext.value?.replaceCartPaymentDetails(paymentDetailsId);
      } else {
        const body = {
          subscriptionId: selectedPaymentMode.value + ":" + payOneHostedTokenizationId.value,
          saved: true,
        } as PaymentDetails & RequiredPaymentDetailsProps;
        await cartContext.value?.setPaymentDetails(body);
      }
      paymentModeStatus.value = PaymentStatus.ready;
    }
  };

  // Always select first item of array
  const savedPayPalAccount: Ref<PaymentProviderPayment | undefined> = computed(
    () => savedPayPalAccounts.value[0],
  );

  const setPayPalPayment = async () => {
    if (isPayPalLoading.value) return;

    isPayPalLoading.value = true;

    const body: PaymentDetailsBody = {
      userId: cartContext.value?.getUserId(),
      baseSite: cartContext.value?.bs,
      paymentId: "",
      subscriptionId: "",
      saved: isPayPalSave.value,
    };

    if (!body.userId || !body.baseSite) throw new Error();

    if (savedPayPalAccount.value && isPayPalSave.value) {
      // use existing payment detail
      body.paymentId = savedPayPalAccount.value.id;
    } else {
      // don't use existing payment detail
      body.subscriptionId = selectedPaymentProvider.value as string;
    }

    try {
      await sdk.commerce.setPaymentDetails(body);
      cartContext.value?.refreshCart();
    } catch (error) {
      const handled = handleCommerceError(error as Error);
      if (!handled) throw new Error();
    } finally {
      isPayPalLoading.value = false;
    }
  };

  const togglePayPalSaved = async (isSaved: boolean) => {
    isPayPalSave.value = isSaved;
    try {
      await setPayPalPayment();
    } catch (error) {
      pushNotification($t("global.error.unknown"), NotificationType.Error);
    }
    // Reload saved PayPal accounts
    await initPayPal(selectedPaymentProvider.value as string, false);
  };

  const showCheckPaymentButton = computed(
    () =>
      selectedPaymentProvider.value === PaymentProvider.payOne &&
      paymentModeStatus.value === PaymentStatus.ready,
  );

  /**
   * Payment was canceled. Show error message and rediret to payment step.
   */
  const paymentCancelOrder = () => {
    pushNotification($t("checkout.step.payment.error.order_canceled"), NotificationType.Error);

    if (cartContext.value) {
      router.push({ name: "checkoutPayment", params: { baseSite: cartContext.value.bs } });
    }
  };

  /**
   * Payment error occured. Show error message and rediret to payment step.
   */
  const paymentError = () => {
    pushNotification($t("checkout.step.payment.error.general"), NotificationType.Error);

    if (cartContext.value) {
      router.push({ name: "checkoutPayment", params: { baseSite: cartContext.value.bs } });
    }
  };

  /**
   * Fetch all available payment modes from Hybris.
   *
   * @throws {CartError}
   */
  const getPaymentModes = async (): Promise<void> => {
    if (!cartContext.value) return;

    getPaymentModesLoading.value = true;

    try {
      const { data: paymentModesData } = (await sdk.commerce.getPaymentModes(
        cartContext.value.getCartId(),
        cartContext.value.getUserId(),
        cartContext.value.bs,
      )) as {
        data: PaymentModes;
      };
      paymentModes.value = paymentModesData;
    } catch (error) {
      handleCommerceError(error as Error);
      throw new CartError($t("payment.error.could_not_get_payment_modes"));
    } finally {
      getPaymentModesLoading.value = false;
    }
  };

  onMounted(async () => {
    try {
      await loadScript(runtimeConfig.public.payoneScriptUrl);
      isPayOneLoaded.value = true;
    } catch (e) {
      isPayOneError.value = true;
    }
  });

  onUnmounted(() => {
    clearPayOneLoadTimeout();
  });

  return {
    createPaymentCard,
    getPaymentModes,
    isPaymentModeConfirmed,
    isPayOneTokenizerError,
    isPayPalSave,
    paymentCancelOrder,
    paymentError,
    paymentModes,
    paymentModeStatus,
    payOneSavedCards,
    payOneAddedCards,
    savedPayPalAccount,
    selectedPaymentProvider,
    selectPayment,
    selectPaymentMode,
    setPayPalPayment,
    showCheckPaymentButton,
    showWidget,
    togglePayPalSaved,
    unselectPaymentMode,
  };
}
