<template>
  <div
    class="quantity-button"
    :class="{
      'quantity-button--cart-hidden': cartEntryUpdate && !quantity,
      'quantity-button--is-updating': quantityUpdating,
    }"
  >
    <spar-tooltip
      v-if="quantityUpdatedText || quantityUpdating"
      class="quantity-button__tooltip"
      :tosca-prefix="`quantity-button-${getSanitizedIdString(quantityUpdatedText)}`"
      :aria-id="`info-${productInfo.productId}`"
      :position="TooltipPosition.left"
      :text="quantityUpdatedText"
      :show-loader="quantityUpdating && !quantityUpdatedText"
    >
    </spar-tooltip>
    <div class="quantity-button__actions">
      <spar-button
        v-if="quantity"
        :data-tosca="`${toscaPrefix}-decrease-quantity`"
        class="quantity-button__control quantity-button__control--decrease"
        :aria-controls="`itemQuantity${productInfo.productId}`"
        :variant="ButtonVariant.custom"
        :aria-label="
          $t('quantity.button.decrease.label', {
            productName: productInfo.name2,
            amount: Math.max(quantity - boundaries.min, 0).toString(),
          })
        "
        :title="
          $t('quantity.button.decrease.label', {
            productName: productInfo.name2,
            amount: Math.max(quantity - boundaries.min, 0).toString(),
          })
        "
        :icon="quantity === boundaries.min ? 'trash' : 'minus'"
        icon-only
        @click="updateQuantity(Math.max(quantity - boundaries.min, 0), true, true)"
      />
      <input
        v-if="quantity"
        v-model="valueWithSuffix"
        :data-tosca="`${toscaPrefix}-input-quantity`"
        type="text"
        class="quantity-button__input"
        @focusout="updateQuantity(($event.target as HTMLInputElement).value, true)"
        @focusin="toggleSuffix(false)"
        @keypress.enter="updateQuantity(($event.target as HTMLInputElement).value, true)"
      />
      <spar-button
        :data-tosca="quantity ? `${toscaPrefix}-increase-quantity` : `${toscaPrefix}-add-to-cart`"
        class="quantity-button__control--increase btn btn--primary"
        :class="{ 'qantity-button__control--add-to-cart': !quantity }"
        :variant="ButtonVariant.custom"
        :aria-controls="`itemQuantity${productInfo.productId}`"
        :aria-label="
          $t('quantity.button.increase.label', {
            productName: productInfo.name2,
            amount: (quantity + boundaries.min).toString(),
          })
        "
        :title="
          $t('quantity.button.increase.label', {
            productName: productInfo.name2,
            amount: (quantity + boundaries.min).toString(),
          })
        "
        @click="updateQuantity(quantity + boundaries.min, true, true)"
      >
        <span v-if="!quantity">
          <span v-if="props.layout === SparQuantityButtonLayout.small"
            ><spar-icon-sprite symbol="cart"></spar-icon-sprite
          ></span>
          <span v-else>{{ $t("quantity.button.add_to_cart.label") }}</span>
        </span>
        <spar-icon-sprite symbol="plus"></spar-icon-sprite>
      </spar-button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ButtonVariant } from "~/components/shared/SparButton/SparButton.types";
import SparButton from "~/components/shared/SparButton/SparButton.vue";
import SparIconSprite from "~/components/shared/SparIconSprite/SparIconSprite.vue";
import { TooltipPosition } from "~/components/shared/SparTooltip/SparTooltip.types";
import SparTooltip from "~/components/shared/SparTooltip/SparTooltip.vue";
import useI18n from "~/composables/i18n/useI18n";
import { useQuantitySelector } from "~/composables/quantity/useQuantitySelector";
import { type ProductMainInfo, SparProductType } from "~/utils/mdsa/integration/mdsa.types";
import { getSanitizedIdString } from "~/utils/ui";
import {
  type SparQuantityButtonBoundaries,
  SparQuantityButtonLayout,
  type SparQuantityButtonProps,
} from "./SparQuantityButton.types";

const props: SparQuantityButtonProps = defineProps({
  boundaries: {
    type: Object as PropType<SparQuantityButtonBoundaries>,
    default: () => ({}),
  },
  productInfo: {
    type: Object as PropType<Pick<ProductMainInfo, "name2" | "productId" | "salesUnit">>,
    required: true,
  },
  toscaPrefix: {
    type: String,
    default: "",
  },
  layout: {
    type: String,
    default: SparQuantityButtonLayout.small,
  },
  sparProductType: {
    type: String,
    default: "",
  },
  availabilityType: {
    type: String,
    default: "",
  },
  // if true - updateCart will be triggered not addProductToCart
  cartEntryUpdate: {
    type: Boolean,
    default: false,
  },
  entryNumber: {
    type: Number as PropType<number | undefined>,
    default: undefined,
  },
  additionalServices: {
    type: Array as PropType<Array<string>>,
    default: () => [],
  },
});

const { additionalServices: selectedServices } = toRefs(props);

const { $t } = useI18n(); // Explicit Import for Storybook

const {
  quantity,
  setQuantity,
  quantityUpdating,
  quantityUpdatedText,
  addOrUpdate,
  valueWithSuffix,
  toggleSuffix,
} = useQuantitySelector(props);

const emit = defineEmits(["quantitySet"]);

const updateQuantity = async (newQuantity: number | string, showSuffix = true, clicked = false) => {
  let newQty = 0;

  // Catch empty string input (fallback to newQty=0)
  if (newQuantity) {
    newQty = typeof newQuantity === "string" ? parseInt(newQuantity) : newQuantity;
  }

  // do nothing if cart is updating
  if (quantityUpdating.value) {
    return;
  }

  // special case for Weighted and click - always round up on click to the next minOderQuantity
  if (props.sparProductType === SparProductType.Weighted && clicked && props.boundaries) {
    if (newQty > quantity.value) {
      const nextStep = Math.ceil(quantity.value / props.boundaries.min) * props.boundaries.min;
      const difference = nextStep - quantity.value;

      if (difference !== 0) {
        newQty = quantity.value + difference;
      }
    } else {
      newQty = Math.ceil(newQty / props.boundaries.min) * props.boundaries.min;
    }
  }

  // special case for ApproximateWeight
  if (
    props.sparProductType === SparProductType.ApproximateWeight &&
    props.boundaries &&
    newQty !== quantity.value
  ) {
    // round up to next min-step --> f.e. min: 300 --> input 350 --> should round up to 600
    let nextStep = Math.ceil(newQty / props.boundaries.min) * props.boundaries.min;
    if (nextStep !== props.boundaries.min) {
      newQty = nextStep;
    }
  }

  quantity.value = newQty;

  toggleSuffix(showSuffix);

  await addOrUpdate(quantity.value, selectedServices?.value);

  emit("quantitySet");
};

setQuantity();
</script>

<style lang="scss">
@use "./SparQuantityButton.scss";
</style>
