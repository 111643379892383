import { useLog } from "@shop-storefront/utils";
import { isClient } from "~/utils/ui";
import { LogArea, type LogArgs, LogType } from "./types";

// Global configuration
const isLogEnabled = ref(false);
const logLevel = ref(0); // 0 = debug
const levels = Object.values(LogType);
const {
  log: processLog,
  setConfig: setProcessConfig,
  setLocalConfig: setProcessLocalConfig,
} = useLog();

export function setLogConfig(config: string) {
  if (config === "off") return;
  else {
    isLogEnabled.value = true;
    setProcessConfig(true, "");

    const index = levels.indexOf(config as LogType);
    // Catch invalid log level settings
    const level = index < 0 ? 0 : index;
    // Log info before possibly reducing the log level
    Log.info(LogArea.log, "Logger is enabled with log level", levels[level].toUpperCase());
    logLevel.value = index < 0 ? 0 : index;
  }
}

export class Log {
  static debug(logArea: LogArea, ...args: LogArgs[]) {
    Log.printMessage(LogType.debug, logArea, ...args);
  }

  static info(logArea: LogArea, ...args: LogArgs[]) {
    Log.printMessage(LogType.info, logArea, ...args);
  }

  static log(logArea: LogArea, ...args: LogArgs[]) {
    Log.printMessage(LogType.log, logArea, ...args);
  }

  static warn(logArea: LogArea, ...args: LogArgs[]) {
    Log.printMessage(LogType.warn, logArea, ...args);
  }

  static error(logArea: LogArea, ...args: LogArgs[]) {
    Log.printMessage(LogType.error, logArea, ...args);
  }

  static getFormattedMessage<T extends LogArgs>(...args: T[]) {
    const formattedArgs = args.map((arg) => {
      if (typeof arg === "object" && arg !== null) {
        return JSON.stringify(arg, null, 2);
      }
      if (arg === undefined) {
        return "{undefined}";
      }
      if (arg === null) {
        return "{null}";
      }
      return arg.toString();
    });

    const message = formattedArgs.join(" ") + "\n";
    return message;
  }

  static printMessage(consoleMethod: LogType, logArea: LogArea, ...args: LogArgs[]) {
    // Check if log is enabled
    if (!isLogEnabled.value) return;
    // Check if log level is reached
    const index = levels.indexOf(consoleMethod);
    if (logLevel.value > index) return;

    // Send log to console or to process.stdout/stderr
    if (isClient()) {
      // This is the only place in the entire FE package, where a direct console call is allowed
      // eslint-disable-next-line no-console
      console[consoleMethod](`[${logArea.toUpperCase()}]`, ...args);
    } else {
      setProcessLocalConfig(logArea.toUpperCase());
      const message = Log.getFormattedMessage(...args);
      processLog(message, consoleMethod);
    }
  }
}
