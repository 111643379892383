<template>
  <div v-if="isSuccess" class="registration-form">
    <spar-heading level="1" heading-style="1" :title="$t('auth.registration_form.success')" />
  </div>

  <form v-if="!isSuccess" class="spar-form registration-form" novalidate @submit.prevent="onSubmit">
    <spar-heading level="1" heading-style="1" :title="$t('auth.registration_form.subtitle')" />

    <fieldset v-if="showDataGroup(DataGroups.BasicData)">
      <div v-if="showField(RegistrationFields.Email)" class="w-100">
        <spar-input
          v-model="formData.email"
          :type="SparInputType.email"
          :label="$t('auth.registration_form.fields.email')"
          :required="fieldConfig.email.required"
          tosca-prefix="registration-email"
          :status-message="emailStatusMessage"
          @interface="getChildInterface"
          @change="validateEmail"
        />
      </div>

      <div v-if="showField(RegistrationFields.Password)" class="w-100">
        <spar-input
          v-model="formData.password"
          :type="SparInputType.password"
          :label="$t('auth.registration_form.fields.password')"
          :minlength="8"
          :required="fieldConfig.password.required"
          show-strength
          tosca-prefix="registration-password"
          @interface="getChildInterface"
        />
      </div>

      <div v-if="showField(RegistrationFields.Password2)" class="w-100">
        <spar-input
          v-model="formData.password2"
          :type="SparInputType.password"
          :label="$t('auth.registration_form.fields.password2')"
          :minlength="8"
          :required="fieldConfig.password2.required"
          :validators="[
            (input) =>
              formData.password === input || $t('auth.registration_form.fields.password2.no_match'),
          ]"
          tosca-prefix="registration-repeat-password"
          @interface="getChildInterface"
        />
      </div>
    </fieldset>

    <fieldset v-if="showDataGroup(DataGroups.ConsentData)">
      <div v-if="showField(RegistrationFields.PrivacyConsent)" class="w-100">
        <spar-checkbox
          v-model="formData.privacyConsent"
          val="accepted"
          :label="$t('auth.registration_form.fields.privacy_consent')"
          :required="fieldConfig.privacyConsent.required"
          tosca-prefix="registration-privacy-consent"
          @interface="getChildInterface"
        />
      </div>
    </fieldset>

    <div role="alert">
      <p v-if="error" class="spar-form__error" data-tosca="registration-general-error">
        {{ errorMessage }}
      </p>
    </div>

    <spar-button
      type="submit"
      :disabled="loading || !isGigyaReady"
      class="btn--primary"
      tosca-prefix="registration-submit"
    >
      {{ $t("auth.registration_form.submit") }}
    </spar-button>

    <div class="registration-form__info-text">{{ $t("auth.registration_form.info_text") }}</div>
  </form>
</template>

<script setup lang="ts">
// https://help.sap.com/docs/SAP_CUSTOMER_DATA_CLOUD/8b8d6fffe113457094a17701f63e3d6a/41389fe070b21014bbc5a10ce4041860.html?locale=en-US
import {
  type RegistrationDataGroupsConfig,
  type RegistrationFieldsConfig,
  RegistrationFields,
  DataGroups,
} from "~/components/feature/SparRegistration/SparRegistration.types";
import {
  type InputStatusMessage,
  SparButton,
  SparCheckbox,
  SparHeading,
  SparInput,
  SparInputStatus,
  SparInputType,
} from "~/components/shared";
import { type GigyaResponse, GigyaRegistrationResponseCode } from "~/composables/auth/gigya.types";
import useGigya from "~/composables/auth/useGigya";
import useI18n from "~/composables/i18n/useI18n";
import type { InputInterface } from "~/composables/inputs/useInputValues.types";
import useRegistrationConfig from "./useRegistrationConfig";

const { isGigyaReady } = useGigya();
const { $t } = useI18n();
const { getCountryDataGroupsConfig, getFieldConfig } = useRegistrationConfig();

const children: Ref<InputInterface[]> = ref([]);
const error = ref(0);
const isSuccess = ref(false);
const loading = ref(false);
const regToken = ref("");
const emailStatusMessage: Ref<InputStatusMessage | undefined> = ref(undefined);

const dataGroupConfig = getCountryDataGroupsConfig();
const fieldConfig = getFieldConfig();

const formData = reactive({
  email: "",
  password: "",
  password2: undefined,
  privacyConsent: "",
});

const isEmailAvailable = (response: GigyaResponse) => {
  const emailTakenError = {
    status: SparInputStatus.error,
    text: $t("auth.registration_form.fields.email.taken"),
  };

  if (response.isAvailable || !formData.email) {
    emailStatusMessage.value = undefined;
  } else {
    emailStatusMessage.value = emailTakenError;
  }
};

// Check if eMail is already taken
const validateEmail = async () => {
  window.gigya.accounts.isAvailableLoginID({
    loginID: formData.email,
    callback: isEmailAvailable,
  });
};

// Cleanup states before submit
const cleanupForm = () => {
  isSuccess.value = false;
  emailStatusMessage.value = undefined;
  error.value = 0;
  loading.value = false;
};

const onSubmit = async () => {
  cleanupForm();

  const res = children.value.map((c) => c.validate());
  const isValid = res.every((v) => v);

  if (!isValid || emailStatusMessage.value) return;

  loading.value = true;

  // 1. accounts.initRegistration,
  // 2. accounts.register and
  // 3. accounts.finalizeRegistration. If the finalizeRegistration parameter of accounts.register is set to 'true' then there is no need to call accounts.finalizeRegistration.
  window.gigya.accounts.initRegistration({
    callback: initRegistrationCallback,
  });
};

const initRegistrationCallback = (eventObj: GigyaResponse) => {
  loading.value = false;

  if (eventObj.errorCode !== 0 || !eventObj.regToken) {
    error.value = eventObj.errorCode;
  } else {
    regToken.value = eventObj.regToken;
    register();
  }
};

const register = () => {
  if (!regToken.value) return;
  loading.value = true;

  window.gigya.accounts.register({
    email: formData.email,
    password: formData.password,
    regToken: regToken.value,
    ignoreInterruptions: true,
    finalizeRegistration: true,
    regSource: window.location.href,
    // TODO: currently static, will be added to the basesite and taken from there
    data: {
      spar: {
        regOrigin: "shopplattform.at",
        regContext: "Z001",
      },
    },
    preferences: {
      terms: {
        websites: {
          isConsentGranted: !!formData.privacyConsent,
        },
      },
    },
    callback: registerCallback,
  });
};

const registerCallback = (eventObj: GigyaResponse) => {
  const errorCode = eventObj.errorCode;

  if (errorCode !== GigyaRegistrationResponseCode.PendingConfirmation) {
    error.value = eventObj.validationErrors?.[0].errorCode ?? eventObj.errorCode;
    loading.value = false;
  } else {
    isSuccess.value = true;
  }
};

const errorMessage = computed(() => {
  if (error.value === GigyaRegistrationResponseCode.PasswordInvalid)
    return $t("auth.reset_password_form.error.password");

  if (
    error.value === GigyaRegistrationResponseCode.LoginIdentifierExists ||
    error.value === GigyaRegistrationResponseCode.UniqueIdentifierExists
  )
    return $t("auth.registration_form.fields.email.taken");

  return $t("auth.reset_password_form.error.general");
});

const getChildInterface = (childInterface: InputInterface) => {
  children.value.push(childInterface);
};

const showDataGroup = (groupId: string): boolean => {
  const dataGroup = dataGroupConfig[groupId as keyof RegistrationDataGroupsConfig];
  return dataGroup.visible || (!dataGroup.visible && dataGroup.required);
};

const showField = (fieldId: string): boolean => {
  const field = fieldConfig[fieldId as keyof RegistrationFieldsConfig];
  return field.visible || (!field.visible && field.required);
};
</script>

<style lang="scss">
@use "./SparRegistration.scss";
</style>
