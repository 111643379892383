<template>
  <div class="tabs">
    <div class="tabs__header" :class="tabsWrapperClass">
      <spar-button
        v-for="(tab, index) in tabs"
        :key="tab.id"
        :data-tosca="getToscaPrefix('tab', toscaPrefix, index)"
        :variant="ButtonVariant.custom"
        role="tab"
        class="btn-custom tabs__header-btn"
        :class="[
          { 'tabs__header--selected': activeId === tab.id },
          { 'tabs__header--disabled': !tab.isBuyable },
          tabHeaderClass,
          tab.additionalClass,
        ]"
        :aria-selected="activeId === tab.id"
        :aria-controls="`panel-${tab.id}`"
        @click="activeId = tab.id"
      >
        <slot name="icon__in"></slot>

        {{ tab.title }}

        <slot name="icon"></slot>
      </spar-button>
    </div>

    <slot />
  </div>
</template>

<script setup lang="ts">
import { ButtonVariant } from "~/components/shared/SparButton/SparButton.types";
import SparButton from "~/components/shared/SparButton/SparButton.vue";
import { getToscaPrefix } from "~/utils/ui";
import type { SparTabsProps } from "./SparTabs.types";

interface Tabs {
  id: string;
  title: string;
  isBuyable: boolean;
  additionalClass: Record<string, boolean>;
}

// Hyphenated props
interface Tab {
  "tab-id": string;
  title: string;
  "is-buyable": boolean;
  "additional-class": Record<string, boolean>;
}

defineProps({
  tabsWrapperClass: {
    type: String,
    default: "",
  },
  tabHeaderClass: {
    type: String,
    default: "",
  },
  toscaPrefix: {
    type: String,
    default: undefined,
  },
}) as SparTabsProps;

const slots = useSlots();

const tabs: Ref<Tabs[]> = ref([]);
const activeId = ref("");
const activeSet = ref(false);

const getTabsData = () => {
  if (!slots.default) return;

  slots.default().forEach((tab) => {
    if (tab.children && Array.isArray(tab.children)) {
      tab.children.forEach((childrenTab) => {
        // @ts-expect-error Property exists
        const { props: childrenTabProps } = childrenTab;
        setTabsData(childrenTabProps);
      });
    } else {
      const { props: tabProps } = tab;
      // @ts-expect-error Property exists
      setTabsData(tabProps);
    }
  });
};

const setTabsData = (props: Tab) => {
  tabs.value.push({
    id: props["tab-id"],
    title: props.title,
    isBuyable: props["is-buyable"],
    additionalClass: props["additional-class"],
  });

  if (!activeSet.value) {
    activeSet.value = true;
    activeId.value = props["tab-id"];
  }
};

getTabsData();

provide("selectedTab", activeId);
</script>

<style lang="scss">
@use "./SparTabs.scss";
</style>
