export function getUniqueId(prefix: string) {
  return prefix + "-" + Math.floor(Math.random() * 9999999);
}

// TODO: This needs refinement as soon as the final header is implemented
export function scrollTo(element: HTMLElement) {
  const header = document.getElementsByClassName("header")[0];

  window.scroll({
    behavior: "smooth",
    left: 0,
    top: element.getBoundingClientRect().top + window.scrollY - header.clientHeight - 70,
  });
}

export function isStorybook() {
  return Boolean(import.meta.env.STORYBOOK);
}

export function isClient() {
  if (typeof process === "undefined") return true;
  return process.client || isStorybook();
}

export function getToscaPrefix(
  suffix: string,
  prefix: undefined | string,
  id: undefined | number = undefined,
) {
  if (!prefix) return undefined;

  let toscaLabel = prefix + (id ? "-" + id : "") + "-" + suffix;

  // Replace spaces with underscores
  toscaLabel = toscaLabel.replace(/ /g, "_");

  return toscaLabel;
}

// https://stackoverflow.com/a/822464/6549013
export function stripHtml(input: string) {
  return input.replace(/<[^>]*>?/gm, "");
}

// "Lorem [Ipsum] 123+456" -> lorem_ipsum_123456
export function getSanitizedIdString(str: string) {
  return str
    .replace(/[^a-zA-Z0-9-_ ]/g, "")
    .replaceAll(" ", "_")
    .toLowerCase();
}

// Return the alphabet as an array
export function getAlphabet(uppercase = false) {
  const start = uppercase ? 65 : 97;

  const letters = [];
  for (let i = start; i <= start + 25; i++) {
    letters.push(String.fromCharCode(i));
  }
  return letters;
}
