import { defineStore } from "pinia";
import type {
  Navigation,
  NavigationNode,
} from "~/components/sections/SparHeader/SparMainNavigation/SparMainNavigation.types";
import { useScreenInfo } from "~/composables/utils/useScreenInfo";

export const useNavigationStore = defineStore("navigation", () => {
  const { $indexes } = useNuxtApp();
  const { isMobile } = useScreenInfo();

  const subNavigationHeight = ref(0);

  const navigation: Navigation = $indexes.navigation;
  const tmpActiveMenuId = ref("");

  const currentMenuLevel = ref(1);
  const setCurrentMenuLevel = (level: number) => {
    currentMenuLevel.value = level;
  };

  const mobileMenuOpen = ref(false);
  const clickedMobileMainNavigation = ref("");
  const setMobileMenuActive = (navNode: NavigationNode | null, forceClose = false) => {
    if (forceClose) {
      resetMobileMenuActive();
      return;
    }

    mobileMenuOpen.value = false;
    if (isMobile.value && navNode) {
      if (clickedMobileMainNavigation.value === navNode.categoryId) {
        resetMobileMenuActive();
        return;
      }

      mobileMenuOpen.value = true;
      clickedMobileMainNavigation.value = navNode.categoryId;
    }
  };

  const resetMobileMenuActive = () => {
    mobileMenuOpen.value = false;
    clickedMobileMainNavigation.value = "";
  };

  const setActiveMenu = (navNode: NavigationNode, initial = false) => {
    if (!navNode.categoryId) {
      return;
    }

    // close flyout-level on second click (desktop only)
    if (
      tmpActiveMenuId.value === navNode.categoryId &&
      currentMenuLevel.value > 2 &&
      !isMobile.value
    ) {
      closeFlyout(null);
      return;
    }

    tmpActiveMenuId.value = navNode.categoryId;

    // toggle flyout on second click to the same node (desktop only)
    if (
      !isMobile.value &&
      navigation?.flyoutOpenable &&
      navigation.levelPath.includes(navNode.categoryId)
    ) {
      navigation.flyoutOpenable = false;
      return;
    }

    // toggle flyout openable
    if (!initial) {
      navigation.flyoutOpenable = true;
    }

    // set level path
    navigation.levelPath = navNode.levelPath;

    // set active (clicked) level and id
    setCurrentMenuLevel(Number(navNode.level));

    // increase menu level if childCategories
    if (navNode.childCategories?.length) {
      setCurrentMenuLevel(Number(navNode.level) + 1);
    }
  };

  // close flyout (menu level 3)
  const closeFlyout = (navNode: NavigationNode | null) => {
    if (!navNode) {
      // click from outside
      setCurrentMenuLevel(currentMenuLevel.value - 1);
      return;
    }

    if (isMobile.value) {
      navigation.levelPath = navNode.levelPath;
    } else {
      navigation.flyoutOpenable = false;
    }
    setCurrentMenuLevel(Number(navNode.level));
  };

  // check active state of nav-node
  const checkActive = (item: NavigationNode, level = 1) => {
    return navigation.levelPath.includes(item.categoryId) && currentMenuLevel.value >= level;
  };

  return {
    checkActive,
    currentMenuLevel,
    mobileMenuOpen,
    navigation,
    setActiveMenu,
    setCurrentMenuLevel,
    setMobileMenuActive,
    subNavigationHeight,
    closeFlyout,
  };
});
