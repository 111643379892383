<template>
  <div class="leaflets-details container">
    <section class="leaflets-details__sections spar-section">
      <template v-if="content?.[0]?.topSections">
        <render-sections :content="content[0].topSections" />
      </template>
    </section>
    <div v-if="!isLoading">
      <div
        v-if="isLeafletValid"
        class="leaflets-details__container"
        :class="{ 'leaflets-details__container--fullscreen': isFullscreen }"
      >
        <div
          class="leaflets-details__filters"
          :class="{ 'leaflets-details__filters--active': isMobileActive }"
        >
          <div v-show="isSmallerOrEqualMd" class="leaflets-details__filters-heading">
            {{ $t("leaflets.details.filters.heading") }}
            <spar-button
              class="leaflets-details__filters-close"
              :aria-label="$t('global.close')"
              tosca-prefix="leaflets-details-close-filters"
              icon-only
              icon="close"
              :variant="ButtonVariant.custom"
              @click="isMobileActive = false"
            />
          </div>
          <div class="leaflets-details__filters--left">
            <spar-leaflets-filter
              :selected-option="selectedRegion"
              :label="$t('leaflets.details.region.label')"
              :options="regions"
              label-level="4"
              tosca-prefix="leaflets-details"
              @select="handleRegionSelection"
            />
            <spar-leaflets-filter
              :selected-option="selectedLeaflet"
              :label="$t('leaflets.details.leaflet.label')"
              :options="leafletOptions"
              label-level="4"
              tosca-prefix="leaflets-details-leaflet"
              @select="handleLeafletSelection"
            />
          </div>
          <div class="leaflets-details__filters--right">
            <spar-button
              class="leaflets-details__apply-btn"
              tosca-prefix="leaflets-details-apply-filters"
              :variant="ButtonVariant.primary"
              @click="showLeaflet"
            >
              {{ $t("leaflets.details.apply.btn") }}
            </spar-button>
            <spar-button
              class="leaflets-details__show-all-btn"
              tosca-prefix="leaflets-details-show-all"
              :variant="ButtonVariant.secondary"
              @click="showAll"
            >
              {{ $t("leaflets.details.show_all.btn") }}
            </spar-button>
          </div>
        </div>
        <div class="leaflets-details__content">
          <spar-button
            class="leaflets-details__fullscreen-btn"
            tosca-prefix="leaflets-details-fullscreen"
            :variant="ButtonVariant.custom"
            :icon="isFullscreen ? 'shrink' : 'expand'"
            icon-only
            @click="isFullscreen = !isFullscreen"
          ></spar-button>
          <iframe
            :src="leafletSrc"
            :title="$t('leaflets.details.iframe.title')"
            allow="autoplay; fullscreen;"
            class="leaflets-details__iframe"
            data-tosca="leaflets-details-iframe"
            width="100%"
          ></iframe>
          <div class="leaflets-details__open-filters">
            <spar-button
              v-show="isSmallerOrEqualMd"
              tosca-prefix="leaflets-details-open-filters"
              :variant="ButtonVariant.secondary"
              @click="isMobileActive = true"
            >
              {{ $t("leaflets.details.show_filters.btn") }}
            </spar-button>
          </div>
        </div>
      </div>
      <div v-else>
        <spar-heading
          level="2"
          headline-style="2"
          :title="$t('leaflets.details.error.heading')"
          :description="$t('leaflets.details.error.text')"
        />
      </div>
    </div>
    <spar-loader v-else class="leaflets-details__loader" />
    <section class="leaflets-details__sections spar-section">
      <template v-if="content?.[0]?.bottomSections">
        <render-sections :content="content[0].bottomSections" />
      </template>
    </section>
  </div>
</template>

<script lang="ts" setup>
import type { LeafletsConfig, LeafletsDetailsPage } from "@shop-storefront/utils";
import SparLeafletsFilter from "~/components/feature/SparLeaflets/SparLeafletsFilter/SparLeafletsFilter.vue";
import RenderSections from "~/components/sections/RenderSections/RenderSections.vue";
import { ButtonVariant, SparButton, SparHeading, SparLoader } from "~/components/shared";
import { useScreenInfo } from "~/composables/utils/useScreenInfo";
import { ContentstackPageParams } from "~/plugins/contentstack/contentstack.types";
import { useIpaperStore } from "~/stores/ipaper.store";
import ContentType from "~/utils/contentstack/constants/content-types.constants";
import { leafletsDetailsPageReferences } from "~/utils/contentstack/constants/references.constants";
import {
  RobotFollow,
  RobotIndex,
} from "~/utils/contentstack/resolvers/gloabal-fields/page-seo/page-seo.types";

const { $contentstack } = useNuxtApp();
const router = useRouter();
const {
  params: { region, leafletId },
  query: { p: page },
} = useRoute();
const { getPath } = useRoutes();
const { isSmallerOrEqualMd } = useScreenInfo();

const isMobileActive = ref(false);
const isLeafletValid = ref(false);
const isLoading = ref(true);
const isFullscreen = ref(false);
const leafletSrc = ref("");

const {
  checkValidity,
  getLeafletUrl,
  getSanitizedLeafletName,
  init,
  setIpaperConfig,
  setLeafletOptions,
  setSelectedLeaflet,
  setSelectedRegion,
} = useIpaperStore();
const { ipaperConfig, leafletOptions, regions, selectedLeaflet, selectedRegion } =
  storeToRefs(useIpaperStore());

// Load Contentstack Sections for SSR
const { data: content } = await useAsyncData(ContentType.leafletsDetailsPage, async () => {
  return await $contentstack.getEntries<LeafletsDetailsPage>({
    type: ContentType.leafletsDetailsPage,
    customQuery: {
      [ContentstackPageParams.leafletRegion]: region,
    },
    includeReference: leafletsDetailsPageReferences as string[],
  });
});

// Load Config for SSR
const { data: config } = await useAsyncData(ContentType.leafletsConfig, async () => {
  return await $contentstack.getEntries<LeafletsConfig[]>({
    type: ContentType.leafletsConfig,
    includeCount: true,
  });
});

if (config.value?.[0]?.[0]) {
  setIpaperConfig(config.value[0][0]);

  // Fetch Data from iPaper Integration for SSR
  await init(region.toString());

  setLeafletOptions();
  setSelectedLeaflet(leafletId.toString());
}

// Set SEO data
if (process.client && selectedLeaflet.value) {
  useHead({
    title: getSanitizedLeafletName(selectedLeaflet.value.value),
    meta: [
      {
        hid: "robots",
        name: "robots",
        content: `${RobotIndex.noindex}, ${RobotFollow.nofollow}`,
      },
    ],
  });
}

const handleRegionSelection = (region: string) => {
  if (!ipaperConfig.value?.defaultRegion) return;
  setSelectedRegion(region, ipaperConfig.value.defaultRegion);
  setLeafletOptions();
  // pre-select first leaflet in list
  setSelectedLeaflet(leafletOptions.value[0].key);
};

const handleLeafletSelection = (leafletId: string) => {
  setSelectedLeaflet(leafletId);
};

const showLeaflet = () => {
  if (selectedRegion.value && selectedLeaflet.value) {
    router.push(`${getPath("leaflets")}/${selectedRegion.value.key}/${selectedLeaflet.value.key}/`);
  }

  if (isSmallerOrEqualMd) {
    isMobileActive.value = false;
  }
};

const showAll = () => {
  if (selectedRegion.value) {
    router.push(`${getPath("leaflets")}/${selectedRegion.value.key}`);
  }
};

onMounted(() => {
  isLeafletValid.value = checkValidity();
  if (isLeafletValid.value) {
    const iframeBaseUrl = "https://viewer.ipaper.io/";
    const queryParam = page ? `?page=${page}` : "";
    leafletSrc.value = iframeBaseUrl + getLeafletUrl() + queryParam;
  }
  isLoading.value = false;
});
</script>

<style lang="scss">
@use "SparLeafletsDetails.scss";
</style>
