<template>
  <div class="payment-details" data-tosca="myaccount-payment-details">
    <spar-heading
      class="payment-details__heading"
      level="3"
      :title="$t('my_account.personal_data.payment_details.title')"
    />
    <div>{{ $t("my_account.personal_data.payment_details.info") }}</div>
    <dl v-if="paymentDetailsList" class="payment-details__list">
      <div
        v-for="paymentDetail in paymentDetailsList.payments"
        :key="paymentDetail.id"
        class="payment-details__list-item"
        :data-tosca="`myaccount-payment-details-list-${paymentDetail.id}`"
      >
        <spar-payment-details-card :payment-details="paymentDetail" />
      </div>
    </dl>
  </div>
</template>

<script lang="ts" setup>
import { SparHeading } from "~/components/shared";
import SparPaymentDetailsCard from "~/components/shared/SparPaymentDetailsCard/SparPaymentDetailsCard.vue";
import usePaymentDetails from "~/composables/payment/usePaymentDetails";

const { getPaymentDetailsList, paymentDetailsList } = usePaymentDetails();
getPaymentDetailsList(true, true);
</script>

<style lang="scss">
@use "./SparPaymentDetails.scss";
</style>
