<template>
  <section class="checkout-content container">
    <div v-if="order">
      <h1 data-tosca="confirmation-success">{{ $t("checkout.step.confirmation.title") }}</h1>
      <p data-tosca="confirmation-ordernr">{{ order.code }}</p>
    </div>
    <div v-else>
      {{ $t("checkout.step.confirmation.no_order") }}
    </div>

    <spar-link
      link="/"
      :label="$t('global.back_to_homepage')"
      data-tosca="confirmation-back2shop"
    />
  </section>
  <spar-checkout-proceed>
    <div class="checkout-payment__proceed">
      <spar-checkout-overview
        :headline-title="$t('checkout.step.payment.overview.title')"
        :delivery-cost="order?.deliveryCost"
        :sub-total="order?.subTotal"
        :total-price-with-tax="order?.totalPriceWithTax"
      />
    </div>
  </spar-checkout-proceed>
</template>

<script setup lang="ts">
import SparCheckoutOverview from "~/components/feature/SparCheckout/SparCheckoutOverview/SparCheckoutOverview.vue";
import SparCheckoutProceed from "~/components/feature/SparCheckout/SparCheckoutProceed/SparCheckoutProceed.vue";
import { SparLink } from "~/components/shared";
import { useCheckoutStore } from "~/stores/checkout.store";

const { order } = storeToRefs(useCheckoutStore());
</script>

<style lang="scss" scoped>
@use "./SparCheckoutConfirmation.scss";
</style>
