<template>
  <div class="multiselect">
    <div v-for="option in options" :key="option.text" class="plp-facets__facet-element">
      <spar-checkbox
        v-if="option.totalHits"
        v-model="selectedOptions[option.text]"
        :val="option.text"
        :label="option.text"
        :tosca-prefix="`plp-facet-${option.text.toLowerCase().replace(/\s/g, '')}`"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { SparCheckbox } from "~/components/shared";
import { useSearchStore } from "~/stores/search.store";
import type { Filters } from "~/utils/factfinder/createQuery";
import type { FactFinderFacet } from "~/utils/factfinder/integration/factfinder.types";

const searchStore = useSearchStore();

const props = defineProps({
  options: {
    type: Object as PropType<FactFinderFacet[]>,
    required: true,
  },
  name: {
    type: String,
    default: "",
  },
  filterName: {
    type: String,
    default: "",
  },
});

const selectedObj: Record<string, string> = {};
Object.values(props.options)
  .filter((value) => value.selected === "TRUE")
  .every((item) => (selectedObj[item.text] = item.text));
const selectedOptions: Ref<Record<string, string>> = ref(selectedObj);

const internalValue = computed(() => {
  const values = Object.values(selectedOptions.value);
  return values.filter((value) => value);
});

watch(
  () => internalValue.value,
  async (value) => {
    if (value !== undefined) {
      const facet: Filters = {
        [props.filterName]: value,
      };

      let removeFilter: string | null = null;
      if (!Object.entries(facet[props.filterName]).length) {
        removeFilter = props.filterName;
      }

      searchStore.updateFilterUrl(facet, removeFilter);
    }
  },
);
</script>
