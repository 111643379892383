<template>
  <section class="spar-section container">
    <div class="contact-form__inner">
      <spar-heading v-if="heading" v-bind="heading" />

      <p>{{ text }}</p>

      <p v-if="textSmall" class="contact-form__text-small">{{ textSmall }}</p>

      <div v-if="isSent">
        <spar-alert :type="SparAlertType.success" :is-closable="false">{{
          confirmationText
        }}</spar-alert>

        <!-- TODO: Remove as soon as backend service is ready -->
        <p>Temporary debug output:</p>
        <pre>{{ data }}</pre>
      </div>

      <form v-else class="contact-form__form" novalidate @submit.prevent="onSubmit">
        <div class="contact-form__field--medium">
          <spar-select
            v-model="data.salutation"
            :label="$t('form.input.salutation.label')"
            required
            :options="salutationOptions"
            :tosca-prefix="getToscaPrefix('salutation', toscaPrefix)"
            @interface="getChildInterface"
          />
        </div>
        <div class="contact-form__field--medium">
          <spar-select
            v-model="data.title"
            :label="$t('form.input.title.label')"
            :options="secondTitleCodeOptions"
            :tosca-prefix="getToscaPrefix('title', toscaPrefix)"
            @interface="getChildInterface"
          />
        </div>

        <div class="contact-form__field--medium">
          <spar-input
            v-model="data.firstName"
            type="text"
            :maxlength="50"
            :label="$t('form.input.firstname')"
            required
            :tosca-prefix="getToscaPrefix('firstname', toscaPrefix)"
            @interface="getChildInterface"
          />
        </div>
        <div class="contact-form__field--medium">
          <spar-input
            v-model="data.lastName"
            type="text"
            :maxlength="50"
            :label="$t('form.input.lastname')"
            required
            :tosca-prefix="getToscaPrefix('lastname', toscaPrefix)"
            @interface="getChildInterface"
          />
        </div>
        <div class="contact-form__field">
          <spar-input
            v-model="data.email"
            type="email"
            :maxlength="50"
            :label="$t('form.input.email')"
            required
            :tosca-prefix="getToscaPrefix('email', toscaPrefix)"
            @interface="getChildInterface"
          />
        </div>
        <div class="contact-form__field--large">
          <spar-input
            v-model="data.street"
            type="text"
            :maxlength="50"
            :label="$t('form.input.street')"
            :tosca-prefix="getToscaPrefix('street', toscaPrefix)"
            @interface="getChildInterface"
          />
        </div>
        <div class="contact-form__field--small">
          <spar-input
            v-model="data.streetNo"
            type="text"
            :maxlength="10"
            :label="$t('form.input.house_number')"
            :tosca-prefix="getToscaPrefix('housenr', toscaPrefix)"
            @interface="getChildInterface"
          />
        </div>
        <div class="contact-form__field--small">
          <spar-input
            v-model="data.zip"
            type="text"
            :maxlength="5"
            :label="$t('form.input.postalcode')"
            :tosca-prefix="getToscaPrefix('zip', toscaPrefix)"
            @interface="getChildInterface"
          />
        </div>
        <div class="contact-form__field--large">
          <spar-input
            v-model="data.city"
            type="text"
            :maxlength="50"
            :label="$t('form.input.town')"
            :tosca-prefix="getToscaPrefix('city', toscaPrefix)"
            @interface="getChildInterface"
          />
        </div>
        <div class="contact-form__field">
          <spar-select
            v-model="data.country"
            :label="$t('form.input.country')"
            :options="countryList"
            :tosca-prefix="getToscaPrefix('country', toscaPrefix)"
            @interface="getChildInterface"
          />
        </div>
        <div class="contact-form__field">
          <spar-input
            v-model="data.phone"
            type="tel"
            :maxlength="20"
            :label="$t('form.input.phone')"
            :tosca-prefix="getToscaPrefix('phone', toscaPrefix)"
            @interface="getChildInterface"
          />
        </div>

        <div class="contact-form__field">
          <!-- TODO: Use data from API instead of mock data -->
          <spar-select-autocomplete
            v-model="data.market"
            label="Market"
            class="market__select-autocomplete"
            :options="marketOptions"
            :tosca-prefix="getToscaPrefix('market', toscaPrefix)"
            @interface="getChildInterface"
          />
        </div>

        <fieldset class="contact-form__fieldset">
          <legend class="contact-form__fieldset-legend">
            {{ $t("contact_form.section.concern") }}
          </legend>

          <div class="contact-form__field">
            <spar-select
              v-model="data.topic"
              :label="$t('contact_form.fields.concern')"
              required
              :options="concernList"
              :tosca-prefix="getToscaPrefix('concern', toscaPrefix)"
              @interface="getChildInterface"
            />
          </div>
          <div class="contact-form__field">
            <spar-textarea
              v-model="data.message"
              :maxlength="5000"
              :label="$t('contact_form.fields.textarea')"
              required
              :tosca-prefix="getToscaPrefix('textarea', toscaPrefix)"
              @interface="getChildInterface"
            />
          </div>
          <div class="contact-form__field">
            <!-- TODO: Finish as soon as backend service is ready -->
            <spar-file-upload
              :label="$t('form.input.file.label')"
              :accepted-file-extensions="acceptedFileTypes"
              :tosca-prefix="getToscaPrefix('file', toscaPrefix)"
              :max-size="5 * 1024 * 1024"
              @interface="getChildInterface"
            />
          </div>
          <div class="contact-form__field--medium">
            <spar-checkbox
              v-model="data.sendCopy"
              val="sendCopy"
              :label="$t('contact_form.fields.send_copy')"
              :tosca-prefix="getToscaPrefix('copy', toscaPrefix)"
              @interface="getChildInterface"
            />
          </div>
          <div class="contact-form__field--medium">
            <spar-checkbox
              v-model="data.sendReply"
              val="sendReply"
              :label="$t('contact_form.fields.feedback_desired')"
              :tosca-prefix="getToscaPrefix('feedback', toscaPrefix)"
              @interface="getChildInterface"
            />
          </div>
        </fieldset>
        <div class="contact-form__field">
          <div aria-live="polite">
            <p v-if="hasError" class="contact-form__error">
              {{ $t("form.validation.form_invalid") }}
            </p>
          </div>

          <NuxtTurnstile v-model="turnstileToken" />

          <spar-button type="submit" class="btn--primary">{{
            $t("form.action.submit")
          }}</spar-button>

          <div v-if="hasSendError" class="contact-form__send-error">
            <spar-alert :type="SparAlertType.error" :is-closable="false">{{
              $t("form.error.send")
            }}</spar-alert>
          </div>
        </div>

        <div class="contact-form__field">
          <p class="contact-form__legend">{{ $t("form.validation.required_legend") }}</p>
        </div>
      </form>
    </div>
  </section>
</template>

<script lang="ts" setup>
import type { ContentstackKeyValue } from "@shop-storefront/utils";
import {
  SparAlert,
  SparAlertType,
  SparButton,
  SparCheckbox,
  SparFileUpload,
  SparHeading,
  type SparHeadingProps,
  SparInput,
  SparSelect,
  SparSelectAutocomplete,
  SparTextarea,
} from "~/components/shared";
import { marketOptions } from "~/components/shared/SparSelectAutocomplete/SparSelectAutocomplete.mocks";
import useAddress from "~/composables/form/useAddress";
import type { InputInterface } from "~/composables/inputs/useInputValues.types";
import { getToscaPrefix, scrollTo } from "~/utils/ui";
import type { ContactFormData, SparContactFormProps } from "./SparContactForm.types";

const props: SparContactFormProps = defineProps({
  concern: {
    type: Object as PropType<ContentstackKeyValue>,
    required: true,
  },
  confirmationText: {
    type: String,
    required: true,
  },
  countries: {
    type: Object as PropType<ContentstackKeyValue>,
    required: true,
  },
  heading: {
    type: Object as PropType<SparHeadingProps>,
    default: undefined,
  },
  text: {
    type: String,
    required: true,
  },
  textSmall: {
    type: String,
    default: undefined,
  },
});

const data: Ref<ContactFormData> = ref({
  salutation: "",
  title: "",
  firstName: "",
  lastName: "",
  email: "",
  topic: "",
  message: "",
});

const { $form } = useNuxtApp();
const turnstileToken = ref("");
const children = ref([]) as Ref<InputInterface[]>;
const hasError = ref(false);
const hasSendError = ref(true);
const acceptedFileTypes = ["jpg", "gif", "doc", "pdf", "txt"];
const isSent = ref(false);
const toscaPrefix = "contact";

const { getTitleOptions, getSecondTitleOptions } = useAddress();

const salutationOptions = getTitleOptions();
const secondTitleCodeOptions = getSecondTitleOptions();

const getChildInterface = (childInterface: InputInterface) => {
  children.value.push(childInterface);
};

const onSubmit = () => {
  const res = children.value.map((child) => child.validate());
  const isValid = res.every((isValid) => isValid);
  if (isValid) {
    hasError.value = false;

    sendData();
  } else {
    hasError.value = true;
  }
};

const sendData = async () => {
  hasSendError.value = false;
  try {
    const res = await $form.submitForm({
      token: turnstileToken.value,
      data: data.value,
    });

    // TODO: Throw more specific error as soon as backend service is ready
    if (res.error.value) throw new Error();

    // TODO: Submit form to backend service with uploaded file
    Log.log(LogArea.contact, "submit", data.value);
    isSent.value = true;
  } catch {
    // TODO: Show specific error message as soon as backend service is ready
    hasSendError.value = true;
  }
};

// TODO: Determining target email address in frontend is INSECURE
// needs refinement as soon as backend service is in progress
const concernList = props.concern.value.map((concern) => concern);

const countryList = props.countries.value.map((country) => country);

// Scroll back to form top to show success message after submit
watch(
  () => isSent.value,
  (isSent) => {
    if (isSent) {
      const formTop = document.getElementsByClassName("contact-form__inner")[0] as HTMLElement;
      nextTick(() => scrollTo(formTop));
    }
  },
);
</script>

<style lang="scss">
@use "./SparContactForm.scss";
</style>
