<template>
  <div class="additional-service">
    <spar-checkbox
      v-model="selected"
      class="additional-service__checkbox"
      :val="serviceId"
      label=""
      :aria-describedby="`info-${serviceId}`"
      :tosca-prefix="`${toscaPrefix}-additional-service-${title.toLocaleLowerCase()}`"
    >
      <template #label-link>
        <span class="additional-service__name">{{ title }}</span>
        <span class="additional-service__price f-bold">{{ priceString }}</span>
      </template>
    </spar-checkbox>
    <span class="additional-service__info">
      <spar-tooltip
        v-if="description"
        :aria-id="`info-${serviceId}`"
        :position="TooltipPosition.right"
        :text="description"
      >
        <spar-icon-sprite symbol="info" />
      </spar-tooltip>
    </span>
  </div>
</template>

<script lang="ts" setup>
import SparCheckbox from "~/components/shared/SparCheckbox/SparCheckbox.vue";
import SparIconSprite from "~/components/shared/SparIconSprite/SparIconSprite.vue";
import { TooltipPosition } from "~/components/shared/SparTooltip/SparTooltip.types";
import SparTooltip from "~/components/shared/SparTooltip/SparTooltip.vue";
import type { SparAdditionalServiceProps } from "./SparAdditionalService.types";

const props: SparAdditionalServiceProps = defineProps({
  serviceId: {
    type: String,
    required: true,
  },
  title: {
    type: String,
    required: true,
  },
  description: {
    type: String,
    default: "",
  },
  priceString: {
    type: String,
    default: "",
  },
  modelValue: {
    type: Array as PropType<string[]>,
    required: true,
  },
  toscaPrefix: {
    type: String,
    default: "",
  },
});

const emit = defineEmits(["update:modelValue"]);

const selected = computed({
  get: () => (props.modelValue.includes(props.serviceId) ? props.serviceId : ""),
  set: () => handleServiceSelection(),
});

const handleServiceSelection = async () => {
  let selectedServices = props.modelValue;
  if (props.modelValue.includes(props.serviceId)) {
    selectedServices = selectedServices.filter((id) => id !== props.serviceId);
  } else {
    selectedServices.push(props.serviceId);
  }

  emit("update:modelValue", selectedServices);
};
</script>

<style lang="scss">
@use "./SparAdditionalService.scss";
</style>
