import { useDebounceFn, useTimeoutFn } from "@vueuse/core";
import useNotification, {
  NotificationTimeout,
  NotificationType,
} from "~/components/shared/SparNotification/useNotification";
import {
  SparQuantityButtonActionType,
  type SparQuantityButtonProps,
} from "~/components/shared/SparQuantityButton/SparQuantityButton.types";
import { useBaseSites } from "~/composables/base-sites/baseSites";
import useI18n from "~/composables/i18n/useI18n";
import { useCartStore } from "~/stores/cart.store";
import {
  AvailabilityPossibility,
  SparBaseStoreTypes,
  SparProductType,
} from "~/utils/mdsa/integration/mdsa.types";

export function useQuantitySelector(props: SparQuantityButtonProps) {
  const { getCartStores } = useBaseSites();
  const cartStores = getCartStores();
  const quantity = ref(0);
  const quantityUpdatedText = ref("");
  const quantityUpdating = ref(false);
  const { $t } = useI18n();
  const { pushNotification } = useNotification();

  // set tooltip value to "update" as default
  let buttonAction: SparQuantityButtonActionType = SparQuantityButtonActionType.update;

  const {
    params: { baseSite },
  } = useRoute();

  const isVoucher = props.sparProductType === SparProductType.Voucher;

  let baseStore =
    props.availabilityType === AvailabilityPossibility.P || isVoucher
      ? SparBaseStoreTypes.national
      : SparBaseStoreTypes.timeslot;

  // if baseStore from url is given - f.e. cart-page check products only from given store
  if (
    baseSite &&
    !Array.isArray(baseSite) &&
    Object.keys(SparBaseStoreTypes).includes(baseSite) &&
    props.cartEntryUpdate
  ) {
    baseStore = baseSite as SparBaseStoreTypes;
  }

  // get cart store
  // and check for product in cart to be able to prefill quantity beer bottles or beer box
  const cartStore: ReturnType<ReturnType<typeof useCartStore>> = cartStores[baseStore]();
  const lookedProduct = computed(() => cartStore.getLookedProduct(props.productInfo.productId));

  // just for initial loading to prevent hydration problem and set values even if
  // rendering was on server side
  const init = ref(false);
  watch(
    () => lookedProduct.value,
    () => {
      if (!init.value && cartStore.productLookUp[props.productInfo.productId]) {
        init.value = true;
        setQuantity();
      }
    },
  );

  function setQuantity() {
    if (props.sparProductType === SparProductType.Voucher) {
      if (lookedProduct.value?.deliveryMode === props.availabilityType) {
        quantity.value = lookedProduct.value.quantity;
      }
    } else if (lookedProduct.value) {
      if (lookedProduct.value.isBomArticle && lookedProduct.value.cartEntries) {
        // cartpage and servicearticle - service articles are always listed separate in the cart
        // therefore we have to get the cartEntry from the lookedProduct
        if (props.cartEntryUpdate && props.entryNumber !== undefined) {
          quantity.value = lookedProduct.value.cartEntries[props.entryNumber]?.quantity || 0;
        } else {
          quantity.value = lookedProduct.value.bomSumUpQuantity;
        }
      } else {
        quantity.value = lookedProduct.value.quantity;
      }
    }
  }

  function checkMaxMin(newQuantity: number): number {
    const {
      minOrderQuantity,
      maxOrderQuantity,
      isBomArticle,
      cartEntries,
      bomSumUpQuantity,
      quantity,
    } = lookedProduct.value;

    let updateableAmount = null;
    if (isBomArticle) {
      // for bomArticle we have to check how many entries there are in the cart
      // use the sumUpQuantity to get the difference and update the cart quantity
      // we also have to differentiate if we are on a cartAction-Page or on f.e. plp, pdp etc
      const currentCartQuantity =
        props.cartEntryUpdate && cartEntries && props.entryNumber
          ? cartEntries[props.entryNumber].quantity
          : bomSumUpQuantity;

      const completeQuantityToUpdate = newQuantity - currentCartQuantity + bomSumUpQuantity;

      const boundedQuantity = Math.max(
        minOrderQuantity,
        Math.min(maxOrderQuantity, completeQuantityToUpdate),
      );

      // how much can be added or can be removed depending on min and maxquantities
      updateableAmount =
        completeQuantityToUpdate - (completeQuantityToUpdate - boundedQuantity + bomSumUpQuantity);
      if (completeQuantityToUpdate > maxOrderQuantity) {
        setButtonToolTip("maximum");
      } else if (completeQuantityToUpdate < minOrderQuantity) {
        setButtonToolTip("minimum");
      }
    } else {
      // for not bom articles just check the boundaries and update the quantity
      const boundedQuantity = Math.max(minOrderQuantity, Math.min(maxOrderQuantity, newQuantity));

      updateableAmount = boundedQuantity - quantity;

      if (newQuantity > maxOrderQuantity) {
        setButtonToolTip("maximum");
      } else if (newQuantity < minOrderQuantity) {
        setButtonToolTip("minimum");
      }
    }

    return updateableAmount;
  }

  const addOrUpdate = useDebounceFn(async (updateQuantity: number, selectedServices = []) => {
    let reduceQuantity = false;
    quantityUpdating.value = true;

    if (lookedProduct.value && Number(updateQuantity)) {
      const { isBomArticle } = lookedProduct.value;

      const checkedQuantity = checkMaxMin(updateQuantity);

      if (checkedQuantity < 0) {
        reduceQuantity = true;
      }

      // if nothing changes do nothing
      if (checkedQuantity === 0) {
        // check for service-article and set products cart-quantity
        let cartEntryQuantity = isBomArticle
          ? lookedProduct.value.bomSumUpQuantity
          : lookedProduct.value.quantity;

        if (props.cartEntryUpdate && props.entryNumber) {
          cartEntryQuantity =
            isBomArticle && lookedProduct.value.cartEntries
              ? lookedProduct.value.cartEntries[props.entryNumber].quantity
              : lookedProduct.value.quantity;
        }

        quantity.value = cartEntryQuantity;
        quantityUpdating.value = false;
        return;
      }

      updateQuantity = checkedQuantity;
    }

    // if product is already in the cart and we remove the complete product
    // reduce the whole quantity of the current cart quantity
    if (lookedProduct.value && Number(updateQuantity) === 0) {
      reduceQuantity = true;
      // reduce the quantity of the cart-product
      updateQuantity = lookedProduct.value.isBomArticle
        ? lookedProduct.value.bomSumUpQuantity
        : lookedProduct.value.quantity;

      if (props.cartEntryUpdate) {
        updateQuantity = 0;
      }

      // set tooltip value to "remove"
      buttonAction = SparQuantityButtonActionType.remove;
    }

    if (!lookedProduct.value) {
      // set tooltip value to "add" because there is not entry in the cart
      buttonAction = SparQuantityButtonActionType.add;
    }

    // on cartEntryUpdate (f.e. cart-page) we habe to update the hybris-cart-entry by number
    // otherwhise we will perform an addToCart (plp or pdp)
    if (props.cartEntryUpdate) {
      await updateCartQuantity(updateQuantity);
    } else {
      // on plp, pdp f.e.
      await addOrReduceProductToCart(updateQuantity, reduceQuantity, selectedServices);
    }
    // set quantity again to set the be-given value
    setQuantity();

    quantityUpdating.value = false;
    setButtonToolTip(buttonAction);
  }, 500);

  function setButtonToolTip(buttonAction: SparQuantityButtonActionType) {
    quantityUpdatedText.value = $t(`quantity.button.${buttonAction}`);

    useTimeoutFn(() => {
      quantityUpdatedText.value = "";
    }, 4000);
  }

  async function updateCartQuantity(updateQuantity: number) {
    if (props.entryNumber === undefined) {
      return;
    }

    const newCartEntryQuantity =
      (lookedProduct.value.isBomArticle && lookedProduct.value.cartEntries
        ? lookedProduct.value.cartEntries[props.entryNumber].quantity
        : lookedProduct.value.quantity) + updateQuantity;

    try {
      if (!updateQuantity) {
        await cartStores[baseStore]().removeFromCart(props.entryNumber);
        await cartStores[baseStore]().validateCart();
        return;
      }
      await cartStores[baseStore]().updateCartItem(newCartEntryQuantity, props.entryNumber);
    } catch (e) {
      pushNotification(
        $t("quantity.button.error.generic"),
        NotificationType.Error,
        NotificationTimeout.Medium,
      );
    }
  }

  /**
   * Add or reduce product quantity in cart on PLP or PDP
   * Data is not given by SAP - updates will be performed by product-code not by entryNumer (as f.e. on cart-page)
   *
   * @param {number}  updateQuantity     new product quantity / difference
   * @param {boolean}  reduce     add or reduce product on plp or pdp
   * @param {string[]}  selectedServices   add selected services to product
   */
  async function addOrReduceProductToCart(
    updateQuantity: number,
    reduce = false,
    selectedServices: string[],
  ) {
    if (!reduce) {
      try {
        await cartStores[baseStore]().addToCart(
          props.productInfo.productId,
          updateQuantity,
          props.availabilityType,
          selectedServices,
        );
      } catch (e) {}
    } else {
      try {
        await cartStores[baseStore]().reduceCartProductQuantity(
          props.productInfo.productId,
          Math.abs(updateQuantity),
          props.availabilityType,
        );
      } catch (e) {}
    }
  }

  const showSalesUnit = ref(true);

  const valueWithSuffix = computed(() => {
    if (
      (props.sparProductType === SparProductType.ApproximateWeight ||
        props.sparProductType === SparProductType.Weighted) &&
      showSalesUnit.value
    ) {
      return `${quantity.value}${props.productInfo.salesUnit}`;
    }

    return `${quantity.value}`;
  });

  const toggleSuffix = (showSuffix: boolean) => {
    showSalesUnit.value = showSuffix;
  };

  return {
    addOrUpdate,
    quantity,
    quantityUpdatedText,
    quantityUpdating,
    setQuantity,
    valueWithSuffix,
    toggleSuffix,
  };
}
