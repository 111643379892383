import type { ContentSliderSection } from "@shop-storefront/utils";

type SparContentSliderItem = ContentSliderSection["sliderItems"][number];
export type SparContentSliderItemProps = Omit<SparContentSliderItem, "component" | "title">;

export type SparContentSliderProps = Omit<
  ContentSliderSection,
  "component" | "title" | "sliderItems"
> & {
  sliderItems: SparContentSliderItemProps[];
};

export const ColorTheme = {
  dark: "dark",
  light: "light",
} as const;
export type ColorTheme = (typeof ColorTheme)[keyof typeof ColorTheme];
