import { useBreakpoints } from "@vueuse/core";

export function useScreenInfo() {
  const customBreakpoints = {
    xs: 0,
    sm: 480,
    md: 768,
    lg: 1024,
    xl: 1440,
  };

  const breakpoints = useBreakpoints({
    ...customBreakpoints,
  });

  return {
    customBreakpoints,
    isSmallerOrEqualMd: breakpoints.smallerOrEqual("md"),
    isMobile: breakpoints.smaller("md"),
  };
}
