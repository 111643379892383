<template>
  <div class="spar-plp">
    <div class="spar-plp__content">
      <section class="spar-plp__left">
        <spar-sub-navigation
          v-if="subNavigation?.level === 3 || subNavigation?.level === 4"
          class="spar-plp__sub-navigation"
          :sub-navigation="subNavigation"
        />

        <div v-if="searchResults?.products.facets" class="spar-plp__results-sorting">
          <spar-plp-facets :facets="searchResults.products.facets"></spar-plp-facets>
        </div>
      </section>

      <section v-if="searchResults" class="spar-plp__right">
        <div v-if="searchResults.products.sortItems" class="spar-plp__results-sorting">
          <spar-plp-sorting :search-sorting="searchResults.products.sortItems"></spar-plp-sorting>
        </div>

        <div class="spar-plp__results">
          <spar-product-tile
            v-for="(product, index) in searchResults.products.resolvedProducts"
            v-bind="product"
            :key="index"
          ></spar-product-tile>
        </div>
      </section>
    </div>
  </div>
</template>

<script lang="ts" setup>
import queryString from "query-string";
import { SparSubNavigation } from "~/components/shared";
import SparProductTile from "~/components/shared/SparProductTile/SparProductTile.vue";
import { useSearchStore } from "~/stores/search.store";
import SparPlpFacets from "./SparPlpFacets/SparPlpFacets.vue";
import SparPlpSorting from "./SparPlpSorting/SparPlpSorting.vue";

const {
  params: { categoryId },
  fullPath,
} = useRoute();

const searchStore = useSearchStore();
const { searchResults } = storeToRefs(useSearchStore());

const { $indexes } = useNuxtApp();
// TODO: Check this function as soon as data is available!
const subNavigation = $indexes.getSubNavigation(categoryId as string);

searchStore.setQuery(
  queryString.parse(fullPath.split("?")[1], {
    parseNumbers: true,
    arrayFormat: "comma",
  }),
);
</script>

<style lang="scss">
@use "./SparPlp.scss";
</style>
