<template>
  <div class="leaflets-overview container">
    <section class="leaflets-details__sections">
      <template v-if="content?.[0]?.topSections">
        <render-sections :content="content[0].topSections" />
      </template>
    </section>
    <div class="leaflets-overview__container">
      <div class="leaflets-overview__filters">
        <spar-leaflets-filter
          tosca-prefix="leaflets-overview"
          :selected-option="selectedRegion"
          :label="$t('leaflets.overview.region.label')"
          :options="regions"
          label-level="1"
          @select="handleRegionSelection"
        />
      </div>
      <ul v-if="papersForSelectedRegion" class="leaflets-overview__leaflets">
        <li
          v-for="(leaflet, index) in papersForSelectedRegion"
          :key="index"
          class="leaflets-overview__leaflet-item"
        >
          <NuxtLink
            :to="getLeafletUrl(leaflet.url)"
            class="leaflets-overview__leaflet link"
            :title="leaflet.name"
            :data-tosca="`leaflets-overview-${index}-link`"
          >
            <spar-adaptive-image
              v-if="leaflet.structure && leaflet.structure[1].url"
              class="leaflets-overview__leaflet-image"
              :src="leaflet.structure[1].url"
              :alt="leaflet.name"
              tosca-prefix="leaflets-overview"
            />
            <div class="leaflets-overview__leaflet-text">
              <div
                class="f-bold"
                :data-tosca="`leaflets-overview-${getSanitizedIdString(leaflet.name)}-title`"
              >
                {{ getSanitizedLeafletName(leaflet.name) }}
              </div>
              <div
                v-if="leaflet.settings && leaflet.settings[0].value"
                class="leaflets-overview__leaflet-validity"
                :data-tosca="`leaflets-overview-${getSanitizedIdString(
                  leaflet.name,
                )}-validity-date`"
              >
                {{ leaflet.settings[0].value }}
              </div>
            </div>
          </NuxtLink>
        </li>
      </ul>
    </div>
    <section class="leaflets-details__sections">
      <template v-if="content?.[0]?.bottomSections">
        <render-sections :content="content[0].bottomSections" />
      </template>
    </section>
  </div>
</template>

<script lang="ts" setup>
import type { LeafletsConfig, LeafletsOverviewPage } from "@shop-storefront/utils";
import SparLeafletsFilter from "~/components/feature/SparLeaflets/SparLeafletsFilter/SparLeafletsFilter.vue";
import RenderSections from "~/components/sections/RenderSections/RenderSections.vue";
import { SparAdaptiveImage } from "~/components/shared";
import { ContentstackPageParams } from "~/plugins/contentstack/contentstack.types";
import { useIpaperStore } from "~/stores/ipaper.store";
import ContentType from "~/utils/contentstack/constants/content-types.constants";
import { leafletsOverviewPageReferences } from "~/utils/contentstack/constants/references.constants";
import { resolvePageSeo } from "~/utils/contentstack/resolvers/gloabal-fields/page-seo/page-seo.resolvers";
import { getSanitizedIdString } from "~/utils/ui";

const { $contentstack } = useNuxtApp();
const router = useRouter();
const {
  params: { region },
} = useRoute();
const { getPath } = useRoutes();

const { getLeafletKeyFromUrl, getSanitizedLeafletName, init, setIpaperConfig } = useIpaperStore();

const { papersForSelectedRegion, regions, selectedRegion } = storeToRefs(useIpaperStore());

// Load Contentstack Sections for SSR
const { data: content } = await useAsyncData(ContentType.leafletsOverviewPage, async () => {
  return await $contentstack.getEntries<LeafletsOverviewPage>({
    type: ContentType.leafletsOverviewPage,
    customQuery: {
      [ContentstackPageParams.leafletRegion]: region.toString(),
    },
    includeReference: leafletsOverviewPageReferences as string[],
  });
});

// Load Config for SSR
const { data: config } = await useAsyncData(ContentType.leafletsConfig, async () => {
  return await $contentstack.getEntries<LeafletsConfig[]>({
    type: ContentType.leafletsConfig,
    includeCount: true,
  });
});

if (config.value?.[0]?.[0]) {
  setIpaperConfig(config.value[0][0]);

  // Fetch Data from iPaper Integration for SSR
  await init(region.toString());
}

const getLeafletUrl = (url: string): string => {
  const leafletKey = getLeafletKeyFromUrl(url);
  return `${getPath("leaflets")}/${selectedRegion.value?.key}/${leafletKey}`;
};

const handleRegionSelection = (region: string) => {
  router.push(`${getPath("leaflets")}/${region}`);
};

if (content?.value?.[0]?.seo) {
  useHead(resolvePageSeo(content.value[0].seo));
}
</script>

<style lang="scss">
@use "SparLeafletsOverview.scss";
</style>
