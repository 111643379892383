import FormIntegration from "~/utils/form/form.integration";
import type { InternalFetch } from "../fetch";

export default defineNuxtPlugin(async ({ $internalFetch }) => {
  return {
    provide: {
      form: new FormIntegration($internalFetch as InternalFetch),
    },
  };
});
