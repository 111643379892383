<template>
  <div
    class="spar-input"
    :class="{
      'spar-input--has-text': !!internalValue,
      'spar-input--invalid': !isValid || isForceInvalid,
      'spar-input--required': required,
    }"
  >
    <div class="spar-input__wrapper">
      <label class="spar-input__label will-change" :for="uniqueId">
        <slot name="label" v-bind="{ label }">{{ label }}</slot>
      </label>
      <input
        :id="uniqueId"
        ref="inputRef"
        v-model="internalValue"
        class="spar-input__input"
        :data-tosca="getToscaPrefix('input', toscaPrefix)"
        :required="required"
        :disabled="disabled"
        :maxlength="maxlength"
        :minlength="minlength || undefined"
        :min="getMinMax('min')"
        :max="getMinMax('max')"
        :name="name"
        :class="{ 'spar-input--is-password': isPasswordType }"
        :type="inputTypeRef"
        :aria-invalid="!isValid"
        :aria-required="required"
        :aria-describedby="errorMessage || legend ? `${uniqueId}-legend` : undefined"
        @blur="onBlur"
        @keydown.enter="onBlur"
        @input="emitValue"
      />
      <!-- <span class="spar-input__bar"></span> -->

      <spar-button v-if="icon" class="spar-input__button btn--clear" @click="$emit('click:icon')">
        <spar-icon-sprite :symbol="icon" class="spar-input__icon" />
      </spar-button>
      <spar-button
        v-if="isPasswordType"
        class="spar-input__password-button btn--clear"
        type="button"
        :aria-label="switchPasswordLabel"
        :aria-pressed="isPasswordVisible"
        @click="switchVisibilityPassword()"
      >
        <spar-icon-sprite
          class="spar-input__password-icon"
          :class="{
            hidden: !isPasswordVisible,
          }"
          symbol="show-password"
        ></spar-icon-sprite>
      </spar-button>
    </div>
    <div :id="`${uniqueId}-legend`" class="spar-input__legend">
      <!-- TODO: Visual Indicator -->
      <span
        v-if="isPasswordType && showStrength && internalValue"
        class="spar-input__password-strength"
        >{{ passwordStrengthText }}</span
      >

      <span aria-live="polite">
        <!-- Error Message from Validation engine -->
        <span
          v-if="!isValid"
          class="spar-input__error-message"
          :data-tosca="getToscaPrefix('error-msg', toscaPrefix)"
        >
          {{ errorMessage }}
        </span>

        <!-- status and text - f.e. for coupon input -->
        <span
          v-if="statusMessage"
          :class="`spar-input__status-${statusMessage.status}`"
          :data-tosca="getToscaPrefix(`${statusMessage.status}-msg`, toscaPrefix)"
        >
          {{ statusMessage.text }}
        </span>
      </span>

      <span v-if="isValid && legend && !$slots['legend']">{{ legend }}</span>
      <slot v-else-if="isValid" name="legend"></slot>
    </div>
  </div>
</template>

<script lang="ts" setup>
import SparButton from "~/components/shared/SparButton/SparButton.vue";
import SparIconSprite from "~/components/shared/SparIconSprite/SparIconSprite.vue";
import useI18n from "~/composables/i18n/useI18n";
import { Strength } from "~/composables/inputs/passwordStrength";
import { useInputValue } from "~/composables/inputs/useInputValue";
import { getToscaPrefix } from "~/utils/ui";
import {
  type SparInputProps,
  SparInputType,
  type SparInputValidator,
  type InputStatusMessage,
} from "./SparInput.types";

const props: SparInputProps = defineProps({
  modelValue: {
    type: String,
    default: "",
  },
  label: {
    type: String,
    default: null,
  },
  legend: {
    type: String,
    default: null,
  },
  statusMessage: {
    type: Object as PropType<InputStatusMessage>,
    default: null,
  },
  name: {
    type: String,
    default: null,
  },
  type: {
    type: String as PropType<SparInputType>,
    default: "text",
  },
  icon: {
    type: String,
    default: null,
  },
  required: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  maxlength: {
    type: Number,
    default: 100,
  },
  minlength: {
    type: Number,
    default: 0,
  },
  min: {
    type: Number,
    default: 0,
  },
  max: {
    type: Number,
    default: 99,
  },
  minDate: {
    type: Number,
    default: 19000101, // 1900-01-01
  },
  maxDate: {
    type: Number,
    default: 20991231, // 2099-12-31
  },
  validators: {
    type: Array as PropType<SparInputValidator[]>,
    default: () => [] as SparInputValidator[],
  },
  showStrength: {
    type: Boolean,
    default: false,
  },
  toscaPrefix: {
    type: String,
    default: undefined,
  },
});

const emit = defineEmits(["update:modelValue", "interface", "click:icon", "onUnmount"]);

const {
  emitValue,
  errorMessage,
  internalValue,
  isForceInvalid,
  isValid,
  isValidDateMinMax,
  onBlur,
  passwordStrength,
  uniqueId,
} = useInputValue(props, emit);

const { $t } = useI18n();

const isPasswordVisible = ref(false);
const inputTypeRef = ref(props.type);
const inputRef = ref<undefined | HTMLInputElement>(undefined);

const isPasswordType = computed(() => props.type === SparInputType.password);
const isNumberType = computed(() => props.type === SparInputType.number);
const isDateType = computed(() => props.type === SparInputType.date);

const switchVisibilityPassword = () => {
  if (props.type !== SparInputType.password) return;
  isPasswordVisible.value = !isPasswordVisible.value;
  inputTypeRef.value = isPasswordVisible.value ? SparInputType.text : SparInputType.password;
};

const switchPasswordLabel = computed(() => {
  if (isPasswordVisible.value) {
    return $t("form.password.hide");
  }
  return $t("form.password.show");
});

const passwordStrengthText = computed(() => {
  switch (passwordStrength.value) {
    case Strength.veryWeak:
    case Strength.weak:
      return $t("form.validation.password.strength.weak");
    case Strength.medium:
      return $t("form.validation.password.strength.medium");
    case Strength.strong:
    case Strength.veryStrong:
      return $t("form.validation.password.strength.strong");
  }
});

// Map min / max and minDate / maxDate to :min and :max, based on field type
function getMinMax(field: string) {
  let val: number | undefined = undefined;

  if (field === "min") {
    val = isDateType.value ? props.minDate : props.min;
  } else if (field === "max") {
    val = isDateType.value ? props.maxDate : props.max;
  }

  if (isNumberType.value || val === undefined) {
    return val;
  }

  if (isDateType.value) {
    if (!isValidDateMinMax(val)) return undefined;
    const str = val.toString();
    const arr = str.split("");
    return arr[0] + arr[1] + arr[2] + arr[3] + "-" + arr[4] + arr[5] + "-" + arr[6] + arr[7];
  }
}

function focusInput() {
  if (inputRef.value) inputRef.value.focus();
}

defineExpose({ focusInput });
</script>

<style lang="scss">
@use "./SparInput.scss";
</style>
