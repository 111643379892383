<template>
  <section class="my-account container">
    <nav class="my-account__nav" :class="{ 'my-account__nav-show-mobile': hideNav }">
      <div class="my-account__nav-info">
        <spar-button
          class="my-account__nav-mobile-toggle btn--secondary"
          :variant="ButtonVariant.custom"
          :aria-label="
            hideNav ? $t('my_account.nav.mobile.hide') : $t('my_account.nav.mobile.unhide')
          "
          tosca-prefix="my-account-mobile-nav-toggle"
          :icon="hideNav ? 'user-menu-close' : 'user-menu-open'"
          icon-only
          @click="hideNav = !hideNav"
        >
        </spar-button>

        <div class="my-account__content">
          <spar-heading
            class="my-account__heading"
            tosca-prefix="myaccount"
            level="2"
            headline-style="3"
            :title="$t('my_account.title')"
          />

          <p
            v-if="userData?.name"
            class="my-account__nav-salutation"
            data-tosca="my-account-salutation"
          >
            {{ $t("my_account.salutation") }} {{ userData?.name }}!
          </p>
        </div>
      </div>
      <ul class="my-account__nav-list">
        <li :class="getMenuClass(getPath('myAccount'))">
          <spar-link
            icon="placeholder"
            :icon-position="IconPosition.left"
            :variant="LinkVariant.linkIcon"
            :link="getPath('myAccount')"
            tosca-prefix="myaccount-overview"
            class="my-account__nav-list-link"
            >{{ $t("my_account.nav.overview") }}</spar-link
          >
        </li>
        <li :class="getMenuClass(getPath('myAccountPersonalData'))">
          <spar-link
            icon="placeholder"
            :icon-position="IconPosition.left"
            :variant="LinkVariant.linkIcon"
            :link="getPath('myAccountPersonalData')"
            tosca-prefix="myaccount-personaldata"
            class="my-account__nav-list-link"
            >{{ $t("my_account.nav.personal_data") }}</spar-link
          >
        </li>
        <li :class="getMenuClass(getPath('myAccountOrders'))">
          <spar-link
            icon="placeholder"
            :icon-position="IconPosition.left"
            :variant="LinkVariant.linkIcon"
            :link="getPath('myAccountOrders')"
            tosca-prefix="myaccount-orders"
            class="my-account__nav-list-link"
            >{{ $t("my_account.nav.orders") }}</spar-link
          >
        </li>
        <li :class="getMenuClass(getPath('myAccountFavorites'))">
          <spar-link
            icon="placeholder"
            :icon-position="IconPosition.left"
            :variant="LinkVariant.linkIcon"
            :link="getPath('myAccountFavorites')"
            tosca-prefix="myaccount-favorites"
            class="my-account__nav-list-link"
            >{{ $t("my_account.nav.favorites") }}</spar-link
          >
        </li>
        <li :class="getMenuClass(getPath('myAccountNewsletter'))">
          <spar-link
            icon="placeholder"
            :icon-position="IconPosition.left"
            :variant="LinkVariant.linkIcon"
            :link="getPath('myAccountNewsletter')"
            tosca-prefix="myaccount-newsletter"
            class="my-account__nav-list-link"
            >{{ $t("my_account.nav.newsletter") }}</spar-link
          >
        </li>
        <li :class="getMenuClass(getPath('myAccountLoyalityPoints'))">
          <spar-link
            icon="placeholder"
            :icon-position="IconPosition.left"
            :variant="LinkVariant.linkIcon"
            :link="getPath('myAccountLoyalityPoints')"
            class="my-account__nav-list-link"
            tosca-prefix="myaccount-loyalty-points"
            >{{ $t("my_account.nav.loyalty_points") }}</spar-link
          >
        </li>
        <li class="my-account__nav-logout">
          <spar-link
            role="button"
            icon="logout"
            :icon-position="IconPosition.left"
            :variant="LinkVariant.secondaryIcon"
            tosca-prefix="myaccount-logout"
            class="my-account__nav-list-link-logout"
            @click="logout()"
            >{{ $t("my_account.logout") }}</spar-link
          >
        </li>
      </ul>
    </nav>
    <div class="my-account__page">
      <slot />
    </div>
  </section>
</template>

<script lang="ts" setup>
import { ButtonVariant, LinkVariant, SparHeading, SparButton, SparLink } from "~/components/shared";
import { useAuthStore } from "~/stores/auth.store";
import { useUserStore } from "~/stores/user.store";
import { IconPosition } from "~/types/icon.types";

const route = useRoute();
const isLoading = ref(true);
const hideNav = ref(false);

const { getUserData } = useUserStore();
const { userData } = storeToRefs(useUserStore());
const { logout } = useAuthStore();
const { getPath } = useRoutes();

const getMenuClass = (path: string) => {
  if (route.fullPath === path) {
    return "my-account__nav-item--active";
  }
};

onMounted(async () => {
  await getUserData();
  isLoading.value = false;
});
</script>

<style lang="scss">
@use "./SparMyAccountWrapper.scss";
</style>
