<template>
  <div
    v-if="isLoginFinished && errorCode === 0"
    data-tosca="registration-confirmation-success-text"
  >
    <p>{{ $t("auth.registration_confirmation.success.title") }}</p>

    <p>{{ $t("auth.registration_confirmation.success.text") }}</p>

    <spar-button
      :label="$t('auth.registration_confirmation.success.cta')"
      tosca-prefix="registration-confirmation"
      @click="goToProfile"
    />
  </div>
  <div v-else-if="!isLoginFinished && isValid" class="registration-confirmation__loader">
    <spar-loader />
  </div>
  <div v-else>
    <p>{{ $t("auth.registration_confirmation.error.expired") }}</p>
    <spar-button :label="$t('auth.registration_confirmation.error.cta')" @click="goToLogin" />
  </div>
</template>

<script setup lang="ts">
import { SparButton, SparLoader } from "~/components/shared";
import useNotification, {
  NotificationTimeout,
  NotificationType,
} from "~/components/shared/SparNotification/useNotification";
import { GigyaRegistrationResponseCode } from "~/composables/auth/gigya.types";
import useGigya from "~/composables/auth/useGigya";
import useI18n from "~/composables/i18n/useI18n";
import { useAuthStore } from "~/stores/auth.store";

const { sdk } = useVsfSdk();
const { executeWhenIsReady } = useGigya();
const route = useRoute();
const router = useRouter();
const { pushNotification } = useNotification();
const authStore = useAuthStore();
const { $t } = useI18n();
const { getPath } = useRoutes();

const errorCode = ref(0);
const isLoginFinished = ref(false);

const isValid = computed(() => {
  return errorCode.value === GigyaRegistrationResponseCode.PendingAutologinFinalization;
});

const goToLogin = () => {
  router.push(getPath("login"));
};

const goToProfile = () => {
  router.push(getPath("myAccount"));
};

const startAutoLogin = async () => {
  try {
    const eventObj = await sdk.commerce.signIn();
    errorCode.value = eventObj.errorCode;
    authStore.login();
  } catch (e) {
    // Gigya succeeded but OAuth failed - should not happen in a live env
    // (Gigya and SAP CC out of sync)
    pushNotification(
      $t("global.error.unknown"),
      NotificationType.Error,
      NotificationTimeout.Medium,
    );
    router.push("/");
  } finally {
    isLoginFinished.value = true;
  }
};

onMounted(() => {
  const params = route.query;
  // Calling this page without an errorCode param is possibly unintended
  if (!params.errorCode) {
    router.push("/");
    return false;
  }
  if (!params.regToken) return false;

  errorCode.value = Number.parseInt(params.errorCode.toString());

  executeWhenIsReady(() => {
    startAutoLogin();
  });
});
</script>

<style lang="scss">
@use "./SparRegistrationConfirmation.scss";
</style>
