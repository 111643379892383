<template>
  <div
    v-if="buyBox.prices?.promoText"
    class="buy-box-small__promo-badge"
    data-tosca="plp-small-promo-badge"
  >
    {{ buyBox.prices?.promoText }}
  </div>
  <div class="buy-box-small">
    <div class="buy-box-small__content-left">
      <div class="buy-box-small__availability-text-wrapper">
        <span
          v-if="buyMessage"
          class="buy-box-small__availability-text"
          data-tosca="plp-availability"
        >
          {{ $t(`plp.product.tile.availability.${buyMessage}`) }}
        </span>

        <spar-icon-sprite
          class="buy-box-small__availability-icon"
          :class="{ 'buy-box-small--available': buyable }"
          :symbol="buyable ? 'check-rounded' : 'close-rounded'"
        />
      </div>
    </div>
    <div class="buy-box-small__content-right">
      <div v-if="buyBox.prices" class="buy-box-small__price-wrapper">
        <div
          v-if="buyBox.prices?.recommendedRetailPriceString"
          class="buy-box-small__uvp"
          data-tosca="plp-uvp-price"
        >
          {{ buyBox.prices.recommendedRetailPriceString }}
        </div>
        <div
          v-if="buyBox.prices?.insteadPriceString"
          class="buy-box-small__instead-price"
          data-tosca="plp-instead-price"
        >
          {{ buyBox.prices.insteadPriceString }}
        </div>
        <div class="buy-box-small__price" data-tosca="plp-price">
          {{ buyBox.prices.salesPriceString }}
        </div>
        <div
          v-if="buyBox.prices?.referencePriceString"
          class="buy-box-small__reference-price"
          data-tosca="plp-reference-price"
        >
          {{ buyBox.prices.referencePriceString }}
        </div>
      </div>
      <div class="buy-box-small__actions">
        <div class="buy-box-small__amount-wrapper">
          <spar-quantity-button
            v-if="buyable"
            tosca-prefix="plp"
            :spar-product-type="sparProductType"
            :product-info="mainInfo"
            :boundaries="boundaries"
            :availability-type="buyBox.priceType"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { SparIconSprite } from "~/components/shared";
import type { SparQuantityButtonBoundaries } from "~/components/shared/SparQuantityButton/SparQuantityButton.types";
import SparQuantityButton from "~/components/shared/SparQuantityButton/SparQuantityButton.vue";
import useI18n from "~/composables/i18n/useI18n";
import type { ProductMainInfo, ProductPriceItem } from "~/utils/mdsa/integration/mdsa.types";
import type { SparBuyBoxSmallProps } from "./SparBuyBoxSmall.types";

defineProps({
  boundaries: {
    type: Object as PropType<SparQuantityButtonBoundaries>,
    default: () => ({}),
  },
  buyBox: {
    type: Object as PropType<ProductPriceItem>,
    required: true,
  },
  buyMessage: {
    type: String,
    default: "",
  },
  buyable: {
    type: Boolean,
    default: true,
  },
  mainInfo: {
    type: Object as PropType<ProductMainInfo>,
    required: true,
  },
  sparProductType: {
    type: String,
    default: "",
  },
}) as SparBuyBoxSmallProps;

const { $t } = useI18n(); // Explicit Import for Storybook
</script>

<style lang="scss">
@use "./SparBuyBoxSmall.scss";
</style>
