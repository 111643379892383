import { defineStore } from "pinia";
import { type RouteName } from "~~/configs/routes";
import type { CheckoutStepCondition } from "~/components/feature/SparCheckout/SparCheckout.types";
import type {
  Consent,
  ConsentTemplate,
} from "~/components/feature/SparCheckout/SparCheckoutSummary/SparCheckoutConsents/SparCheckoutConsents.types";
import { useBaseSites } from "~/composables/base-sites/baseSites";
import { useBaseSiteStore } from "~/stores/basesite.store";
import type { Address, Error as OccError, Order } from "~/types/occ.types";
import { CartError, handleCommerceError } from "~/utils/error";
import { useCartStore } from "./cart.store";

export const useCheckoutStore = defineStore("checkout", () => {
  const order = ref<Order | null>(null);
  const createInitialAddress = ref(false);
  const createNewAddress = ref(false);
  const addressFormData = ref<Address | null>(null);

  const context = ref("");
  const consentsGiven = ref(false);
  const getCartConsentTemplatesLoading = ref(false);
  const giveCartConsentsLoading = ref(false);
  const placeOrderLoading = ref(false);
  const placeOrderAfterRedirectLoading = ref(false);
  const consentTemplates = ref<ConsentTemplate[] | null>(null);
  const consents = ref<Consent>({});
  const cartContext = ref<ReturnType<ReturnType<typeof useCartStore>> | null>(null);

  const { replaceBaseSiteInRoute } = useBaseSites();
  const { baseSiteConfig } = useBaseSiteStore();
  const router = useRouter();
  const { sdk } = useVsfSdk();
  const { $t } = useNuxtApp();

  const conditions: CheckoutStepCondition[] = [
    {
      step: "checkoutDelivery",
      conditions: ["deliveryAddress"],
    },
    {
      step: "checkoutPayment",
      conditions: ["paymentAddress", "paymentInfo"],
    },
    {
      step: "checkoutSummary",
      conditions: [],
    },
  ];

  watch(
    () => context.value,
    (cartType) => {
      if (cartType) {
        loadCart();
      }
    },
  );

  const loadCart = async () => {
    const cartStore = useCartStore(context.value)();
    await cartStore.refreshCartCheckout();

    cartContext.value = cartStore;
  };

  const setContext = (contextCart: string) => {
    context.value = contextCart;
  };

  const setCreateInitialAddress = (state: boolean) => {
    createInitialAddress.value = state;
  };

  const setCreateNewAddress = (state: boolean) => {
    createNewAddress.value = state;
  };

  const setAddressFormData = (address: Address | null) => {
    addressFormData.value = address;
  };

  function storeOrder(o: Order) {
    order.value = o;
  }

  /*
   * Add check of checkout step for refreshed page
   * Logic is handled by check-in.vue
   */
  const checkStep = (step: string, baseSite: string): string | undefined => {
    // page-index of given page (from url)
    const givenPageIndex = conditions.findIndex(
      (item) => replaceBaseSiteInRoute(item.step, baseSite) === step,
    );

    // page-index with missing property
    const missingPageIndex = conditions.findIndex(
      (item) =>
        !item.conditions.every((condition) => Boolean(cartContext.value?.cart?.[condition])),
    );

    // no page with missing properties found, link to given page.
    if (missingPageIndex < 0) return step;

    // check if givenPageIndex is lower then the page-index of the page with a missing property
    // if all pages until the given page don't have missing properties, link to the given page
    // else link to the step, where the user needs to perform an action
    return givenPageIndex < missingPageIndex
      ? step
      : replaceBaseSiteInRoute(conditions[missingPageIndex]?.step, baseSite);
  };

  /**
   * Check if user can proceed to next step by clicking on "next"
   * Will trigger an error
   *
   * @param step Route
   * @returns
   */
  const checkForNextStep = (step: RouteName): boolean => {
    const toCheck = conditions.find((condition) => condition.step === step);

    if (toCheck) {
      return toCheck.conditions.every((condition) => Boolean(cartContext.value?.cart?.[condition]));
    }
    return false;
  };

  /**
   * Set the consent state of the checkout.
   *
   * @param isValid Boolean
   */
  const setConsentsGivenState = (isConsentsGiven: boolean) => {
    consentsGiven.value = isConsentsGiven;
  };

  /**
   * Fetch all consents, which need to be displayed in the checkout, from Hybris.
   *
   * @param {string} [cartId]
   * @throws {CartError}
   */
  const getCartConsentTemplates = async (cartId?: string) => {
    if (!cartContext.value) return;

    getCartConsentTemplatesLoading.value = true;

    try {
      const { data: consents } = await sdk.commerce.getCartConsentTemplates(
        cartId || cartContext.value.cartCookie || "current",
        cartContext.value.getUserId(),
        cartContext.value.bs,
      );

      if (!consents || !consents.consentTemplates) return;

      consentTemplates.value = consents.consentTemplates;
    } catch (error) {
      handleCommerceError(error as Error);
      throw new CartError($t("checkout.error.could_not_get_consents"));
    } finally {
      getCartConsentTemplatesLoading.value = false;
    }
  };

  /**
   * Send all consents, which were accepted by the customer, to Hybris.
   *
   * @param {string} [cartId]
   * @throws {CartError}
   */
  async function giveCartConsents(cartId?: string): Promise<void> {
    if (!cartContext.value || !consentTemplates.value) return;

    giveCartConsentsLoading.value = true;

    try {
      for (const template of consentTemplates.value) {
        if (consents.value.hasOwnProperty(template.id)) {
          // consent was given - send to hybris
          await sdk.commerce.giveCartConsent(
            template.id,
            parseInt(template.version),
            cartId || cartContext.value.cartCookie || "current",
            cartContext.value.getUserId(),
            cartContext.value.bs,
          );
        }
      }
    } catch (error) {
      handleCommerceError(error as Error);
      throw new CartError($t("checkout.error.could_not_set_consent"));
    } finally {
      giveCartConsentsLoading.value = false;
    }
  }

  /**
   * Set consents and place order. Redirect to confirmation page if checkout was successful.
   *
   * @throws {CartError}
   */
  async function placeOrder(): Promise<void> {
    if (!cartContext.value || !consentsGiven.value) return;

    placeOrderLoading.value = true;

    try {
      // give consents before placeOrder
      await giveCartConsents();

      const cartId = (
        cartContext.value.isGuestCart()
          ? cartContext.value.cart?.guid
          : cartContext.value.cart?.code
      ) as string;

      const order = (await sdk.commerce.placeOrder(
        {
          cartId,
        },
        baseSiteConfig(cartContext.value.bs),
      )) as Order | OccError;

      // Check if we need to redirect to the payment provider for transaction authorization
      if ("reason" in order && order.reason === "REDIRECT" && order.subject) {
        window.location.href = order.subject;
      } else {
        handlePlaceOrderSuccess(order as Order);
      }
    } catch (error) {
      const handled = handleCommerceError(error as Error);
      if (!handled) {
        throw new CartError($t("checkout.error.could_not_place_order"));
      }
    } finally {
      placeOrderLoading.value = false;
    }
  }

  /**
   * Payment redirect was successful. Place the order.
   *
   * @param {string} [paymentId]
   * @param {string} [cartId]
   * @throws {CartError}
   */
  async function placeOrderAfterRedirect(
    paymentId: string,
    redirectMessageAuthenticationCode: string,
    cartId: string,
  ): Promise<void> {
    if (!cartContext.value) return;

    placeOrderAfterRedirectLoading.value = true;

    try {
      const { data: order } = (await sdk.commerce.placeOrderAfterRedirect(
        cartId,
        "current",
        paymentId,
        redirectMessageAuthenticationCode,
        cartContext.value.bs,
      )) as { data: Order | OccError };

      // Check for error
      if ("type" in order && order.type === "PaymentException") {
        throw new Error();
      }

      handlePlaceOrderSuccess(order as Order);
    } catch (error) {
      const handled = handleCommerceError(error as Error);
      if (!handled) {
        throw new CartError($t("checkout.error.could_not_place_order"));
      }
    } finally {
      placeOrderAfterRedirectLoading.value = false;
    }
  }

  /**
   * When order is placed, the cart no longer exists - store the order, clear cart, cookie and checkout.
   *
   * @param {Order} [order]
   */
  function handlePlaceOrderSuccess(order: Order) {
    if (!cartContext.value || !order) return;

    storeOrder(order);

    router.push({ name: "checkoutConfirmation", params: { baseSite: cartContext.value.bs } });

    cartContext.value.$reset();
    cartContext.value.removeCartCookie();
    $reset();
  }

  /**
   * Reset the checkout
   */
  function $reset(): void {
    addressFormData.value = null;
    cartContext.value = null;
    consents.value = {};
    consentsGiven.value = false;
    consentTemplates.value = null;
    createInitialAddress.value = false;
    createNewAddress.value = false;
    getCartConsentTemplatesLoading.value = false;
    giveCartConsentsLoading.value = false;
    placeOrderLoading.value = false;
    placeOrderAfterRedirectLoading.value = false;
  }

  return {
    addressFormData,
    cartContext,
    createInitialAddress,
    checkForNextStep,
    checkStep,
    consents,
    consentsGiven,
    consentTemplates,
    createNewAddress,
    getCartConsentTemplates,
    giveCartConsents,
    loadCart,
    order,
    placeOrder,
    placeOrderAfterRedirect,
    placeOrderLoading,
    setAddressFormData,
    setConsentsGivenState,
    setContext,
    setCreateInitialAddress,
    setCreateNewAddress,
    storeOrder,
    $reset,
  };
});
