<template>
  <spar-tabs
    v-if="availabilityTypes"
    class="buy-box__tabs"
    tosca-prefix="buybox"
    tabs-wrapper-class="buy-box__tabs-header"
    tab-header-class="buy-box__tab-header"
  >
    <template #icon__in
      ><span class="buy-box__tab-header-icon--in"
        ><spar-icon-sprite symbol="in"></spar-icon-sprite
      ></span>
    </template>

    <template #icon
      ><span class="buy-box__tab-header-icon"
        ><spar-icon-sprite symbol="sale"></spar-icon-sprite
      ></span>
    </template>

    <spar-tab
      v-for="(price, buyBoxIndex) in prices"
      :key="buyBoxIndex"
      tosca-prefix="buybox"
      class="buy-box__tab-content"
      :tab-id="`${uniqueId}${price?.priceType}`"
      :title="
        $t(`plp.product.tile.availability.${price?.priceType.replace('-', '').toLowerCase()}`)
      "
      :is-buyable="isBuyable(price?.priceType)"
      :additional-class="{
        'buy-box--onsale': Boolean(price?.prices?.onSale),
      }"
    >
      <spar-buy-box-small
        v-if="boxFormat === BuyBoxFormat.small && price"
        :buy-box="price"
        :main-info="mainInfo"
        :boundaries="getMinMax(price.priceType)"
        :spar-product-type="sparProductType"
        :buyable="isBuyable(price.priceType)"
        :buy-message="getBuyMessage(price.priceType)"
      />
      <spar-buy-box-big
        v-else-if="price"
        :availability="availability"
        :buy-box="price"
        :main-info="mainInfo"
        :spar-product-type="sparProductType"
        :buyable="isBuyable(price.priceType)"
        :buy-message="getBuyMessage(price.priceType)"
        :boundaries="getMinMax(price.priceType)"
        :additional-services="additionalServices"
        :documents="buyBoxDocuments"
      />
    </spar-tab>
  </spar-tabs>
</template>

<script lang="ts" setup>
import { usePdpProduct } from "~/components/feature/SparPdp/SparPdpProduct/usePdpProduct";
import { SparTabs, SparTab, SparIconSprite } from "~/components/shared";
import {
  BuyBoxFormat,
  type BuyBoxTab,
  type SparBuyBoxProps,
} from "~/components/shared/SparBuyBox/SparBuyBox.types";
import SparBuyBoxBig from "~/components/shared/SparBuyBox/SparBuyBoxBig/SparBuyBoxBig.vue";
import SparBuyBoxSmall from "~/components/shared/SparBuyBox/SparBuyBoxSmall/SparBuyBoxSmall.vue";
import useI18n from "~/composables/i18n/useI18n";
import {
  type ProductMainInfo,
  SparProductType,
  type ProductAvailability,
  ProductAvailabilityTypes,
  AvailabilityPossibility,
  type SparProduct,
  type ProductPicture,
  ProductDocumentType,
} from "~/utils/mdsa/integration/mdsa.types";
import { getUniqueId } from "~/utils/ui";

const props: SparBuyBoxProps = defineProps({
  availabilityTypes: {
    type: Array as PropType<AvailabilityPossibility[]>,
    default: null,
  },
  availability: {
    type: Object as PropType<ProductAvailability>,
    default: null,
  },
  additionalServices: {
    type: Array as PropType<SparProduct[]>,
    default: () => [],
  },
  mainInfo: {
    type: Object as PropType<ProductMainInfo>,
    required: true,
  },
  minOrderQuantityP: {
    type: Number,
    default: 0,
  },
  minOrderQuantityT: {
    type: Number,
    default: 0,
  },
  maxOrderQuantityP: {
    type: Number,
    default: 0, // value must always be provided by hybris/mdsa
  },
  maxOrderQuantityT: {
    type: Number,
    default: 0, // value must always be provided by hybris/mdsa
  },
  pictures: {
    type: Array as PropType<ProductPicture[]>,
    default: () => [],
  },
  prices: {
    type: Object as PropType<SparProduct["prices"]>,
    required: true,
  },
  sparProductType: {
    type: String,
    default: "",
  },
  boxFormat: {
    type: String as PropType<BuyBoxFormat>,
    default: BuyBoxFormat.small,
  },
});

const { $t } = useI18n(); // Explicit Import for Storybook

const uniqueId = getUniqueId("tab");
const { setPictures, filterProductDocuments } = usePdpProduct();

if (props.pictures) {
  setPictures(props.pictures);
}

const getMinMax = (tab: string) => {
  let availabilityTab: BuyBoxTab;
  // tab can only be "P" or "T"
  if (isVoucher.value) {
    availabilityTab = AvailabilityPossibility.P;
  } else {
    availabilityTab = tab as BuyBoxTab;
  }

  return {
    min: props[`minOrderQuantity${availabilityTab}`] as number,
    max: props[`maxOrderQuantity${availabilityTab}`] as number,
  };
};

const isVoucher = computed(
  () =>
    props.sparProductType === SparProductType.Voucher ||
    props.sparProductType === SparProductType.GWK,
);

const getBuyMessage = (tab: string) => {
  if (isVoucher.value) {
    // voucher can only have "P"
    tab = AvailabilityPossibility.P;
  }

  const message = props.availability?.eComAvailability.availabilityTypes?.filter(
    (availabilityType) => tab === availabilityType.availabilityType,
  );

  if (message && message.length) {
    return message[0].value;
  }
  return "";
};

const isBuyable = (tab: string = "") => {
  if (!tab) {
    Log.warn(LogArea.buyBox, "isBuyable - no tab-name given");
  }
  const buyMessage = getBuyMessage(tab);
  return buyMessage === ProductAvailabilityTypes.buyable;
};

const buyBoxDocuments = computed(() => filterProductDocuments(ProductDocumentType.pdfDatasheet));
</script>

<style lang="scss">
@use "./SparBuyBox.scss";
</style>
