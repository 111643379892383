export const servicesEndpoints = {
  // Middleware
  factfinder: {
    search: "/factfinder/search",
  },
  i18n: {
    getTranslations: "/i18n/getTranslations",
  },
  ipaper: {
    getPapers: "/ipaper/getPapers",
  },
  mdsa: {
    product: "/mdsa/product",
  },

  // Nuxt server api
  form: {
    submitForm: "/api/form/submit-form",
  },
  version: {
    version: "/api/version",
  },
};
