import { defineStore } from "pinia";
import useNotification, {
  NotificationTimeout,
  NotificationType,
} from "~/components/shared/SparNotification/useNotification";
import useGigya from "~/composables/auth/useGigya";
import { useBaseSites } from "~/composables/base-sites/baseSites";
import { useBaseSiteStore } from "~/stores/basesite.store";
import { useCheckoutStore } from "~/stores/checkout.store";
import { useUserStore } from "~/stores/user.store";
import { SparBaseStoreTypes } from "~/utils/mdsa/integration/mdsa.types";

export const useAuthStore = defineStore("auth", () => {
  const { sdk } = useVsfSdk();
  const { pushNotification } = useNotification();
  const { $t } = useNuxtApp();
  const router = useRouter();
  const { baseSiteConfig } = useBaseSiteStore();
  const { executeWhenIsReady, executeWhenIsSessionVerified } = useGigya();

  const isGigyaLoggedIn: Ref<boolean | undefined> = ref(undefined);
  const isOauthLoggedIn: Ref<boolean | undefined> = ref(undefined);
  const isLoggedInStatePending = ref(false);
  const isLoggedInChecked = ref(false);
  const isLogoutPending = ref(false);
  const redirectUrl = ref("/");

  async function login() {
    const { getCartStores } = useBaseSites();
    const cartStores = getCartStores();

    isGigyaLoggedIn.value = true;
    isOauthLoggedIn.value = true;

    // trigger cart merge for all login events
    // call mergeCarts for all base sites
    for (const store of Object.values(cartStores)) {
      try {
        await store().mergeCarts();
        await store().loadCart();
      } catch (e) {
        // TODO: No specific handling of this error
      }
    }
  }

  // Only OAuth state is meaningful for login state
  const isLoggedIn = computed(() => isOauthLoggedIn.value);

  function setIsGigyaLoggedIn(val: boolean) {
    isGigyaLoggedIn.value = val;
  }

  function setIsOauthLoggedIn(val: boolean) {
    isOauthLoggedIn.value = val;
  }

  async function checkLogin() {
    const cookie = useCookie("vsf-user");

    if (cookie.value === "current") {
      // User has OAuth Cookie - should be logged in
      Log.debug(LogArea.auth, "User has OAuth Cookie - should be logged in");
      try {
        isLoggedInStatePending.value = true;
        // Dummy call to check if user is authorized
        Log.debug(LogArea.auth, "Dummy call to check if user is authorized");
        await sdk.commerce.getUser({}, baseSiteConfig(SparBaseStoreTypes.national));
        Log.debug(LogArea.auth, "Dummy call successful - user is logged in");
        isOauthLoggedIn.value = true;
        isLoggedInStatePending.value = false;
        isLoggedInChecked.value = true;
      } catch (error) {
        // OAuth Session invalid, try re-login with Gigya
        Log.debug(LogArea.auth, "OAuth Session invalid, try re-login with Gigya");
        executeWhenIsSessionVerified(reLogin);
      }
    } else {
      // User has no OAuth Cookie - might be logged in with Gigya
      Log.debug(LogArea.auth, "User has no OAuth Cookie - might be logged in with Gigya");
      executeWhenIsSessionVerified(() => reLogin(false));
    }
  }

  /**
   * Try to log in with a Gigya session
   * isGigyaLoggedIn must be validated before!
   * @param [hasOauthCookie=true] User has an (expired) OAuth Cookie
   */
  async function reLogin(hasOauthCookie = true) {
    // Check if Gigya session is valid
    Log.debug(LogArea.auth, "Check if Gigya session is valid");

    isOauthLoggedIn.value = undefined;
    isLoggedInStatePending.value = true;

    if (!isGigyaLoggedIn.value && hasOauthCookie) {
      // Gigya session expired - log user out and show notification
      Log.debug(LogArea.auth, "Gigya session expired - log user out and show notification");
      pushNotification(
        $t("auth.notification.session_expired"),
        NotificationType.Error,
        undefined,
        "session-expired",
      );
      logout(true);
    } else if (isGigyaLoggedIn.value) {
      // Gigya session is valid - try to log in again
      Log.debug(LogArea.auth, "Gigya session is valid - try to log in again");
      try {
        // Log in by asking Gigya for a new id_token
        Log.debug(LogArea.auth, "Log in by asking Gigya for a new id_token");
        await sdk.commerce.signIn();
        Log.debug(LogArea.auth, "Login successful");
        isOauthLoggedIn.value = true;
      } catch (_) {
        // Log in failed
        Log.debug(LogArea.auth, "Log in failed");
        pushNotification(
          $t("auth.notification.session_expired"),
          NotificationType.Error,
          undefined,
          "session-expired",
        );
        logout(true);
      }
    }

    isOauthLoggedIn.value = isOauthLoggedIn.value || false;
    Log.debug(LogArea.auth, "Setting logged in state to", isOauthLoggedIn.value);

    isLoggedInStatePending.value = false;
    isLoggedInChecked.value = true;
    return isOauthLoggedIn.value;
  }

  /**
   * Signs the user out from the platform and from commerce.
   */
  async function logoutCallback(isForcedLogout = false) {
    const { $reset: $resetCheckout } = useCheckoutStore();
    const { getCartStores } = useBaseSites();
    const cartStores = getCartStores();

    const { pushNotification } = useNotification();
    const { $reset: $resetUser } = useUserStore();

    // Call logout on auth store
    isGigyaLoggedIn.value = false;
    isOauthLoggedIn.value = false;

    // Sign out from commerce
    Log.debug(LogArea.auth, "Sign out from commerce");
    try {
      await sdk.commerce.signUserOut(baseSiteConfig(SparBaseStoreTypes.national));
    } catch (error) {
      Log.error(LogArea.auth, error);
    } finally {
      isLogoutPending.value = false;
    }

    // Reset stores dedicated to a user
    for (const store of Object.values(cartStores)) {
      store().$reset();
    }
    $resetCheckout();
    $resetUser();

    if (!isForcedLogout) {
      // User triggered logout
      pushNotification(
        $t("auth.notification.logged_out"),
        NotificationType.Success,
        NotificationTimeout.Medium,
        "logout",
      );
      router.push("/");
    }
  }

  /**
   * Log the user out from Gigya and SAP Commerce; clear all stores
   * @param isForcedLogout true = forced internally (eg. token expired); false = user triggerd logout
   */
  function logout(isForcedLogout = false) {
    if (isLogoutPending.value === true) return;

    isLogoutPending.value = true;
    // Gigya is not ready yet if the logout call comes from the checkAuth page
    executeWhenIsReady(() =>
      window.gigya.accounts.logout({
        callback: () => logoutCallback(isForcedLogout),
      }),
    );
  }

  function setRedirectUrl(url: string) {
    Log.debug(LogArea.auth, "Setting redirect url", url);
    // e.g. /my-account  /my-account/  /my-account/lorem/ipsum/
    if (/^(\/[a-z\d-]+)+\/?$/g.test(url)) {
      redirectUrl.value = url;
    } else {
      redirectUrl.value = "/";
    }
  }

  /**
   * Get the user id, "current" for currently authenticated user, "anonymous" for anonymous user
   *
   * @return {string}
   */
  function getUserId(): string {
    return isLoggedIn.value ? "current" : "anonymous";
  }

  return {
    checkLogin,
    isGigyaLoggedIn,
    isLoggedIn,
    isLoggedInChecked,
    isLoggedInStatePending,
    isLogoutPending,
    isOauthLoggedIn,
    login,
    logout,
    redirectUrl,
    reLogin,
    setIsGigyaLoggedIn,
    setIsOauthLoggedIn,
    setRedirectUrl,
    getUserId,
  };
});
