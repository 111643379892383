import type { InputInterface } from "~/composables/inputs/useInputValues.types";
import { getUniqueId } from "~/utils/ui";
import {
  type FieldCollection,
  FieldContentType,
  type InputFieldsType,
  type SparFlexibleFormProps,
} from "./SparFlexibleForm.types";

export function useFlexibleForm() {
  const propsRaw: Ref<SparFlexibleFormProps | undefined> = ref(undefined);
  const sections: Ref<FieldCollection[]> = ref([]);
  const children = ref([]) as Ref<InputInterface[]>;
  const hasError = ref(false);
  const isSent = ref(false);
  const hasSendError = ref(false);
  const turnstileToken = ref("");
  const { $form } = useNuxtApp();

  const initForm = (props: SparFlexibleFormProps) => {
    propsRaw.value = props;
    generateSections();
  };

  const generateSections = () => {
    if (!propsRaw.value) return;

    propsRaw.value.fields.forEach((field) => {
      // Fields MUST live inside a field collection for consistency
      // -> fields from Contentstack without a section are nested in a dummy section
      if (field.contentTypeUid === FieldContentType.flexible_form_field_collection) {
        // Cast to FieldCollection because of previous type check
        sections.value.push(field as unknown as FieldCollection);
      } else {
        const collection = {
          fields: [
            {
              field: [field],
              width: null, // fallback to default
              metadata: {
                uid: getUniqueId("f"),
              },
            },
          ],
        } as FieldCollection;
        sections.value.push(collection);
      }
    });
  };

  const getChildInterface = (childInterface: InputInterface) => {
    children.value.push(childInterface);
  };

  // Check if form is valid and call actual submit function OR show error
  const onSubmit = () => {
    const res = children.value.map((child) => child.validate());
    const isValid = res.every((isValid) => isValid);

    if (isValid) {
      hasError.value = false;
      sendData();
    } else {
      hasError.value = true;
    }
  };

  // Get user data for all original fields from Contentstack
  const extractData = () => {
    const fields: InputFieldsType[] = [];

    // Extract fields from all sections into one array
    sections.value.forEach((section) => {
      const sectionFields = section.fields.flatMap((field) => field.field);
      sectionFields.forEach((field) => {
        fields.push(field);
      });
    });

    // Get user values for all fields and add to original fields object for easier post-processing
    // Checkboxes and Radio Buttons create multiple form fields which are merged here
    const res = fields.map((field) => {
      const uiFields = children.value.filter((child) => {
        const props = child.getProps();
        if ("name" in props) return props.name === field.uid;
        return false;
      });
      const values = uiFields.map((field) => field.getValue()).filter((val) => val);
      const uniqueValues = [...new Set(values)];

      return {
        ...field,
        userValue: uniqueValues,
      };
    });

    return res;
  };

  const formatData = () => {
    const res = extractData();
    const data = res.map((field) => {
      return {
        label: field.label,
        value: field.userValue.join(", "),
      };
    });
    return data;
  };

  const sendData = async () => {
    // TODO: Submit form to backend service with uploaded file
    // At the moment, the data is logged to the console for acceptance testing
    const data = formatData();

    try {
      const res = await $form.submitForm({
        token: turnstileToken.value,
        data: data,
      });

      // TODO: Throw more specific error as soon as backend service is ready
      if (res.error.value) throw new Error();

      // TODO: Submit form to backend service with uploaded file
      Log.log(LogArea.contact, "submit", data);
      isSent.value = true;
    } catch {
      // TODO: Show specific error message as soon as backend service is ready
      hasSendError.value = true;
    }
  };

  return {
    children,
    getChildInterface,
    hasError,
    hasSendError,
    initForm,
    isSent,
    onSubmit,
    sections,
    turnstileToken,
  };
}
