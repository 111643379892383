<template>
  <div class="content-page container">
    <spar-heading
      level="2"
      :title="$t('auth.check_auth.title')"
      :description="$t('auth.check_auth.subtitle')"
    />

    <div class="check-in">
      <spar-loader />
    </div>
  </div>
</template>

<script setup lang="ts">
import { SparHeading, SparLoader } from "~/components/shared";
import useAuth from "~/composables/auth/useAuth";
import { useCheckoutStore } from "~/stores/checkout.store";
import { CheckoutStep } from "~/types/checkout.types";

definePageMeta({
  layout: "reduced",
});

const { cartContext } = storeToRefs(useCheckoutStore());
const { checkStep, loadCart, setContext } = useCheckoutStore();
const { executeWhenIsLoggedInChecked } = useAuth();

const router = useRouter();

const {
  query: { r: step, baseSite },
} = useRoute();
const { getPath } = useRoutes();

const handleCheckIn = async () => {
  // return to cart if no cartContext
  if (!cartContext.value) router.push(getPath("cart"));

  let checkoutStep = null;
  if (step) {
    checkoutStep = checkStep(step as string, baseSite as string);
  }

  // check on this point if user is allowed to go to the given page
  // we also have to check if the cart is already in a not anonymous state
  // that there is present a cart which is valid
  // otherwhise link to the cart page
  if (
    cartContext.value &&
    !cartContext.value.isAnonymousCart() &&
    checkoutStep &&
    step &&
    cartContext.value.cart &&
    isValid.value
  ) {
    return navigateTo({ path: checkoutStep });
  } else {
    router.push(getPath("cart"));
  }
};

const isValid = computed(() => {
  if (!cartContext.value) return false;
  // Checkout step "Summary" checks for issues in all steps
  const { errors, warnings } = cartContext.value.getCartStepErrorsAndWarnings(CheckoutStep.Summary);
  return Object.keys(errors).length === 0 && Object.keys(warnings).length === 0;
});

onMounted(() => {
  executeWhenIsLoggedInChecked(async () => {
    setContext(baseSite as string);
    await loadCart();
    handleCheckIn();
  });
});
</script>

<style lang="scss">
.check-in {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 10rem;
  width: 100%;
}
</style>
