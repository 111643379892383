<template>
  <div class="change-password">
    <spar-heading level="2" :title="$t('my_account.change_password.title')" />

    <div role="alert">
      <p v-if="successMessage" data-tosca="change-password-success-msg">
        {{ successMessage }}
      </p>
    </div>

    <form
      v-if="!successMessage"
      class="spar-form change-password-form"
      novalidate
      @submit.prevent="onSubmit"
    >
      <spar-input
        v-model="oldPassword"
        :type="SparInputType.password"
        :label="$t('my_account.change_password.fields.old_password')"
        required
        tosca-prefix="myaccount-old-password"
        @interface="getChildInterface"
      />

      <spar-input
        v-model="newPassword"
        :type="SparInputType.password"
        :label="$t('my_account.change_password.fields.new_password')"
        required
        show-strength
        tosca-prefix="myaccount-new-password"
        @interface="getChildInterface"
      />

      <div role="alert">
        <p v-if="error" class="spar-form__error" data-tosca="myaccount-change-password-error-msg">
          {{ errorMessage }}
        </p>
      </div>

      <p v-if="!successMessage">
        <spar-button
          type="submit"
          :disabled="loading || !isGigyaReady"
          class="btn--primary"
          tosca-prefix="myaccount-change-password"
          >{{ $t("my_account.change_password.submit") }}</spar-button
        >
      </p>
    </form>
  </div>
</template>

<script setup lang="ts">
// https://help.sap.com/docs/SAP_CUSTOMER_DATA_CLOUD/8b8d6fffe113457094a17701f63e3d6a/eb93d538b9ae45bfadd9a8aaa8806753.html?locale=en-US
import { GigyaError } from "~/components/feature/SparLogin/SparLogin.types";
import { SparButton, SparInput, SparInputType, SparHeading } from "~/components/shared";
import type { GigyaResponse } from "~/composables/auth/gigya.types";
import useGigya from "~/composables/auth/useGigya";
import useI18n from "~/composables/i18n/useI18n";
import type { InputInterface } from "~/composables/inputs/useInputValues.types";

const { isGigyaReady } = useGigya();
const { $t } = useI18n();

const children: Ref<InputInterface[]> = ref([]);

const oldPassword = ref("");
const newPassword = ref("");

const error = ref(0);
const errorDetailsCode = ref(0);
const successMessage = ref("");
const loading = ref(false);

// Mark fields in red color
const setFieldsValidation = (valid = true) => {
  children.value.forEach((child) => child.setForceInvalid(!valid));
};

const onSubmit = async () => {
  cleanupForm();

  const res = children.value.map((c) => c.validate());
  const isValid = res.every((v) => v);
  if (!isValid) return;

  loading.value = true;

  window.gigya.accounts.setAccountInfo({
    password: oldPassword.value,
    newPassword: newPassword.value,
    callback: changePasswordCallback,
  });
};

const getChildInterface = (childInterface: InputInterface) => {
  children.value.push(childInterface);
};

// Cleanup states before submit
const cleanupForm = () => {
  successMessage.value = "";
  error.value = 0;
  errorDetailsCode.value = 0;
  setFieldsValidation(true);
};

const changePasswordCallback = (eventObj: GigyaResponse) => {
  loading.value = false;
  if (eventObj.errorCode !== 0) {
    error.value = eventObj.errorCode;
    if (eventObj.errorDetailsCode) {
      errorDetailsCode.value = eventObj.errorDetailsCode;
    }
  } else {
    successMessage.value = $t("my_account.change_password.success");
  }
};

const errorMessage = computed(() => {
  switch (error.value) {
    case GigyaError.Invalid:
      return $t("my_account.change_password.error.invalid");
    case GigyaError.ValidationError:
      if (errorDetailsCode.value === 100001) {
        return $t("my_account.change_password.error.identical");
      }
      return $t("my_account.change_password.error.validation");
  }
  return $t("my_account.change_password.error.general");
});
</script>

<style lang="scss">
@use "./SparChangePassword.scss";
</style>
