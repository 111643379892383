import type { GetContentParams } from "@vsf-enterprise/contentstack-sdk";

export const ContentstackPageParams = {
  slug: "slug",
  leafletRegion: "leaflet_regions.region",
  locationId: "location_id",
} as const;
export type ContentstackPageParams =
  (typeof ContentstackPageParams)[keyof typeof ContentstackPageParams];

export type ContentstackPlugin = {
  getEntries: <T>(params: GetContentParams) => Promise<T[]>;
};
