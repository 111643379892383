<template>
  <div class="login__registration-list">
    <spar-heading level="2" class="heading--left" :title="$t('auth.login_registration.title')" />
    <div class="login__registration-list-title">{{ $t("auth.login_registration.list") }}</div>
    <ul class="login__registration-list">
      <li>{{ $t("auth.login_registration.list_item1") }}</li>
      <li>{{ $t("auth.login_registration.list_item2") }}</li>
      <li>{{ $t("auth.login_registration.list_item3") }}</li>
      <li>{{ $t("auth.login_registration.list_item4") }}</li>
    </ul>
  </div>

  <div class="login__footer">
    <spar-button
      :label="$t('checkout.login.account.register')"
      tosca-prefix="login-create-account"
      @click="register"
    />
  </div>
</template>

<script setup lang="ts">
import { SparHeading, SparButton } from "~/components/shared";

const router = useRouter();
const { getPath } = useRoutes();

const register = () => {
  router.push(getPath("registration"));
};
</script>
