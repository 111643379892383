<template>
  <div class="checkout-consents__container">
    <template v-for="(consent, index) in consentTemplates" :key="index">
      <spar-checkbox
        v-if="consent.mandatory"
        v-model="consents[consent.id]"
        :required="consent.mandatory"
        :val="consent.id"
        :label="''"
        :tosca-prefix="`checkout-consents-${consent.id}`"
        @update:model-value="checkConsents"
      >
        <template #label-link>
          <span v-html="getLabel(consent.id)"></span>
        </template>
      </spar-checkbox>
    </template>
  </div>
</template>

<script lang="ts" setup>
import { SparCheckbox } from "~/components/shared";
import useI18n from "~/composables/i18n/useI18n";
import { useCheckoutStore } from "~/stores/checkout.store";

const { consents, consentTemplates } = storeToRefs(useCheckoutStore());
const { getCartConsentTemplates, setConsentsGivenState } = useCheckoutStore();
const { $t } = useI18n();

await getCartConsentTemplates();

const checkConsents = () => {
  // check if all mandatory consents are accepted
  const mandatoryConsentsGiven = consentTemplates.value?.every(
    (consentTemplate) =>
      !consentTemplate.mandatory || consents.value[consentTemplate.id] === consentTemplate.id,
  );

  setConsentsGivenState(mandatoryConsentsGiven || false);
};

const getLabel = (consentType: string) => {
  let placeholders = {};
  switch (consentType) {
    case "consent.terms.and.revocation":
      placeholders = {
        agb: `<a href=${$t(consentType + ".agb.link")}> ${$t(consentType + ".agb.label")}</a>`,
        revocation: `<a href=${$t(consentType + ".revocation.link")}> ${$t(
          consentType + ".revocation.label",
        )}</a>`,
        revocationform: `<a href=${$t(consentType + ".revocationform.link")}> ${$t(
          consentType + ".revocationform.label",
        )}</a>`,
      };
      break;
    case "consent.data.protection.policy":
      placeholders = {
        dataprotection: `<a href=${$t(consentType + ".dataprotection.link")}> ${$t(
          consentType + ".dataprotection.label",
        )}</a>`,
      };
      break;
    default:
      return $t(consentType);
  }
  return $t(consentType, placeholders);
};

onMounted(() => {
  checkConsents();
});
</script>
