import type { ProductMainInfo } from "~/utils/mdsa/integration/mdsa.types";

export type SparQuantityButtonBoundaries = {
  max: number;
  min: number;
};

export interface SparQuantityButtonProps {
  productInfo: Pick<ProductMainInfo, "name2" | "productId" | "salesUnit">;
  cartPage?: boolean;
  boundaries?: SparQuantityButtonBoundaries;
  toscaPrefix?: string;
  layout?: string;
  sparProductType?: string;
  availabilityType?: string;
  cartEntryUpdate?: boolean;
  entryNumber?: number;
  additionalServices?: string[];
}

export const SparQuantityButtonLayout = {
  small: "small",
  big: "big",
} as const;
export type SparProductType =
  (typeof SparQuantityButtonLayout)[keyof typeof SparQuantityButtonLayout];

export const SparQuantityButtonActionType = {
  update: "update",
  remove: "remove",
  add: "add",
  maximum: "maximum",
  minimum: "minimum",
} as const;
export type SparQuantityButtonActionType =
  (typeof SparQuantityButtonActionType)[keyof typeof SparQuantityButtonActionType];
