import _ from "lodash";
import { SparInputType } from "~/components/shared";
import useI18n from "~/composables/i18n/useI18n";
import { checkPasswordStrength } from "~/composables/inputs/passwordStrength";
import { getUniqueId } from "~/utils/ui";
import type { InputInterface, InputProps } from "./useInputValues.types";

export function useInputValue(
  props: InputProps,
  emit: (event: "update:modelValue" | "interface" | "onUnmount", ...args: unknown[]) => void,
) {
  const internalValue = ref(props.modelValue);
  const initialValue = props.modelValue;
  const isValid = ref(true); // Internal Validation
  const isForceInvalid = ref(false); // External Validation
  const errorMessage = ref("");
  const uniqueId = getUniqueId("input");

  const { isString } = _;
  const { $t } = useI18n();

  const sanitize = () => {
    if (!isString(internalValue.value)) return;
    internalValue.value = internalValue.value.trim();
  };

  const getValue = () => {
    return internalValue.value;
  };

  const getProps = () => {
    return props;
  };

  const reset = () => {
    internalValue.value = initialValue;
    isValid.value = true;
    emit("update:modelValue", internalValue.value);
  };

  const setForceInvalid = (invalid = true) => {
    isForceInvalid.value = invalid;
  };

  const isEmpty = () => {
    // Checkbox
    if (Array.isArray(internalValue.value)) {
      if (internalValue.value.filter((val) => val).length === 0) {
        return true;
      }
      return false;
    }
    // Other input types
    if (
      internalValue.value === "" ||
      internalValue.value === undefined ||
      internalValue.value === null
    )
      return true;
    return false;
  };

  const validate = () => {
    // Validate (empty) required field
    if (isEmpty()) {
      if ("required" in props && props.required) {
        isValid.value = false;
        errorMessage.value = $t("form.validation.field_required");
      } else {
        isValid.value = true;
      }

      return isValid.value;
    }

    // Custom validators
    if ("validators" in props && props.validators?.length) {
      const validationRes = props.validators.map((v) => {
        return v(internalValue.value as string);
      });
      const isValidated = validationRes.every((r) => r === true);
      if (!isValidated) {
        isValid.value = false;
        errorMessage.value = validationRes.find((r) => typeof r === "string") as string;
        return false;
      }
    }

    // Validate number
    if ("type" in props && props.type === SparInputType.number) {
      const num = isString(internalValue.value)
        ? parseInt(internalValue.value)
        : (internalValue.value as number);

      if (!(num >= (props.min as number) && num <= (props.max as number))) {
        isValid.value = false;
        errorMessage.value = $t("form.validation.out_of_range");
        return false;
      }
    }

    // Validate date
    // Input value converted from ISO String to Integer for min/max comparison
    // (2023-07-05 -> 20230705)
    if ("type" in props && props.type === SparInputType.date) {
      const dateStr = internalValue.value?.toString().replaceAll("-", "") || "";
      const dateInt = Number.parseInt(dateStr);

      if (!(dateInt >= (props.minDate as number) && dateInt <= (props.maxDate as number))) {
        isValid.value = false;
        errorMessage.value = $t("form.validation.date_out_of_range");
        return false;
      }
    }

    // Validate email address
    if ("type" in props && props.type === SparInputType.email && isString(internalValue.value)) {
      const pattern =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!pattern.test(internalValue.value)) {
        isValid.value = false;
        errorMessage.value = $t("form.validation.email_invalid");
        return false;
      }
    }

    // Validate min length
    if ("minlength" in props && (props.minlength as number) > 0 && isString(internalValue.value)) {
      if (internalValue.value.length < (props.minlength as number)) {
        isValid.value = false;
        errorMessage.value = $t("form.validation.input_too_short");
        return false;
      }
    }

    // Validate max length
    if ("maxlength" in props && (props.maxlength as number) > 0 && isString(internalValue.value)) {
      if (internalValue.value.length > (props.maxlength as number)) {
        isValid.value = false;
        errorMessage.value = $t("form.validation.input_too_long");
        return false;
      }
    }

    isValid.value = true;
    errorMessage.value = "";
    return true;
  };

  const passwordStrength = computed(() => {
    if (!("type" in props && props.type === SparInputType.password)) return 0;
    return checkPasswordStrength(internalValue.value as string);
  });

  const isValidDateMinMax = (num: number) => {
    const str = num.toString();
    return str.length === 8;
  };

  const onBlur = (event: Event | FocusEvent | KeyboardEvent) => {
    sanitize();
    if (isString(internalValue.value)) {
      // Force set value to internalValue; e.g. to validate num input
      (event.target as HTMLInputElement).value = internalValue.value;
    }
    validate();
    emitValue();
  };

  const emitValue = () => {
    emit("update:modelValue", internalValue.value);
  };

  const emitInterface = () => {
    emit("interface", {
      uniqueId,
      validate: () => validate(),
      reset: () => reset(),
      setForceInvalid,
      getValue: () => getValue(),
      getProps: () => getProps(),
    } as InputInterface);
  };

  onMounted(() => {
    emitInterface();
  });

  onUnmounted(() => {
    emit("onUnmount", uniqueId);
  });

  watch(
    () => props.modelValue,
    (value) => {
      // Update internal value if prop changed from parent
      internalValue.value = value;
    },
  );

  return {
    internalValue,
    isValid,
    isForceInvalid,
    errorMessage,
    uniqueId,
    sanitize,
    validate,
    onBlur,
    emitValue,
    passwordStrength,
    isValidDateMinMax,
  };
}

// ISO String of today (2023-07-03) converted to Int (20230703)
export function getTodayForMinMax() {
  const today = new Date().toLocaleDateString("en-CA");
  return Number.parseInt(today.replaceAll("-", ""));
}
