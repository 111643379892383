<template>
  <spar-icon-sprite v-if="icon" class="link__icon" :symbol="icon" />
  <div
    v-show="selectedTab === tabId"
    class="tab__content"
    :class="additionalClass"
    role="tabpanel"
    tabindex="0"
    :aria-labelledby="tabId"
    :hidden="!(selectedTab === tabId)"
  >
    <slot />
  </div>
</template>

<script setup lang="ts">
import type { SparTabProps } from "./SparTab.types";

defineProps({
  tabId: {
    type: String,
    default: "",
  },
  title: {
    type: String,
    default: "",
  },
  isBuyable: {
    type: Boolean as PropType<boolean | undefined>,
    default: false,
  },
  additionalClass: {
    type: Object as PropType<Record<string, boolean>>,
    default: () => {
      return {};
    },
  },
  icon: {
    type: String,
    default: undefined,
  },
}) as SparTabProps;

const selectedTab = inject("selectedTab");
</script>

<style lang="scss">
@use "./SparTab.scss";
</style>
