<template>
  <div v-if="!loadingCart" class="checkout-login">
    <spar-heading level="1" headline-style="1" :title="$t('checkout.login.headline')" />
    <div class="checkout-login__section">
      <spar-login
        :redirect-url="
          cartContext?.bs
            ? replaceBaseSiteInRoute(getPath('checkoutDelivery'), cartContext?.bs)
            : undefined
        "
      >
        <template #subheading>
          <spar-heading
            class="checkout-login__registered-heading"
            level="3"
            :title="$t('checkout.login.account.registered')"
          />
        </template>
        <template #additionalContent>
          <form class="spar-form checkout-login__guest-form" novalidate @submit.prevent="onSubmit">
            <div class="checkout-login__guest-container">
              <spar-heading
                class="checkout-login__guest-heading"
                level="3"
                :title="$t('checkout.login.account.guest.title')"
              />
              <div class="checkout-login__guest-subtitle">
                {{ $t("checkout.login.account.guest.subtitle") }}
              </div>

              <spar-input
                v-model="email"
                :type="SparInputType.email"
                :label="$t('auth.login_form.fields.email')"
                class="checkout-login__guest-email"
                required
                tosca-prefix="checkout-login-email"
                @interface="getChildInterface"
              />

              <div role="alert">
                <p v-if="errorMessage" class="spar-form__error">
                  {{ errorMessage }}
                </p>
              </div>
            </div>

            <div class="checkout-login__guest-submit">
              <spar-button
                type="submit"
                :disabled="cartContext?.anonymousToGuestCartLoading"
                class="btn--primary"
                tosca-prefix="checkout-login-guest"
              >
                {{ $t("checkout.login.account.guest.submit") }}</spar-button
              >
            </div>
          </form>
        </template>
      </spar-login>
    </div>
  </div>
  <div v-else class="checkout-login__loader"><spar-loader /></div>
</template>

<script lang="ts" setup>
import SparLogin from "~/components/feature/SparLogin/SparLogin.vue";
import { SparButton, SparHeading, SparInput, SparInputType, SparLoader } from "~/components/shared";
import { useBaseSites } from "~/composables/base-sites/baseSites";
import useI18n from "~/composables/i18n/useI18n";
import type { InputInterface } from "~/composables/inputs/useInputValues.types";
import { useCheckoutStore } from "~/stores/checkout.store";

const email = ref("");
const children: Ref<InputInterface[]> = ref([]);
const errorMessage = ref("");
const loadingCart = ref(true);

const { cartContext } = storeToRefs(useCheckoutStore());
const { loadCart, setContext } = useCheckoutStore();
const { $t } = useI18n();
const { replaceBaseSiteInRoute } = useBaseSites();
const { getPath } = useRoutes();
const router = useRouter();
const {
  params: { baseSite },
} = useRoute();

const cleanupForm = () => {
  errorMessage.value = "";
};

const onSubmit = async () => {
  cleanupForm();

  const res = children.value.map((c) => c.validate());
  const isValid = res.every((v) => v);
  if (!isValid) return;

  if (!cartContext.value?.cart) {
    errorMessage.value = $t("checkout.login.account.guest.error.general");
    return;
  }

  try {
    await cartContext.value?.anonymousToGuestCart(email.value);
    proceedToCheckout();
  } catch (e) {
    errorMessage.value = $t("checkout.login.account.guest.error.general");
  }
};

const getChildInterface = (childInterface: InputInterface) => {
  children.value.push(childInterface);
};

const proceedToCheckout = () => {
  if (cartContext.value) {
    router.push({ name: "checkoutDelivery", params: { baseSite: cartContext.value?.bs } });
  }
};

onMounted(async () => {
  if (cartContext.value) {
    await cartContext.value.refreshCart();
  } else {
    // fetch cartContext
    setContext(baseSite as string);
    await loadCart();
  }

  if (!cartContext.value?.isAnonymousCart()) {
    proceedToCheckout();
  } else {
    loadingCart.value = false;
  }
});
</script>

<style lang="scss">
@use "./SparCheckoutLogin.scss";
</style>
