<template>
  <div class="checkout-steps">
    <nav class="checkout-steps__wrapper">
      <ol :class="`checkout-steps__steps-list checkout-steps__steps-list--step${activeStepIndex}`">
        <li v-for="(step, index) in steps" :key="index" class="checkout-steps__step">
          <spar-link
            v-if="isVisited(index)"
            :to="step.link"
            :data-tosca="`checkout-steps-${index}`"
            :aria-current="isCurrent(index) ? 'step' : null"
            :class="isCurrent(index) && 'checkout-steps__step-link--current'"
            class="checkout-steps__step-link checkout-steps__step-link--visited"
          >
            <spar-icon-sprite
              v-if="isVisited(index) && !isCurrent(index)"
              symbol="tick"
              aria-hidden="true"
            />
            <span class="checkout-steps__step-label">{{ $t(step.label) }}</span>
          </spar-link>
          <div
            v-else
            class="checkout-steps__step-link checkout-steps__step-link--disabled"
            aria-disabled="true"
          >
            <span class="checkout-steps__step-label">{{ $t(step.label) }}</span>
          </div>
        </li>
      </ol>
    </nav>
  </div>
</template>

<script lang="ts" setup>
import { SparLink, SparIconSprite } from "~/components/shared";
import { useBaseSites } from "~/composables/base-sites/baseSites";
import { useCheckoutStore } from "~/stores/checkout.store";

const props = defineProps({
  activeSteps: {
    type: Array as PropType<Array<string>>,
    required: true,
  },
});

const { cartContext } = storeToRefs(useCheckoutStore());
const { replaceBaseSiteInRoute } = useBaseSites();
const { getPath } = useRoutes();

const steps = cartContext.value?.bs
  ? [
      {
        link: replaceBaseSiteInRoute(getPath("cart"), cartContext.value?.bs),
        label: "checkout.step0.label",
      },
      {
        link: replaceBaseSiteInRoute(getPath("checkoutDelivery"), cartContext.value?.bs),
        label: "checkout.step1.label",
      },
      {
        link: replaceBaseSiteInRoute(getPath("checkoutPayment"), cartContext.value?.bs),
        label: "checkout.step2.label",
      },
      {
        link: replaceBaseSiteInRoute(getPath("checkoutSummary"), cartContext.value?.bs),
        label: "checkout.step3.label",
      },
    ]
  : [];

/**
 * Checks if the step has already been visited.
 * @param {number} index
 * @return {boolean}
 */
const isVisited = (index: number): boolean => {
  return props.activeSteps.indexOf(index.toString()) !== -1;
};

/**
 * Checks if the step is currently where the user is at.
 * @param {number} index
 * @return {boolean}
 */
const isCurrent = (index: number): boolean => {
  return activeStepIndex.value === index;
};

const activeStepIndex = computed(() => props.activeSteps.length - 1);
</script>

<style lang="scss">
@use "./SparCheckoutSteps.scss";
</style>
