<template>
  <section v-if="product" class="pdp-product">
    <spar-lightbox
      :items="onlyVideos"
      :lightbox-open-index="lightboxOpenIndex"
      @close-light-box="lightboxOpenIndex = null"
    ></spar-lightbox>
    <div class="pdp-product__main">
      <div v-if="assets.length" class="pdp-product__media">
        <div
          v-if="product.badgeSlots && product.badgeSlots[0].badgeSlot2"
          class="pdp-product__badges-media"
        >
          <div
            v-for="(badge, index) in product.badgeSlots[0].badgeSlot2"
            :key="index"
            class="pdp-product__badge-outer"
          >
            <div class="pdp-product__badge-img-wrapper">
              <spar-adaptive-image
                v-if="badge"
                class="pdp-product__badge-img"
                object-fit="contain"
                :src="badge.badgeDamUrl"
                :alt="badge.badgeAltText"
              ></spar-adaptive-image>
            </div>
          </div>
        </div>

        <div class="pdp-product__media-inner">
          <spar-slider
            :items-count="assets.length"
            :dots-as-images="true"
            :assets="assets"
            :show-count="true"
            @slide-changing="setSlideChanging"
          >
            <spar-slider-item
              v-for="(asset, index) in assets"
              :key="index"
              :role="asset.type === ProductPictureType.video && !isSlideChanging ? 'button' : ''"
              :aria-label="$t('lightbox.start_video')"
              @click="openLightBox(asset)"
              @keypress.enter="openLightBox(asset)"
            >
              <div
                class="pdp-product__media-img-wrapper"
                :class="{
                  'pdp-product__media--clickable': asset.type === ProductPictureType.video,
                }"
              >
                <spar-adaptive-image
                  :tosca-prefix="`pdp-slider-items-${index}`"
                  object-fit="contain"
                  class="pdp-product__media-img"
                  :src="asset.thumbnail"
                  :alt="asset.alt"
                />

                <div
                  v-if="asset.type === ProductPictureType.video"
                  class="pdp-product__media-play btn btn--play"
                >
                  <spar-icon-sprite symbol="play"></spar-icon-sprite>
                </div>
              </div>
            </spar-slider-item>
          </spar-slider>
        </div>
      </div>
      <div class="pdp-product__info-wrapper">
        <!--         <spar-button
          data-tosca="pdp-favorite"
          class="pdp-product__wishlist"
          :variant="ButtonVariant.custom"
          :title="$t('wishlist.add_item')"
          :aria-label="$t('wishlist.add_item')"
          @click="toggleWishlist"
        >
          <spar-icon-sprite :symbol="wishlistSymbol" />
        </spar-button> -->
        <div class="pdp-product__info">
          <span v-if="product.mainInfo.name1" class="pdp-product__brand" data-tosca="pdp-brand">
            {{ product.mainInfo.name1 }}
          </span>

          <h1 v-if="product.mainInfo.name2" class="pdp-product__title" data-tosca="pdp-title">
            {{ product.mainInfo.name2 }}
          </h1>
          <span
            v-if="product.mainInfo.name3 || product.mainInfo.name4"
            class="pdp-product__aditional-info"
            data-tosca="pdp-additional-info"
          >
            {{ product.mainInfo.name3 }}&nbsp;{{ product.mainInfo.name4 }}
          </span>
        </div>

        <div
          v-if="product.badgeSlots && product.badgeSlots[0].badgeSlot1"
          class="pdp-product__badges"
        >
          <div
            v-for="(badge, index) in product.badgeSlots[0].badgeSlot1"
            :key="index"
            class="pdp-product__badge-outer"
          >
            <div class="pdp-product__badge-img-wrapper">
              <spar-adaptive-image
                class="pdp-product__badge-img"
                object-fit="contain"
                :src="badge.badgeDamUrl"
                :alt="badge.badgeAltText"
              ></spar-adaptive-image>
            </div>
          </div>
        </div>

        <spar-link
          v-if="!noInfoLink"
          class="pdp-inpaket-inpronto-link"
          target="_blank"
          icon="info"
          icon-position="left"
          :variant="LinkVariant.linkIcon"
          tosca-prefix="pdp-delivery-info-link"
          @click="showDeliveryOverlay"
        >
          {{
            product.availabilityTypes?.length === 2
              ? $t("pdp.inpaket_inpronto.link")
              : product.availabilityTypes?.[0] === AvailabilityTypes.INPAKET
                ? $t("pdp.inpaket.link")
                : product.availabilityTypes?.[0] === AvailabilityTypes.INPRONTO
                  ? $t("pdp.inpronto.link")
                  : ""
          }}</spar-link
        >

        <spar-overlay
          class="pdp-overlay-inpronto-inpaket"
          :is-active="isActiveOverlay"
          role="alertdialog"
          aria-describedby="pdp-delivery-info-overlay"
          tosca-prefix="pdp-delivery-info-overlay"
          :title="$t('pdp.overlay.title')"
          @close="isActiveOverlay = false"
        >
          <div class="pdp-delivery__description">
            <div class="pdp-delivery__description-row">
              <div class="pdp-delivery__icon">
                <spar-icon-sprite class="pdp-delivery__icon-sprite" symbol="in" />
                {{ $t("plp.product.tile.availability.p") }}
              </div>
              <div class="pdp-overlay__info-text">
                <p class="pdp-overlay__label">
                  {{ $t("pdp.overlay.inpaket.label") }}
                </p>
                {{ $t("pdp.overlay.inpaket.text") }}
              </div>
            </div>
            <div class="pdp-delivery__description-row">
              <div class="pdp-delivery__icon">
                <spar-icon-sprite class="pdp-delivery__icon-sprite" symbol="in" />
                {{ $t("plp.product.tile.availability.t") }}
              </div>
              <div class="pdp-overlay__info-text">
                <p class="pdp-overlay__label">
                  {{ $t("pdp.overlay.inpronto.label") }}
                </p>
                {{ $t("pdp.overlay.inpronto.text") }}
                <span class="pdp-overlay__link-t" v-html="getInProntoLink()"></span>
              </div>
            </div>
          </div>
        </spar-overlay>

        <div v-if="product" class="pdp-product__buybox">
          <spar-buy-box
            :additional-services="product.additionalServices"
            :availability-types="product.availabilityTypes"
            :availability="product.availability"
            :box-format="BuyBoxFormat.big"
            :main-info="product.mainInfo"
            :min-order-quantity-t="product.minOrderQuantityT"
            :min-order-quantity-p="product.minOrderQuantityP"
            :max-order-quantity-t="product.maxOrderQuantityT"
            :max-order-quantity-p="product.maxOrderQuantityP"
            :pictures="product.pictures"
            :prices="product.prices"
            :spar-product-type="product.sparProductType"
          />
        </div>
      </div>
    </div>
    <section class="pdp__product-bottom">
      <div
        v-if="product.productDescription?.marketingText.value"
        class="pdp__product-info"
        v-html="product.productDescription.marketingText.value"
      ></div>
      <div class="pdp__cross-selling">
        <slot v-if="$slots.crossSelling" name="crossSelling"></slot>
      </div>
    </section>
    <section class="pdp__dynamic-product-information">8 - Dynamic product info MDSA</section>
  </section>
</template>

<script lang="ts" setup>
import {
  LinkVariant,
  SparAdaptiveImage,
  SparIconSprite,
  SparSlider,
  SparSliderItem,
  SparLightbox,
  type SparLightboxItem,
  SparOverlay,
  SparLink,
} from "~/components/shared";
import { BuyBoxFormat } from "~/components/shared/SparBuyBox/SparBuyBox.types";
import SparBuyBox from "~/components/shared/SparBuyBox/SparBuyBox.vue";
import {
  ProductPictureType,
  type SparProduct,
  SparProductType,
} from "~/utils/mdsa/integration/mdsa.types";
import { usePdpProduct } from "./usePdpProduct";

const { $t } = useI18n();

const props = defineProps({
  product: {
    type: Object as PropType<SparProduct>,
    required: true,
  },
});

const lightboxOpenIndex: Ref<number | null> = ref(null);
const isSlideChanging = ref(false);
const isActiveOverlay = ref(false);

const noInfoLink = computed(
  () =>
    props.product.sparProductType === SparProductType.Voucher ||
    props.product.sparProductType === SparProductType.GWK,
);

const getInProntoLink = () => {
  const link = $t("pdp.inpronto.text.link");
  const label = $t("pdp.inpronto.text.label");
  return `<a href="${link}">${label}</a>`;
};

const AvailabilityTypes = {
  INPAKET: "P",
  INPRONTO: "T",
};

/* 
const availabilityType = props.product.availabilityTypes?.[0];
let translationKey = "";

switch (availabilityType) {
  case AvailabilityTypes.INPAKET:
    translationKey = $t("pdp.inpaket.link");
    break;
  case AvailabilityTypes.INPRONTO:
    translationKey = $t("pdp.inpronto.link");
    break;
  default:
    translationKey = "";
}
*/

function showDeliveryOverlay(): void {
  isActiveOverlay.value = true;
}

const setSlideChanging = (changing: boolean) => {
  isSlideChanging.value = changing;
};

const { setPictures, filterPictures } = usePdpProduct();
setPictures(props.product.pictures || []);

/* const wishlistSymbol = ref("heart");
const toggleWishlist = () => {
  wishlistSymbol.value = "heart-filled";
}; */

const openLightBox = (asset: SparLightboxItem) => {
  if (asset.type === ProductPictureType.video && !isSlideChanging.value) {
    lightboxOpenIndex.value = asset.id;
  }
};

const assets = filterPictures([ProductPictureType.image, ProductPictureType.video])
  .map((item, index) => {
    return {
      id: index,
      type: item.type,
      src: item.type === ProductPictureType.image ? item.servletBaseUrl : item.progressiveUrl,
      alt: item.altText,
      preview: item.preview,
      thumbnail:
        item.type === ProductPictureType.image ? item.servletBaseUrl : item.thumbnailImageLink,
    };
  })
  .filter((asset) => asset.type && asset.src && asset.thumbnail) as SparLightboxItem[];

const onlyVideos = assets.filter((item) => item.type === ProductPictureType.video);
</script>

<style lang="scss">
@use "SparPdpProduct.scss";
</style>
