<template>
  <div class="content-page">
    <template v-if="content?.[0]?.topSections">
      <render-sections :content="content[0].topSections" />
    </template>

    <div class="container">
      <spar-registration />
    </div>

    <template v-if="content?.[0]?.bottomSections">
      <render-sections :content="content[0].bottomSections" />
    </template>
  </div>
</template>

<script setup lang="ts">
import type { RegistrationPage } from "@shop-storefront/utils";
import SparRegistration from "~/components/feature/SparRegistration/SparRegistration.vue";
import RenderSections from "~/components/sections/RenderSections/RenderSections.vue";
import { ContentstackPageParams } from "~/plugins/contentstack/contentstack.types";
import ContentType from "~/utils/contentstack/constants/content-types.constants";
import { registrationPageReferences } from "~/utils/contentstack/constants/references.constants";

const { $contentstack } = useNuxtApp();
const { getSlug } = useRoutes();

const { data: content, refresh: refreshContent } = await useAsyncData(
  "registrationPage",
  async () => {
    return await $contentstack.getEntries<RegistrationPage>({
      type: ContentType.registrationPage,
      customQuery: {
        [ContentstackPageParams.slug]: getSlug("registration"),
      },
      includeReference: registrationPageReferences as string[],
    });
  },
);

onMounted(() => {
  refreshContent();
});
</script>
