<template>
  <div class="content-page container">
    <spar-heading
      level="2"
      :title="$t('auth.check_auth.title')"
      :description="$t('auth.check_auth.subtitle')"
    />

    <div class="check-auth">
      <spar-loader />
    </div>
  </div>
</template>

<script setup lang="ts">
import { SparHeading, SparLoader } from "~/components/shared";
import useNotification, {
  NotificationTimeout,
  NotificationType,
} from "~/components/shared/SparNotification/useNotification";
import useGigya from "~/composables/auth/useGigya";
import { useAuthStore } from "~/stores/auth.store";

const { reLogin } = useAuthStore();
const { redirectUrl } = storeToRefs(useAuthStore());
const router = useRouter();
const { pushNotification } = useNotification();
const { executeWhenIsSessionVerified, verifySession } = useGigya();
const { $t } = useI18n();
const { getPath } = useRoutes();

const checkAuth = async () => {
  // Try a relogin with Gigya
  const res = await reLogin();
  if (res) {
    // Relogin successful
    // Notify user about it and redirect back to initial page
    pushNotification(
      $t("auth.notification.session_refreshed"),
      NotificationType.Warning,
      NotificationTimeout.Medium,
    );
    router.push(redirectUrl.value);
  } else {
    // Relogin failed
    // Notification happens inside reLogin() already; forward user to login
    router.push(getPath("login"));
  }
};

onMounted(() => {
  executeWhenIsSessionVerified(() => checkAuth());
  verifySession();
});
</script>

<style lang="scss">
.check-auth {
  height: 100px;
}
</style>
