import { parseInteger } from "@shop-storefront/utils";
import { useBaseSiteStore } from "~/stores/basesite.store";
import type { FactFinder, FactFinderHit } from "~/utils/factfinder/integration/factfinder.types";
import {
  type SparProduct,
  type ProductBadge,
  type DeliveryModes,
  type ProductAvailabilityType,
  AvailabilityPossibility,
  SparProductType,
  SparBaseStoreTypes,
  PriceType,
  PriceTypeVoucher,
  PriceTypeTab,
} from "~/utils/mdsa/integration/mdsa.types";

export function resolveFactFinderProduct(product: FactFinderHit): SparProduct {
  const { sapBaseSites } = storeToRefs(useBaseSiteStore());

  const tileBadges: ProductBadge[] = [];
  if (product.masterValues?.badgeAltText && product.masterValues?.badgeDamUrl) {
    product.masterValues?.badgeAltText.split("|").forEach((altText, index) => {
      const damUrls = product.masterValues?.badgeDamUrl.split("|");

      tileBadges.push({
        badgeAltText: altText,
        badgeDamUrl: damUrls[index],
      });
    });
  }

  const deliveryModesList: DeliveryModes[] = product.masterValues.deliveryModes
    .split("|")
    .map((item) => {
      return {
        deliveryModeId: item.trim(),
      };
    });

  const prices: SparProduct["prices"] = {};
  const availabilites: ProductAvailabilityType[] = [];

  switch (product.masterValues.sparProductType) {
    case SparProductType.GWK:
    case SparProductType.Voucher:
      // map voucher to correct structure
      // vouchers will always show "P" price
      // https://collaboration.spar.at/confluence/pages/viewpage.action?pageId=343338437
      product.masterValues?.deliveryModes.split("|").forEach((deliveryMode) => {
        // check if given availabilty is present on current backend
        // otherwhise dont show price-tab
        const checkBaseSiteAvailable = sapBaseSites.value?.filter(
          (item) => item.resolvedUid === SparBaseStoreTypes.national,
        );
        if (!checkBaseSiteAvailable?.length) {
          return;
        }

        const priceType = getPriceType<PriceTypeVoucher>(deliveryMode, [
          PriceTypeVoucher.VoucherDigital,
          PriceTypeVoucher.VoucherPhysical,
        ]);
        if (!priceType) return;

        const {
          insteadPriceString,
          salesPriceString,
          recommendedRetailPriceString,
          savingsString,
          promoText,
          referencePriceString,
          onSale,
        } = product.masterValues;

        prices[priceType] = {
          priceType,
          prices: {
            priceType,
            insteadPriceString,
            salesPriceString,
            recommendedRetailPriceString,
            savingsString,
            promoText,
            referencePriceString,
            onSale: onSale === "true",
          },
        };

        availabilites.push({
          availabilityType: AvailabilityPossibility.P,
          value: product.masterValues.availabilityType_P,
        });
      });
      break;

    default:
      // map non voucher to price structure AvailabilityPossibility
      product.masterValues?.availabilityTypes.split("|").forEach((availability) => {
        const priceType = getPriceType<PriceTypeTab>(availability, [
          PriceTypeTab.T,
          PriceTypeTab.P,
        ]);
        if (!priceType) return;

        const prefixOnline = priceType === AvailabilityPossibility.P ? "" : "tOnline_";
        const onSale = priceType === AvailabilityPossibility.T ? "tOnSale" : "onSale";

        // check if given availabilty is present on current backend
        // otherwhise dont show price-tab
        const baseSite =
          priceType === AvailabilityPossibility.T
            ? SparBaseStoreTypes.timeslot
            : SparBaseStoreTypes.national;
        const checkBaseSiteAvailable = sapBaseSites.value?.filter(
          (item) => item.resolvedUid === baseSite,
        );
        if (!checkBaseSiteAvailable?.length) {
          return;
        }

        prices[priceType] = {
          priceType,
          prices: {
            priceType,
            insteadPriceString: product.masterValues[`${prefixOnline}insteadPriceString`],
            salesPriceString: product.masterValues[`${prefixOnline}salesPriceString`],
            recommendedRetailPriceString: product.masterValues.recommendedRetailPriceString,
            savingsString: product.masterValues[`${prefixOnline}savingsString`],
            promoText: product.masterValues[`${prefixOnline}promoText`],
            referencePriceString: product.masterValues[`${prefixOnline}referencePriceString`],
            onSale: product.masterValues[onSale] === "true",
          },
        };

        availabilites.push({
          availabilityType: availability.trim(),
          value: product.masterValues[`availabilityType_${priceType}`],
        });
      });
  }

  // sorting price https://collaboration.spar.at/jira/browse/HYBS-15917
  // always show p- in front of t-tab or voucher digital in front of post
  // prices.sort((a, b) => {
  //   return a.priceType.localeCompare(b.priceType);
  // });

  return {
    availabilityTypes: product.masterValues?.availabilityTypes
      .split("|")
      .map((availability) => availability.trim() as AvailabilityPossibility),
    availability: {
      eComAvailability: {
        availabilityTypes: [...availabilites],
      },
    },
    badgeSlots: [
      {
        badgeSlot1: [...tileBadges],
      },
    ],
    mainInfo: {
      productId: product.masterValues.productId,
      name1: product.masterValues.name1,
      name2: product.masterValues.name2,
      name3: product.masterValues.name3,
      name4: product.masterValues.name4,
      shopUrl: product.masterValues.shopUrl,
      deliveryTime: product.masterValues.deliveryTime,
      deliveryModes: deliveryModesList,
      salesUnit: product.masterValues.salesPriceUnit,
    },
    minOrderQuantityP: parseInteger(product.masterValues.minOrderQuantityP),
    minOrderQuantityT: parseInteger(product.masterValues.minOrderQuantityT),
    maxOrderQuantityP: parseInteger(product.masterValues.maxOrderQuantityP),
    maxOrderQuantityT: parseInteger(product.masterValues.maxOrderQuantityT),
    prices, // TODO: TS
    tilePicture: {
      assetUrl: product.masterValues.productImage_assetUrl,
      preview: product.masterValues.productImage_assetUrl, // TODO: Not available yet
      altText: product.masterValues.productImage_altText,
    },
    sparProductType: product.masterValues.sparProductType,
  };
}

export function resolveFactFinderResponse(data: FactFinder) {
  data.resolvedProducts = resolveFactFinderHits(data.hits);

  return data;
}

function getPriceType<T extends PriceType>(input: string, whiteList?: PriceType[]) {
  const value = input.trim() as PriceType;
  if (!Object.values(PriceType).includes(value)) return;
  if (whiteList && !whiteList.includes(value)) return;

  return value as T;
}

export function resolveFactFinderHits(hits: FactFinderHit[]): SparProduct[] | null {
  const products: SparProduct[] = [];

  if (hits.length) {
    hits.forEach((hit, index) => {
      products[index] = resolveFactFinderProduct(hit);
    });
    return products;
  }

  return null;
}
