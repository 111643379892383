export type SortOptions = "name1" | "salesPrice" | "creationTime" | "onSale";
export type OrderOptions = "asc" | "desc";
export type FilterParams = string | string[] | number | number[];

export interface Filters {
  [key: string]: FilterParams;
}

export interface FactFinderParams extends Filters {
  search: string;
  sort: SortOptions;
  order: OrderOptions;
  page: number;
  hits: number;
}

const isRange = (value: unknown): value is number[] => {
  if (!Array.isArray(value)) return false;
  if (value.length !== 2) return false;
  return value.every((val) => typeof val === "number");
};

export const createQuery = ({
  search,
  sort,
  order = "asc",
  page,
  hits,
  ...rest
}: Partial<FactFinderParams>) => {
  const filter = Object.entries(rest).map(([key, value]) => {
    if (isRange(value)) {
      return `filter=${key}:[${value.join(",")}]`;
    }
    if (Array.isArray(value) && value.length) {
      return `filter=${key}:${value.join("~~~")}`;
    }
    return `filter=${key}:${value}`;
  });
  return [
    `query=${search || "*"}`,
    sort && `sort=${sort}:${order}`,
    page && `page=${page}`,
    hits && `hitsPerPage=${hits}`,
    ...filter,
  ]
    .filter(Boolean)
    .join("&");
};

export default createQuery;
