import { camelize } from "@shop-storefront/utils";
import type { GetContentParams } from "@vsf-enterprise/contentstack-sdk";
import type { ContentstackPlugin } from "~/plugins/contentstack/contentstack.types";

// import ContentstackLivePreview from "@contentstack/live-preview-utils";

export default defineNuxtPlugin(async ({ $config }) => {
  const { sdk } = useVsfSdk();
  // ContentstackLivePreview.init({ ssr: true });

  const getEntries = async <T>(params: GetContentParams): Promise<T> => {
    // const { query } = useRoute();

    if (!params.locale) {
      params.locale = $config.public.cntsDefaultLocale;
    }

    // if (query.live_preview) {
    //   params.livePreviewQuery = query;
    // }

    try {
      const entries = await sdk.cms.getContent(params);
      return camelize(entries) as T;
    } catch (error) {
      throw error;
    }
  };

  return {
    provide: {
      contentstack: {
        getEntries,
      } as ContentstackPlugin,
    },
  };
});
