<template>
  <nuxt-link
    :to="linkTo"
    class="link"
    :target="linkTarget !== 'none' ? target : ''"
    :class="linkClasses"
    :aria-label="ariaLabel"
    :title="label"
    :data-tosca="getToscaPrefix('link', toscaPrefix)"
  >
    <span v-if="!iconOnly" class="link__content">
      <slot v-if="$slots.default" class="link__slot-content" />
      <span v-else class="link__label">{{ label }}</span>
    </span>

    <spar-icon-sprite v-if="isIconVariant && icon" class="link__icon" :symbol="icon" />
  </nuxt-link>
</template>

<script lang="ts" setup>
import SparIconSprite from "~/components/shared/SparIconSprite/SparIconSprite.vue";
import { IconPosition } from "~/types/icon.types";
import { getToscaPrefix } from "~/utils/ui";
import {
  type InternalLink,
  type LinkTarget,
  LinkVariant,
  type SparLinkProps,
} from "./SparLink.types";

const props: SparLinkProps = defineProps({
  label: {
    type: String,
    default: undefined,
  },
  internalLink: {
    type: Array as PropType<InternalLink[]>,
    default: undefined,
  },
  externalLink: {
    type: String,
    default: undefined,
  },
  link: {
    type: String,
    default: undefined,
  },
  target: {
    type: String as PropType<LinkTarget | null>,
    default: null,
  },
  ariaLabel: {
    type: String,
    default: undefined,
  },
  icon: {
    type: String,
    default: undefined,
  },
  iconOnly: {
    type: Boolean,
    default: false,
  },
  iconPosition: {
    type: String as PropType<IconPosition>,
    default: IconPosition.right,
  },
  variant: {
    type: String as PropType<LinkVariant>,
    default: LinkVariant.link,
  },
  toscaPrefix: {
    type: String,
    default: undefined,
  },
});

// Make sure the internalLink is correctly referenced `{fieldId}.internalLink`.
// Please refer to: https://collaboration.spar.at/confluence/display/ES/2.1.24+Linking+%28Global+Field%29+in+Contentstack
const linkTo: Ref<string | undefined> = computed(() => {
  if (typeof props.link === "string") return props.link;
  if (props.internalLink?.length) return props.internalLink[0].slug;
  if (props.externalLink) return props.externalLink;
  return undefined;
});

const linkTarget = props.internalLink?.length ? props.target : "_blank";

const isIconVariant = computed(
  () => props.variant === LinkVariant.linkIcon || props.variant === LinkVariant.secondaryIcon,
);

const linkClasses = computed(() => {
  return {
    "link--icon": isIconVariant.value && props.icon,
    "link--icon-left": isIconVariant.value && props.iconPosition === IconPosition.left,
    "link--icon-right": isIconVariant.value && props.iconPosition === IconPosition.right,
    "link--secondary":
      props.variant === LinkVariant.secondary || props.variant === LinkVariant.secondaryIcon,
  };
});
</script>

<style lang="scss">
@use "./SparLink.scss";
</style>
