import { defineStore } from "pinia";
import queryString from "query-string";
import { SearchCategory } from "~/components/feature/SparSearch/SparSearch.types";
import {
  resolveFactFinderProduct,
  resolveFactFinderResponse,
} from "~/utils/contentstack/resolvers/product/fact-finder.resolvers";
import { createQuery, type FactFinderParams } from "~/utils/factfinder/createQuery";
import {
  type FactFinder,
  type FactFinderCampaign,
  FactFinderCampaignFlavour,
  type FactFinderFacets,
  type SortedItem,
} from "~/utils/factfinder/integration/factfinder.types";
import type { SparProduct } from "~/utils/mdsa/integration/mdsa.types";

type SearchResultGroup = Partial<FactFinder>;

export const useSearchStore = defineStore("search", () => {
  const showSearchOverlay = ref(false);
  const searchResults = reactive(getEmptySearchResults());
  const refreshFunctions: Ref<(() => void)[]> = ref([]);
  // url query
  const router = useRouter();

  const urlQuery = ref({});
  const loading = ref(true);
  const redirectUrl = ref("/");

  const { $factFinder } = useNuxtApp();

  const FactFinderConfig = {
    products: {
      resolver: resolveFactFinderProduct,
      api: $factFinder.searchProducts,
      name: "factFinderSearch",
      category: SearchCategory.products,
    },
  } as const;

  async function setQuery(query: object, doCallRefreshFunctions = false) {
    loading.value = true;
    urlQuery.value = query;
    refreshFunctions.value = [];
    await getAllResults(doCallRefreshFunctions);

    // If a redirect occurs, do not switch off loading state to avoid short appearance of results
    if (!handleRedirects()) {
      loading.value = false;
    }
  }

  // Entry function for starting a new search
  async function getAllResults(doCallRefreshFunctions = false) {
    const categories = Object.keys(FactFinderConfig);
    const promises = categories.map((category) =>
      getResultsForCategory(category as keyof typeof FactFinderConfig),
    );
    await Promise.all(promises);
    if (doCallRefreshFunctions) {
      await callRefreshFunctions();
    }
  }

  async function getResultsForCategory(category: keyof typeof FactFinderConfig) {
    const { refresh: refreshFactFinder } = await useAsyncData(
      FactFinderConfig[category].name,
      async () => {
        const {
          data: { value: factFinderSearch },
        } = (await $factFinder.searchProducts({
          method: "POST",
          headers: {
            "content-type": "application/json",
          },
          body: {
            channel: "products_at-qa",
            query: createQuery(urlQuery.value),
          },
          transform: (data: FactFinder) => resolveFactFinderResponse(data),
        })) as { data: Ref<FactFinder> };

        if (factFinderSearch?.hits.length) {
          const resolved = factFinderSearch.hits.map((hit) => {
            return FactFinderConfig[category].resolver(hit);
          });

          setHits(
            FactFinderConfig[category].category,
            factFinderSearch.totalHits,
            resolved,
            factFinderSearch.campaigns,
            factFinderSearch.sortItems,
            factFinderSearch.facets,
          );
        } else {
          setHits(
            FactFinderConfig[category].category,
            0,
            [],
            factFinderSearch.campaigns,
            factFinderSearch.sortItems,
            factFinderSearch.facets,
          );
        }
      },
    );
    refreshFunctions.value.push(refreshFactFinder);
  }

  // Calling these functions is required for loading content along with the 1st app initialization
  async function callRefreshFunctions() {
    const promises = refreshFunctions.value.map((refreshFunction) => refreshFunction());
    await Promise.all(promises);
  }

  function setHits(
    category: SearchCategory,
    totalHits: number,
    hits: SparProduct[],
    campaigns: FactFinderCampaign[],
    sortItems: SortedItem[],
    facets: FactFinderFacets[],
  ) {
    searchResults[category].resolvedProducts = hits;
    searchResults[category].totalHits = totalHits;
    searchResults[category].campaigns = campaigns;
    searchResults[category].sortItems = sortItems;
    searchResults[category].facets = facets;
  }

  // There can be multiple redirects in a search result.
  // Rule: the 1st redirect in the campaigns array is the one to consider
  // TODO: What about multiple redirects in multiple search categories? (products, jobs, articles,...)
  function handleRedirects() {
    const categories = Object.keys(FactFinderConfig);
    const redirects = categories
      .map((category) => {
        const { campaigns } = searchResults[category];
        if (!campaigns) return undefined;
        return campaigns.filter(
          (campaign) => campaign.flavour === FactFinderCampaignFlavour.redirect,
        );
      })
      .filter((category) => category)
      .flat() as FactFinderCampaign[];
    if (!redirects.length) return false;

    // Called when using Browser back button to avoid infinite redirect loop
    history.pushState({ url: redirectUrl.value }, "", redirectUrl.value);
    window.location.href = redirects[0].target.destination;
    return true;
  }

  function setRedirectUrl(url: string) {
    redirectUrl.value = url;
  }

  /**
   * Update filter-url and get new list from fact-finder
   *
   *
   * @param params - Factfinder params
   * @param removeFilter - string - remove filter from url-query if nothing is set
   */
  const updateFilterUrl = (
    params: Partial<FactFinderParams>,
    removeFilter: string | null = null,
  ) => {
    const newUrlQuery: Partial<FactFinderParams> = {
      ...(urlQuery.value as FactFinderParams),
      ...params,
    };

    if (removeFilter) {
      delete newUrlQuery[removeFilter];
    }

    const urlPushQuery = queryString.stringify(newUrlQuery, {
      arrayFormat: "comma",
    });
    router.push(`?${urlPushQuery}`);

    setQuery(newUrlQuery);
  };

  return {
    showSearchOverlay,
    searchResults,
    setQuery,
    setRedirectUrl,
    loading,
    updateFilterUrl,
  };
});

// Helper Functions
const getEmptySearchResultGroup = () => {
  return {
    totalHits: 0,
    hits: [],
    campaigns: [],
  } as SearchResultGroup;
};

const getEmptySearchResults = () => {
  const res = {} as Record<string, SearchResultGroup>;
  const keys = Object.keys(SearchCategory);
  keys.forEach((key) => {
    res[key] = getEmptySearchResultGroup();
  });
  return res;
};
