import { useLoadScript } from "~/composables/load-script/useLoadScript";
import { useAuthStore } from "~/stores/auth.store";
import type { GigyaResponse } from "./gigya.types";

const isGigyaReady = ref(false);
const isGigyaMounted = ref(false);
const isGigyaLoadError = ref(false);
const executeWhenReady: Ref<(() => void)[]> = ref([]); // functions to execute when gigya is ready
const executeWhenSessionVerified: Ref<(() => void)[]> = ref([]); // functions to execute when gigya is ready
const { loadScript } = useLoadScript();

export default function useGigya() {
  const authStore = useAuthStore();
  const runtimeConfig = useRuntimeConfig();
  const apiHost = runtimeConfig.public.gigyaApiHost;
  const apiKey = runtimeConfig.public.gigyaApiKey;

  // Works only on client side
  if (typeof window !== "undefined") {
    window.onGigyaServiceReady = () => {
      isGigyaReady.value = true;
      executeWhenIsReadyHandler();

      // Check if user is logged in
      verifySession();
    };
  }

  onMounted(async () => {
    if (isGigyaMounted.value || isGigyaReady.value) return;
    if (typeof window === "undefined" || typeof window.gigya !== "undefined") return;
    isGigyaLoadError.value = false;

    const gigyaSrc = "https://" + apiHost + "/js/gigya.js?apikey=" + apiKey + "&lang=de";
    try {
      await loadScript(gigyaSrc);
      isGigyaMounted.value = true;
    } catch (_) {
      isGigyaLoadError.value = true;
    }
  });

  // Check if user is logged in
  const onSessionVerified = (eventObj: GigyaResponse) => {
    if (eventObj.errorCode === 0) {
      authStore.setIsGigyaLoggedIn(true);
    } else {
      authStore.setIsGigyaLoggedIn(false);
    }
    executeWhenIsSessionVerifiedHandler();
  };

  const verifySession = () => {
    window.gigya.accounts.session.verify({
      callback: onSessionVerified,
    });
  };

  // This method buffers Gigya function calls and executes them when Gigya is ready
  // E.g. when calling the /logout route, Gigya might not be ready when the component is rendered
  const executeWhenIsReady = (func: () => void) => {
    if (isGigyaReady.value) {
      func();
    } else {
      executeWhenReady.value.push(func);
    }
  };

  const executeWhenIsReadyHandler = () => {
    if (!executeWhenReady.value.length) return;
    executeWhenReady.value.forEach((func) => func());
  };

  // This method buffers Gigya function calls and executes them when the session is verified
  // E.g. when directly trying to access a protected page (/my-account)
  const executeWhenIsSessionVerifiedHandler = () => {
    if (!executeWhenSessionVerified.value.length) return;
    executeWhenSessionVerified.value.forEach((func) => func());
    executeWhenSessionVerified.value = [];
  };

  const executeWhenIsSessionVerified = (func: () => void) => {
    executeWhenSessionVerified.value.push(func);
  };

  return {
    executeWhenIsReady,
    executeWhenIsSessionVerified,
    isGigyaLoadError,
    isGigyaReady,
    verifySession,
  };
}
