import { defineStore } from "pinia";
import { useBaseSiteStore } from "~/stores/basesite.store";
import { useCheckoutStore } from "~/stores/checkout.store";
import type { AddressListSPAR, AddressSPAR } from "~/types/occ-custom.types";
import type { Address, User } from "~/types/occ.types";
import { UserError, handleCommerceError } from "~/utils/error";
import { SparBaseStoreTypes } from "~/utils/mdsa/integration/mdsa.types";

export const useUserStore = defineStore("user", () => {
  const { sdk } = useVsfSdk();
  const { $t } = useNuxtApp();
  const { cartContext } = storeToRefs(useCheckoutStore());
  const { baseSiteConfig } = useBaseSiteStore();

  // When adding new state variables, also reset them in $reset method
  const addresses = ref<AddressListSPAR | undefined>(undefined);
  const createOrUpdateAddressLoading = ref(false);
  const getAddressesLoading = ref(false);

  /**
   * Create or update an address of a user and refresh address list
   *
   * @param {Address} address The address to store
   * @throws {UserError}
   */
  async function createOrUpdateAddress(address: Address): Promise<void> {
    if (createOrUpdateAddressLoading.value) return;
    createOrUpdateAddressLoading.value = true;

    try {
      if (address.id) {
        await sdk.commerce.updateUserAddress(
          { addressId: address.id, address },
          baseSiteConfig(cartContext.value?.bs || SparBaseStoreTypes.national),
        );
      } else {
        await sdk.commerce.createUserAddress(
          { address: address as AddressSPAR },
          baseSiteConfig(cartContext.value?.bs || SparBaseStoreTypes.national),
        );
      }
      await getAddresses();
    } catch (error) {
      handleCommerceError(error as Error);
      throw new UserError($t("user.error.could_not_create_or_update_address"));
    } finally {
      createOrUpdateAddressLoading.value = false;
    }
  }

  /**
   * Get all addresses of a user
   *
   * @throws {UserError}
   */
  async function getAddresses(): Promise<void> {
    if (getAddressesLoading.value) return;
    getAddressesLoading.value = true;

    try {
      addresses.value = (await sdk.commerce.getUserAddresses(
        {},
        baseSiteConfig(cartContext.value?.bs || SparBaseStoreTypes.national),
      )) as AddressListSPAR | undefined;
    } catch (error) {
      handleCommerceError(error as Error);
      throw new UserError($t("user.error.could_not_get_addresses"));
    } finally {
      getAddressesLoading.value = false;
    }
  }

  /**
   * Reset the store
   */
  function $reset(): void {
    addresses.value = undefined;
    createOrUpdateAddressLoading.value = false;
    getAddressesLoading.value = false;
  }

  const hasLoadingError = ref(false);
  const userData: Ref<User | undefined> = ref(undefined);
  const getUserData = async () => {
    hasLoadingError.value = false;
    try {
      userData.value = await sdk.commerce.getUser({}, baseSiteConfig(SparBaseStoreTypes.national));
    } catch (error) {
      handleCommerceError(error as Error);
      hasLoadingError.value = true;
    }
  };

  const hasSavingError = ref(false);
  const birthDateMinValue = 19000101;
  const birthDate = ref("");
  const phoneNumber = ref("");
  const isSaving = ref(false);
  const isEditing = ref(false);
  const setUserData = async () => {
    hasSavingError.value = false;
    isSaving.value = true;
    try {
      const updateObj = {
        phoneNumber: phoneNumber.value,
        ...(birthDate.value && { birthDate: birthDate.value }),
      };

      await sdk.commerce.updateUser(
        { user: updateObj as User },
        baseSiteConfig(SparBaseStoreTypes.national),
      );

      userData.value = {
        ...userData.value,
        ...updateObj,
      };
      isEditing.value = false;
    } catch (error) {
      handleCommerceError(error as Error);
      hasSavingError.value = true;
    } finally {
      isSaving.value = false;
    }
  };

  return {
    addresses,
    createOrUpdateAddress,
    createOrUpdateAddressLoading,
    getAddresses,
    getAddressesLoading,
    getUserData,
    userData,
    hasLoadingError,
    hasSavingError,
    birthDateMinValue,
    birthDate,
    phoneNumber,
    isSaving,
    isEditing,
    setUserData,
    $reset,
  };
});
