interface ScriptOptions {
  async?: boolean;
  defer?: boolean;
  attributes?: Record<string, string>;
}

// State for loaded scripts
const loadedScripts = ref<string[]>([]);

export function useLoadScript() {
  const loadScript = async (
    srcUrl: string,
    options: ScriptOptions = {},
    forceLoad = false,
  ): Promise<void> => {
    if (loadedScripts.value.includes(srcUrl) && !forceLoad) return Promise.resolve();

    if (!document) {
      return Promise.reject(Error(`Load-Script Error for ${srcUrl}. "document" not loaded yet.`));
    }

    return await new Promise((resolve, reject) => {
      const scriptElement = document.createElement("script");
      scriptElement.type = "text/javascript";
      scriptElement.src = srcUrl;
      scriptElement.defer = options.defer ?? false;
      scriptElement.async = options.async ?? false;

      for (const [key, value] of Object.entries(options.attributes ?? {})) {
        scriptElement.setAttribute(key, value);
      }

      document.head.appendChild(scriptElement);

      scriptElement.addEventListener("load", () => {
        scriptElement?.setAttribute("data-loaded", "");
        loadedScripts.value.push(srcUrl);
        return resolve();
      });
      scriptElement.addEventListener("error", (error) => {
        return reject(error);
      });
    });
  };

  return {
    loadScript,
  };
}
