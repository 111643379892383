<template>
  <div class="content-page container">
    <div class="check-payment">
      <spar-loader />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { SparLoader } from "~/components/shared";
import usePaymentRedirect from "./usePaymentRedirect";
import type { PayPalQueryParams } from "./usePaymentRedirect.types";

definePageMeta({
  layout: "reduced",
});

const route = useRoute();
const { placeOrderAfterRedirectParams, urlParams } = usePaymentRedirect();

urlParams.value = route.query as unknown as PayPalQueryParams;
placeOrderAfterRedirectParams.value = {
  paymentId: urlParams.value.token,
  redirectMessageAuthenticationCode: urlParams.value.token,
  cartId: urlParams.value.cartId,
};
</script>

<style lang="scss">
@use "./payment.scss";
</style>
