import {
  type ProductMainInfo,
  type ProductAvailability,
  AvailabilityPossibility,
  type SparProduct,
  type ProductPicture,
} from "~/utils/mdsa/integration/mdsa.types";

export const BuyBoxFormat = {
  small: "small",
  big: "big",
} as const;
export type BuyBoxFormat = (typeof BuyBoxFormat)[keyof typeof BuyBoxFormat];

export type BuyBoxTab = typeof AvailabilityPossibility.T | typeof AvailabilityPossibility.P;

export interface SparBuyBoxProps {
  availabilityTypes?: AvailabilityPossibility[];
  availability?: ProductAvailability;
  additionalServices?: SparProduct[];
  mainInfo: ProductMainInfo;
  minOrderQuantityP?: number;
  minOrderQuantityT?: number;
  maxOrderQuantityP?: number;
  maxOrderQuantityT?: number;
  pictures?: ProductPicture[];
  prices: SparProduct["prices"];
  sparProductType?: string;
  boxFormat?: BuyBoxFormat;
}
