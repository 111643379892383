<template>
  <div
    class="spar-select"
    :class="{
      'spar-select--invalid': !isValid,
      'spar-select--required': required,
      'spar-select--disabled': disabled,
    }"
  >
    <label v-if="label" :for="uniqueId" class="spar-select__label">{{ label }}</label>
    <select
      :id="uniqueId"
      v-model="internalValue"
      :data-tosca="getToscaPrefix('select', toscaPrefix)"
      class="spar-select__select"
      :required="required"
      :disabled="disabled"
      :name="name"
      :aria-invalid="!isValid"
      :aria-required="required"
      :aria-describedby="errorMessage || legend ? `${uniqueId}-legend` : undefined"
      @change="onBlur"
    >
      <option v-if="showHint" value="" :hidden="!enableHintOption">{{ hint }}</option>
      <option v-for="option in options" :key="option.key" :value="option.key">
        {{ option.value }}
      </option>
    </select>
    <div
      v-if="((legend || $slots['legend']) && isValid) || !isValid"
      :id="`${uniqueId}-legend`"
      class="spar-select__legend"
    >
      <span
        v-show="!isValid"
        class="spar-select__error-message"
        :data-tosca="getToscaPrefix('error-msg', toscaPrefix)"
        aria-live="polite"
      >
        {{ errorMessage }}
      </span>

      <span v-if="isValid && legend && !$slots['legend']">{{ legend }}</span>
      <slot v-else-if="isValid" name="legend"></slot>
    </div>
  </div>
</template>

<script lang="ts" setup>
import useI18n from "~/composables/i18n/useI18n";
import { useInputValue } from "~/composables/inputs/useInputValue";
import { getToscaPrefix } from "~/utils/ui";
import type { SparSelectProps, SparSelectOption } from "./SparSelect.types";

const props: SparSelectProps = defineProps({
  modelValue: {
    type: [String, Number],
    default: "",
  },
  label: {
    type: String,
    default: null,
  },
  legend: {
    type: String,
    default: null,
  },
  name: {
    type: String,
    default: null,
  },
  required: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  selectHint: {
    // Defaults to $t("form.select.please_choose"); see computed below
    type: String,
    default: null,
  },
  showHint: {
    type: Boolean,
    default: true,
  },
  enableHintOption: {
    type: Boolean,
    default: false,
  },
  options: {
    type: Array as PropType<SparSelectOption[]>,
    required: true,
  },
  toscaPrefix: {
    type: String,
    default: undefined,
  },
});

const emit = defineEmits(["update:modelValue", "interface", "onUnmount"]);

const { internalValue, isValid, errorMessage, uniqueId, onBlur } = useInputValue(
  props as unknown as SparSelectProps,
  emit,
);
const { $t } = useI18n();

const hint = computed(() => props.selectHint || $t("form.select.please_choose"));
</script>

<style lang="scss">
@use "./SparSelect.scss";
</style>
