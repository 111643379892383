<template>
  <div v-if="currentIndex != null" class="lightbox__wrapper">
    <div class="lightbox" role="dialog" :aria-label="$t('lightbox.dialog.label')">
      <spar-button
        ref="initFocus"
        :variant="ButtonVariant.custom"
        :aria-label="$t('lightbox.close')"
        class="lightbox__close"
        icon="close"
        icon-only
        @click="closeLightbox"
      >
      </spar-button>
      <div class="lightbox__media">
        <div v-if="currentMedia.type === ProductPictureType.image">
          <spar-adaptive-image
            data-tosca="lightbox-image"
            :src="currentMedia.src"
            :alt="currentMedia.alt"
          />
        </div>
        <div v-else-if="currentMedia.type === ProductPictureType.video">
          <spar-video :poster="currentMedia.preview" :file-url="currentMedia.src"></spar-video>
        </div>
      </div>
      <div class="lightbox__navigation">
        <!-- 
          NO ARROWS NEEDED AT THE MOMENT
          <spar-button
          :variant="ButtonVariant.custom"
          class="lightbox__navigation-btn lightbox__navigation-btn-left"
          @click="currentIndex--"
          :disabled="currentIndex === 0"
          :aria-label="currentIndex === 0 ? $t('lightbox.no_previous') : $t('lightbox.previous')"
        >
          <spar-icon-sprite symbol="arrow-left" />
        </spar-button>
        <spar-button
          :variant="ButtonVariant.custom"
          class="lightbox__navigation-btn lightbox__navigation-btn-right"
          @click="currentIndex++"
          :disabled="currentIndex === items.length - 1"
          :aria-label="
            currentIndex === items.length - 1 ? $t('lightbox.no_next') : $t('lightbox.next')
          "
        > 
          <spar-icon-sprite symbol="arrow-right" />
        </spar-button>-->
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { onKeyStroke, useFocus } from "@vueuse/core";
import SparAdaptiveImage from "~/components/shared/SparAdaptiveImage/SparAdaptiveImage.vue";
import { ButtonVariant } from "~/components/shared/SparButton/SparButton.types";
import SparButton from "~/components/shared/SparButton/SparButton.vue";
import SparVideo from "~/components/shared/SparVideo/SparVideo.vue";
import useI18n from "~/composables/i18n/useI18n";
import { ProductPictureType } from "~/utils/mdsa/integration/mdsa.types";
import type { SparLightboxItem, SparLightboxProps } from "./SparLightbox.types";

const { $t } = useI18n();

const initFocus = ref(null);
useFocus(initFocus, { initialValue: true });

const props: SparLightboxProps = defineProps({
  items: {
    type: Object as PropType<SparLightboxItem[]>,
    required: true,
  },
  lightboxOpenIndex: {
    type: Number as PropType<number | null>,
    default: null,
  },
});

const currentIndex = ref(props.lightboxOpenIndex);
watch(
  () => props.lightboxOpenIndex,
  (index) => {
    if (index !== null) {
      currentIndex.value = index;
    }
  },
);

const currentMedia = computed(() => {
  return props.items.filter((item) => item.id === currentIndex.value)[0];
});

const closeLightbox = () => {
  currentIndex.value = null;
  emit("closeLightBox");
};

// no arrows needed at the moment.
// onKeyStroke("ArrowRight", (e) => {
//   if (currentIndex.value !== null && currentIndex.value + 1 !== props.items.length) {
//     currentIndex.value++;
//   }
//   e.preventDefault();
// });
// onKeyStroke("ArrowLeft", (e) => {
//   if (currentIndex.value !== null && currentIndex.value - 1 >= 0) {
//     currentIndex.value--;
//   }
//   e.preventDefault();
// });
onKeyStroke("Escape", (e) => {
  closeLightbox();
  e.preventDefault();
});

const emit = defineEmits(["closeLightBox"]);
</script>

<style lang="scss">
@use "./SparLightbox.scss";
</style>
