import type { InternalFetch } from "~/plugins/fetch";
import { servicesEndpoints } from "~/utils/bff/endpoints.constants";
import type {
  ProductAvailability,
  ProductPriceData,
  ProductData,
  SparProduct,
  ProductPrice,
} from "./mdsa.types";

export default class MdsaIntegration {
  private readonly internalFetch: InternalFetch;

  constructor(internalFetch: InternalFetch) {
    this.internalFetch = internalFetch;
  }
  public async searchMdsaProduct<T>(query: Record<string, string>) {
    return this.internalFetch<T>(servicesEndpoints.mdsa.product, {
      query,
    });
  }

  public async getMdsaProductPrice(href: string): Promise<{ prices: ProductPrice[] }> {
    const { data: prices } = await $fetch<ProductPriceData>(href);
    return prices;
  }

  public async getMdsaProductAvailability(href: string): Promise<ProductAvailability> {
    const { data: eComAvailability } = await $fetch<{ data: ProductAvailability }>(href);
    return eComAvailability;
  }

  public async getMdsaAdditionalServiceProduct(
    serviceId: string,
  ): Promise<{ products: SparProduct[] }> {
    // TODO change to $mdsa.searchMdsaProduct in the future
    const { data: products } = await $fetch<ProductData>(
      `https://mockoon-mdsa.apps.ocpt01.spar.local.at/mdsa/v3/AT/p/${serviceId}`,
    );

    // fetch prices
    if (products.products[0] && products.products[0].prices?.P?.href) {
      const { prices: priceItem } = await this.getMdsaProductPrice(
        products.products[0].prices.P.href,
      );

      products.products[0].prices.P.prices = priceItem[0];
    }

    return products;
  }
}
