export const DeliveryMode = {
  ParcelWarehouse: "Parcel-Warehouse",
  Timeslot: "Timeslot",
} as const;
export type DeliveryMode = (typeof DeliveryMode)[keyof typeof DeliveryMode];

export const DeliveryModeVoucher = {
  VoucherPhysical: "Voucher-Physical",
  VoucherDigital: "Voucher-Digital",
} as const;
export type DeliveryModeVoucher = (typeof DeliveryModeVoucher)[keyof typeof DeliveryModeVoucher];

export const SparProductType = {
  General: "General",
  GWK: "GWK",
  Voucher: "Voucher",
  Service: "Service",
  Procurement: "Procurement",
  ApproximateWeight: "ApproximateWeight",
  Weighted: "Weighted",
  Display: "Display",
  Set: "Set",
  Returnable: "Returnable",
  PartyService: "PartyService",
  Thm: "Thm",
  Unsaleable: "Unsaleable",
  ShippingCosts: "ShippingCosts",
} as const;
export type SparProductType = (typeof SparProductType)[keyof typeof SparProductType];

export const SparBaseStoreTypes = {
  national: "national",
  timeslot: "timeslot",
} as const;
export type SparBaseStoreTypes = (typeof SparBaseStoreTypes)[keyof typeof SparBaseStoreTypes];

export const AvailabilityPossibility = {
  default: "default",
  T: "T",
  P: "P",
  TOffline: "TOffline",
} as const;
export type AvailabilityPossibility =
  (typeof AvailabilityPossibility)[keyof typeof AvailabilityPossibility];

export const ProductAvailabilityTypes = {
  buyable: "buyable",
  searchable: "searchable",
} as const;
export type ProductAvailabilityTypes =
  (typeof ProductAvailabilityTypes)[keyof typeof ProductAvailabilityTypes];

export const PriceTypeVoucher = {
  VoucherDigital: "Voucher-Digital",
  VoucherPhysical: "Voucher-Physical",
} as const;
export type PriceTypeVoucher = (typeof PriceTypeVoucher)[keyof typeof PriceTypeVoucher];

export const PriceTypeTab = {
  T: "T",
  P: "P",
} as const;
export type PriceTypeTab = (typeof PriceTypeTab)[keyof typeof PriceTypeTab];

export const PriceType = {
  default: "default", // Not used, but provided by backend
  tOffline: "TOffline",
  ...PriceTypeVoucher,
  ...PriceTypeTab,
} as const;
export type PriceType = (typeof PriceType)[keyof typeof PriceType];

export const BomType = {
  service: "service",
} as const;
export type BomType = (typeof BomType)[keyof typeof BomType];

export const ProductPictureType = {
  image: "image",
  document: "document",
  brandimage: "brandimage",
  video: "video",
} as const;
export type ProductPictureType = (typeof ProductPictureType)[keyof typeof ProductPictureType];

export const ProductDocumentType = {
  pdfDatasheet: "pdf_datasheet",
} as const;
export type ProductDocumentType = (typeof ProductDocumentType)[keyof typeof ProductDocumentType];

export interface Breadcrumb {
  name: string;
  categoryId: string;
  level: number;
  link: string;
  target: string;
}

export interface ProductBadge {
  badgeSequence?: number;
  badgeDamUrl: string;
  badgeAltText: string;
}

export interface ProductBadgeSlot {
  badgeSlot1?: ProductBadge[];
  badgeSlot2?: ProductBadge[];
}

export interface ProductPriceItem {
  priceType: PriceType;
  voucher?: string;
  warehouse?: string;
  productId?: string;
  href?: string;
  prices?: ProductPrice;
  isAvailable?: boolean;
  stores?: {
    storenumber: string;
    href: string;
  }[];
}

export interface ProductPriceData {
  data: {
    prices: ProductPrice[];
  };
}

export interface ProductPicture {
  type?: ProductPictureType;
  position?: string;
  assetUrl?: string;
  servletBaseUrl?: string;
  progressiveUrl?: string;
  thumbnailImageLink?: string;
  altText: string;
  preview: string;
}

export interface ProductAttribute {
  name?: string;
  feName: string;
  value: string;
}

export interface ProductCategories {
  type: string;
  categoryId: string;
  name: string;
  url: string;
  description: string;
}

export interface ProductBoms {
  bomType: string;
  bomTarget: string;
  bomFactor: number;
}

export interface ProductPrice {
  priceType: string;
  insteadPriceString?: string;
  insteadPriceText?: string;
  salesPriceString?: string;
  recommendedRetailPriceString?: string;
  savingsString?: string;
  promoText?: string;
  referencePriceString?: string;
  referencePriceString2?: string;
  priceValidUntilString?: string;
  onSale?: boolean;
  taxRate?: number;
  taxText?: string;
  redFlag?: boolean;
  country?: string;
  currency?: string;
  currencySymbol?: string;
  itemCount?: string;
}

export interface DeliveryModes {
  deliveryModeId?: string;
  defaultDeliveryTime?: string;
  associatedAvailabilityType?: PriceType;
}

export interface ProductMainInfo {
  productId: string;
  name1: string;
  name2: string;
  name3?: string;
  name4?: string;
  shopUrl: string;
  excludeDeliveryAbroad?: boolean;
  defaultDeliveryMode?: string;
  deliveryTimeException?: string;
  deliveryTime?: string;
  deliveryModes?: DeliveryModes[];
  salesUnit?: string;
}

export interface ProductAvailabilityType {
  availabilityType?: string;
  value?: string;
}

export interface ProductAvailabilityData {
  productId?: string;
  currentlyReservable?: boolean;
  availability?: string;
  availabilitiesWarehouse?: [
    {
      warehouse?: string;
      value?: string;
    },
  ];
  availabilityTypes?: ProductAvailabilityType[];
}
export interface ProductAvailability {
  _links?: {
    self: {
      href: string;
    };
  };
  eComAvailability: ProductAvailabilityData;
}

export interface ProductData {
  data: {
    products: SparProduct[];
  };
}

export interface SparProduct {
  availabilityTypes?: AvailabilityPossibility[];
  availability?: ProductAvailability;
  attributes?: ProductAttribute[];
  additionalServices?: SparProduct[];
  badgeSlots?: ProductBadgeSlot[];
  breadcrumbs?: Breadcrumb[];
  boms?: ProductBoms[];
  categories?: ProductCategories[];
  creationTime?: string;
  deliveryModes?: string[];
  gtinList?: [
    {
      name: string;
      factor: number;
    },
  ];
  mainInfo: ProductMainInfo;
  mainSupplierId?: string;
  mainGtin?: string;
  maxOrderQuantityP?: number;
  maxOrderQuantityT?: number;
  minOrderQuantityP?: number;
  minOrderQuantityT?: number;
  modifiedTime?: string;
  origin?: {
    supplierName: string;
  };
  overwriteReservable?: boolean;
  ownBrand?: boolean;
  pictures?: ProductPicture[];
  prices: {
    [key in PriceType]?: ProductPriceItem;
  };
  productDescription?: {
    marketingText: ProductAttribute;
    productMaintenanceText?: {
      values: [{ value: string }];
    };
  };
  purchaseGroup?: string;
  sparProductType?: string;
  salesUnit?: string;
  tilePicture?: ProductPicture;
}
