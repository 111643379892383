<template>
  <section class="teaserblock container spar-section">
    <template v-if="content">
      <render-sections :content="content" />
    </template>
  </section>
</template>

<script lang="ts" setup>
import RenderSections from "~/components/sections/RenderSections/RenderSections.vue";
import type { SparTeaserblockContent, SparTeaserblockProps } from "./SparTeaserblock.types";

const props = defineProps({
  teasers: {
    type: Array as PropType<SparTeaserblockContent[]>,
    required: true,
  },
}) as SparTeaserblockProps;

const content = props.teasers.map((teaser) => {
  return {
    content: [teaser],
  };
});
</script>

<style lang="scss">
@use "./SparTeaserblock.scss";
</style>
