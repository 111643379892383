<template>
  <div class="address-list">
    <template v-if="!addresses || addressForm">
      <button
        :aria-label="$t('address.overlay.back')"
        class="address-list__back btn--clear"
        type="button"
        data-tosca="address-list-go-back"
        @click="hideAddressForm"
      >
        <spar-icon-sprite class="address-list__back-icon" symbol="arrow-left"></spar-icon-sprite>
        {{ $t("address.overlay.back") }}
      </button>
      <spar-address-form
        :address="addressToEdit"
        :country-type="countryType"
        tosca-prefix="checkout-delivery-user"
        @submit="saveAddress"
      ></spar-address-form>
    </template>
    <template v-else>
      <div
        v-for="(address, addressIndex) in addresses"
        :key="addressIndex"
        class="address-list__list"
      >
        <spar-address
          :address="address"
          :editable="true"
          :selectable="true"
          @edit="editAddress(address)"
          @select="emit('select', address)"
        ></spar-address>
      </div>
      <div class="address-list__new-wrapper" @click="showAddressForm">
        <spar-icon-sprite symbol="plus" class-name="address-list__new-icon"></spar-icon-sprite>
        <div class="address-list__new-text" data-tosca="checkout-delivery-add-address">
          {{ $t("address.delivery.new") }}
        </div>
      </div>
      <spar-button
        icon="arrow-right"
        icon-position="right"
        tosca-prefix="checkout-delivery-set-address"
        @click="emit('setAddress')"
      >
        {{ $t("address.overlay.set_address") }}
      </spar-button>
    </template>
  </div>
</template>

<script lang="ts" setup>
import { SparButton } from "~/components/shared";
import SparAddress from "~/components/shared/SparAddress/SparAddress.vue";
import SparAddressForm from "~/components/shared/SparAddressForm/SparAddressForm.vue";
import SparIconSprite from "~/components/shared/SparIconSprite/SparIconSprite.vue";
import { useUserStore } from "~/stores/user.store";
import type { CountryType } from "~/types/country.types";
import type { Address } from "~/types/occ.types";

defineProps({
  countryType: {
    type: String as PropType<CountryType>,
    required: true,
  },
  editable: {
    type: Boolean,
    default: false,
  },
});

interface SparAddressListEmits {
  (e: "select", value: Address): void;
  (e: "setAddress"): void;
}
const emit = defineEmits<SparAddressListEmits>();

const { addresses: userAddresses } = storeToRefs(useUserStore());
const { createOrUpdateAddress, getAddresses } = useUserStore();
const addressForm = ref(false);
const addressToEdit = ref<Address | undefined>(undefined);

const addresses = computed(() => {
  return userAddresses.value?.addresses;
});

function editAddress(address: Address) {
  addressToEdit.value = address;
  showAddressForm();
}

const saveAddress = async (address: Address) => {
  await createOrUpdateAddress(address);
  hideAddressForm();
};

function hideAddressForm(): void {
  addressForm.value = false;
  addressToEdit.value = undefined;
}

function showAddressForm(): void {
  addressForm.value = true;
}

onMounted(async () => {
  await getAddresses();
});
</script>

<style lang="scss">
@use "./SparAddressList.scss";
</style>
