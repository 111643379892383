<template>
  <spar-checkout-steps :active-steps="['0', '1', '2', '3']" />
  <section class="checkout-content">
    <div class="checkout-summary__address-block">
      <div
        v-if="cartContext?.cart?.deliveryAddress"
        class="checkout-summary__delivery-address"
        data-tosca="checkout-sum-delivery-address"
      >
        <spar-address
          :border="false"
          :address="cartContext.cart.deliveryAddress"
          :heading="$t('checkout.step.delivery.address.label')"
          tosca-prefix="checkout-sum-delivery"
        ></spar-address>
      </div>
      <div
        v-if="cartContext?.cart?.paymentAddress"
        class="checkout-summary__billing-address"
        data-tosca="checkout-sum-payment-address"
      >
        <spar-address
          :border="false"
          :address="cartContext.cart.paymentAddress"
          :heading="$t('checkout.step.payment.address.label')"
          tosca-prefix="checkout-sum-payment"
        ></spar-address>
      </div>
    </div>
    <div class="checkout-summary__payment-block">
      <div class="checkout-summary__payment-label">
        {{ $t("checkout.step.summary.payment.label") }}
      </div>
      <div class="checkout-summary__payment-mode" data-tosca="checkout-sum-payment-method">
        {{ cartContext?.cart?.paymentInfo?.paymentMode }}
      </div>
    </div>
    <div
      v-if="cartContext?.cart?.deliveryOrderGroups?.length"
      class="checkout-summary__groups-block"
      data-tosca="checkout-sum-delivery-methods"
    >
      <spar-checkout-delivery-groups
        :delivery-order-groups="cartContext.cart.deliveryOrderGroups"
      />
    </div>

    <spar-checkout-consents />
  </section>

  <spar-checkout-proceed>
    <div class="checkout-summary__proceed">
      <spar-checkout-overview
        :headline-title="$t('checkout.step.summary.overview.title')"
        :order-discounts="cartContext?.cart?.orderDiscounts"
        :applied-vouchers="cartContext?.cart?.appliedVouchers"
        :delivery-cost="cartContext?.cart?.deliveryCost"
        :total-price-with-tax="cartContext?.cart?.totalPriceWithTax"
      />
      <div class="checkout-proceed__payment-btn">
        <spar-button
          role="link"
          type="button"
          class="checkout-proceed__btn"
          data-tosca="checkout-sum-buynow"
          icon="arrow-right"
          full-width
          :disabled="!isValid || !consentsGiven || placeOrderLoading"
          :loading="placeOrderLoading"
          @click="handlePlaceOrder"
          >{{ $t("checkout.step.summary.buy_now.btn") }}</spar-button
        >
      </div>
    </div>
  </spar-checkout-proceed>

  <div class="checkout-proceed__btn-mobile-wrapper">
    <spar-button
      role="link"
      type="button"
      class="checkout-proceed__btn"
      data-tosca="checkout-sum-buynow-mobile"
      icon="arrow-right"
      full-width
      :disabled="!isValid || !consentsGiven || placeOrderLoading"
      :loading="placeOrderLoading"
      @click="handlePlaceOrder"
      >{{ $t("checkout.step.summary.buy_now.btn") }}</spar-button
    >
  </div>
</template>

<script lang="ts" setup>
import type { CartStepErrorsAndWarnings } from "~/components/feature/SparCart/SparCart.types";
import SparCheckoutDeliveryGroups from "~/components/feature/SparCheckout/SparCheckoutDeliveryGroups/SparCheckoutDeliveryGroups.vue";
import SparCheckoutOverview from "~/components/feature/SparCheckout/SparCheckoutOverview/SparCheckoutOverview.vue";
import SparCheckoutProceed from "~/components/feature/SparCheckout/SparCheckoutProceed/SparCheckoutProceed.vue";
import SparCheckoutSteps from "~/components/feature/SparCheckout/SparCheckoutSteps/SparCheckoutSteps.vue";
import { SparAddress, SparButton } from "~/components/shared";
import useNotification, {
  NotificationTimeout,
  NotificationType,
} from "~/components/shared/SparNotification/useNotification";
import useI18n from "~/composables/i18n/useI18n";
import { useCheckoutStore } from "~/stores/checkout.store";
import { CheckoutStep } from "~/types/checkout.types";
import type { CartError } from "~/utils/error";
import SparCheckoutConsents from "./SparCheckoutConsents/SparCheckoutConsents.vue";

const { pushNotification } = useNotification();
const { cartContext, consentsGiven, placeOrderLoading } = storeToRefs(useCheckoutStore());
const { loadCart, placeOrder } = useCheckoutStore();
const { $t } = useI18n();

const handlePlaceOrder = async () => {
  try {
    await placeOrder();
  } catch (e) {
    pushNotification((e as CartError).message, NotificationType.Error, NotificationTimeout.Medium);
  }
};

const cartStepErrorsAndWarnings = computed((): CartStepErrorsAndWarnings => {
  if (!cartContext.value) return { errors: {}, warnings: {} };
  return cartContext.value.getCartStepErrorsAndWarnings(CheckoutStep.Summary);
});

const isValid = computed(() => {
  return Object.keys(cartStepErrorsAndWarnings.value?.errors).length === 0;
});

await loadCart();
</script>

<style lang="scss">
@use "./SparCheckoutSummary.scss";
</style>
