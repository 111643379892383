import type { InternalFetch } from "~/plugins/fetch";
import { servicesEndpoints } from "~/utils/bff/endpoints.constants";

export default class FormIntegration {
  private readonly internalFetch: InternalFetch;

  constructor(internalFetch: InternalFetch) {
    this.internalFetch = internalFetch;
  }

  public async submitForm<T>(options: Record<string, unknown>) {
    return this.internalFetch<T>(servicesEndpoints.form.submitForm, {
      method: "POST",
      body: options,
    });
  }
}
