<template>
  <section
    class="text-image-video spar-section container"
    :class="{
      'text-image-video--full-width': fullWidth,
    }"
  >
    <div v-if="leftSide?.mediaType" class="text-image-video__row">
      <spar-media-block v-bind="leftSide" :full-width="fullWidth"></spar-media-block>
    </div>

    <div v-if="rightSide?.mediaType" class="text-image-video__row">
      <spar-media-block v-bind="rightSide" :full-width="fullWidth"></spar-media-block>
    </div>
  </section>
</template>

<script lang="ts" setup>
import { SparMediaBlock, type SparMediaBlockProps } from "~/components/shared";

const props = defineProps({
  leftSide: {
    type: Object as PropType<SparMediaBlockProps>,
    default: () => undefined,
  },
  rightSide: {
    type: Object as PropType<SparMediaBlockProps>,
    default: () => undefined,
  },
});

const fullWidth = !(props.leftSide?.mediaType && props.rightSide?.mediaType);
</script>

<style lang="scss">
@use "./SparTextImageVideo.scss";
</style>
