<template>
  <div class="search-result-page container">
    <template v-if="content?.[0]?.topSections">
      <render-sections :content="content[0].topSections" />
    </template>

    <spar-search />

    <template v-if="content?.[0]?.bottomSections">
      <render-sections :content="content[0].bottomSections" />
    </template>
  </div>
</template>

<script lang="ts" setup>
import type { SearchResultsPage } from "@shop-storefront/utils";
import queryString from "query-string";
import SparSearch from "~/components/feature/SparSearch/SparSearch.vue";
import RenderSections from "~/components/sections/RenderSections/RenderSections.vue";
import { ContentstackPageParams } from "~/plugins/contentstack/contentstack.types";
import { useSearchStore } from "~/stores/search.store";
import ContentType from "~/utils/contentstack/constants/content-types.constants";
import { searchResultsPageReferences } from "~/utils/contentstack/constants/references.constants";

const route = useRoute();
const { $contentstack } = useNuxtApp();
const searchStore = useSearchStore();
const { getSlug } = useRoutes();

const { data: content, refresh: refreshContent } = await useAsyncData(
  "searchResultsPage",
  async () => {
    return await $contentstack.getEntries<SearchResultsPage>({
      type: ContentType.searchResultsPage,
      customQuery: {
        [ContentstackPageParams.slug]: getSlug("search"),
      },
      includeReference: searchResultsPageReferences as string[],
    });
  },
);

onMounted(() => {
  searchStore.setQuery(
    queryString.parse(route.fullPath.split("?")[1], {
      parseNumbers: true,
      arrayFormat: "comma",
    }),
    true,
  );
  refreshContent();
});

watch(
  () => route.fullPath,
  (fullPath) => {
    if (fullPath) {
      searchStore.setQuery(
        queryString.parse(fullPath.split("?")[1], {
          parseNumbers: true,
          arrayFormat: "comma",
        }),
      );
    }
  },
);
</script>
