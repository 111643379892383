<template>
  <div class="category container">
    <spar-breadcrumbs :breadcrumbs="breadcrumbs" tosca-prefix="plp" />
    <spar-plp />
  </div>
</template>

<script lang="ts" setup>
import SparBreadcrumbs from "~/components/feature/SparBreadcrumbs/SparBreadcrumbs.vue";
import SparPlp from "~/components/feature/SparPlp/SparPlp.vue";

const { $indexes } = useNuxtApp();

const {
  params: { categoryId },
} = useRoute();

const breadcrumbs = $indexes.getBreadcrumbs(categoryId as string);
</script>

<style lang="scss">
@use "./category.scss";
</style>
