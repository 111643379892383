<template>
  <div :class="widthClass">
    <!-- Input: Text, Email, Date -->
    <spar-input
      v-if="fieldComp.contentTypeUid === FieldContentType.flexible_form_input_field"
      v-model="model"
      :type="inputType"
      :maxlength="(fieldComp as FieldTextarea).maxLength || 100"
      :label="fieldComp.label"
      :name="fieldComp.uid"
      :required="fieldComp.required || false"
      :min-date="getDateMinMax('min') || undefined"
      :max-date="getDateMinMax('max') || undefined"
      :tosca-prefix="toscaPrefixComp"
      @interface="handleChildInterface"
    >
      <template #legend>
        <div
          v-if="fieldComp.helpText"
          class="flexible-form__legend-wrapper"
          v-html="fieldComp.helpText"
        />
      </template>
    </spar-input>

    <!-- Textarea -->
    <spar-textarea
      v-else-if="fieldComp.contentTypeUid === FieldContentType.flexible_form_textarea"
      v-model="model"
      :maxlength="(fieldComp as FieldTextarea).maxLength || 100"
      :label="fieldComp.label"
      :name="fieldComp.uid"
      :required="fieldComp.required || false"
      :tosca-prefix="toscaPrefixComp"
      @interface="handleChildInterface"
    >
      <template #legend>
        <div
          v-if="fieldComp.helpText"
          class="flexible-form__legend-wrapper"
          v-html="fieldComp.helpText"
        />
      </template>
    </spar-textarea>

    <!--
        TODO:
        Allow multiple uploads (fieldComp.maxNumberOfFiles)
        Check if mandatory (fieldComp.required)
        --] As soon as upload backend is ready!
    -->
    <!-- File Upload -->
    <spar-file-upload
      v-else-if="fieldComp.contentTypeUid === FieldContentType.flexible_form_file_upload"
      :label="fieldComp.label"
      :name="fieldComp.uid"
      :accepted-file-extensions="(fieldComp as FieldFile).allowedFileTypes"
      :max-size="(fieldComp as FieldFile).maxFileSize * 1024 * 1024"
      :tosca-prefix="toscaPrefixComp"
      @interface="handleChildInterface"
    />

    <!-- Select -->
    <spar-select
      v-else-if="
        fieldComp.contentTypeUid === FieldContentType.flexible_form_selection &&
        (fieldComp as FieldSelection).type === SelectionType.Dropdown
      "
      v-model="model"
      :label="fieldComp.label"
      :name="fieldComp.uid"
      :required="fieldComp.required || false"
      :options="getSelectOptions((fieldComp as FieldSelection).option)"
      :tosca-prefix="toscaPrefixComp"
      @interface="handleChildInterface"
    >
      <template v-if="fieldComp.helpText" #legend>
        <div class="flexible-form__legend-wrapper" v-html="fieldComp.helpText" />
      </template>
    </spar-select>

    <!-- Checkbox -->
    <spar-checkbox-group
      v-else-if="isRadioOrCheckbox && (fieldComp as FieldSelection).type === SelectionType.Checkbox"
      :required="fieldComp.required"
      :options="getOptions()"
      :label="fieldComp.label"
      :tosca-prefix="toscaPrefix"
      @interface="handleChildInterface"
    >
      <template v-if="fieldComp.helpText" #legend>
        <div class="flexible-form__legend-wrapper" v-html="fieldComp.helpText" />
      </template>
    </spar-checkbox-group>

    <!-- Radio -->
    <spar-radio-group
      v-else-if="isRadioOrCheckbox && (fieldComp as FieldSelection).type === SelectionType.Radio"
      :required="fieldComp.required"
      :options="getOptions()"
      :label="fieldComp.label"
      :tosca-prefix="toscaPrefix"
      @interface="handleChildInterface"
    >
      <template v-if="fieldComp.helpText" #legend>
        <div class="flexible-form__legend-wrapper" v-html="fieldComp.helpText" />
      </template>
    </spar-radio-group>
  </div>
</template>

<script lang="ts" setup>
import {
  SparCheckboxGroup,
  SparFileUpload,
  SparInput,
  SparRadioGroup,
  SparSelect,
  SparTextarea,
} from "~/components/shared";
import { getTodayForMinMax } from "~/composables/inputs/useInputValue";
import type { InputInterface } from "~/composables/inputs/useInputValues.types";
import { getSanitizedIdString, getToscaPrefix } from "~/utils/ui";
import {
  type FieldCollectionField,
  FieldContentType,
  type FieldFile,
  type FieldInput,
  type FieldSelection,
  type FieldTextarea,
  InputType,
  SelectionType,
} from "../SparFlexibleForm.types";

const props = defineProps({
  field: {
    type: Object as PropType<FieldCollectionField>,
    required: true,
  },
  toscaPrefix: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(["interface"]);
const model = ref("");

const fieldComp = computed(() => {
  return props.field.field[0];
});

const widthClass = computed(() => {
  const prefix = "contact-form__field";
  // If width is not set OR if field is a selection field -> full width
  if (
    !props.field.width ||
    fieldComp.value.contentTypeUid === FieldContentType.flexible_form_selection
  )
    return prefix;
  if (props.field.width === "25") return prefix + "--small";
  if (props.field.width === "50") return prefix + "--medium";
  if (props.field.width === "75") return prefix + "--large";
  return prefix;
});

const inputType = computed(() => {
  if (fieldComp.value.contentTypeUid === FieldContentType.flexible_form_input_field) {
    const field = fieldComp.value as FieldInput;
    const defaultRes = "text";

    if (!field.type) return defaultRes;
    if (field.type === InputType.Email) return "email";
    if (field.type === InputType.Date) return "date";

    return defaultRes;
  }
});

const handleChildInterface = (childInterface: InputInterface) => {
  emit("interface", childInterface);
};

const getSelectOptions = (options: string[]) => {
  const res = options.map((option) => ({
    key: option,
    value: option,
  }));
  return res;
};

const isRadioOrCheckbox = computed(
  () =>
    fieldComp.value.contentTypeUid === FieldContentType.flexible_form_selection &&
    ((fieldComp.value as FieldSelection).type === SelectionType.Checkbox ||
      (fieldComp.value as FieldSelection).type === SelectionType.Radio),
);

const convertDateForMinMax = (isoDate: string) => {
  return parseInt(isoDate.replaceAll("-", ""));
};

const getDateMinMax = (select: string) => {
  if (
    fieldComp.value.contentTypeUid !== FieldContentType.flexible_form_input_field ||
    inputType.value !== "date"
  )
    return undefined;

  const dateConfiguration = (fieldComp.value as FieldInput).dateConfiguration;
  if (!dateConfiguration) return undefined;

  if (select === "min") {
    if (dateConfiguration.useTodayAsMinDate) {
      return getTodayForMinMax();
    }
    if (dateConfiguration.minDate) {
      return convertDateForMinMax(dateConfiguration.minDate);
    }
  } else if (select === "max") {
    if (dateConfiguration.useTodayAsMaxDate) {
      return getTodayForMinMax();
    }
    if (dateConfiguration.maxDate) {
      return convertDateForMinMax(dateConfiguration.maxDate);
    }
  }

  return undefined;
};

// Get Options for Radio Buttons and Checkboxes
const getOptions = () => {
  const field = fieldComp.value as FieldSelection;
  const options = field.option.map((o) => ({
    val: o,
    label: o,
    name: field.uid,
  }));
  return options;
};

const toscaPrefixComp = computed(() => {
  const fieldName = getSanitizedIdString(fieldComp.value.label);
  return getToscaPrefix(fieldName, props.toscaPrefix);
});
</script>
