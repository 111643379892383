<template>
  <section class="content-slider spar-section">
    <div
      class="content-slider__heading-container"
      :style="{
        backgroundImage: backgroundImage ? 'url(' + backgroundImage.asset + ')' : '',
      }"
    >
      <div
        class="content-slider__heading container"
        :class="`content-slider__heading--${colorTheme}`"
      >
        <spar-heading v-if="heading" class="heading--left" v-bind="heading" />
        <div v-if="text" class="content-slider__text">
          {{ text }}
        </div>
      </div>
    </div>
    <div class="content-slider__slider-container container">
      <spar-slider
        v-if="sliderItems"
        :options="sliderOptions"
        :items-count="sliderItems.length"
        class="content-slider__slider"
      >
        <spar-slider-item
          v-for="(sliderItem, index) in sliderItems"
          :key="index"
          class="content-slider__item"
        >
          <spar-image-text-teaser
            :heading="sliderItem.heading"
            :image="sliderItem.image"
            :text="sliderItem.text"
            :link="sliderItem.link"
          />
        </spar-slider-item>
      </spar-slider>
    </div>
  </section>
</template>

<script lang="ts" setup>
import type { Options as GlideOptions } from "@glidejs/glide";
import { type SparContentSliderProps, SparImageTextTeaser } from "~/components/sections";
import {
  type SparDamAsset,
  SparHeading,
  type SparHeadingProps,
  SparSlider,
  SparSliderItem,
} from "~/components/shared";
import { useScreenInfo } from "~/composables/utils/useScreenInfo";
import { ColorTheme, type SparContentSliderItemProps } from "./SparContentSlider.types";

const { customBreakpoints } = useScreenInfo();

const props: SparContentSliderProps = defineProps({
  heading: {
    type: Object as PropType<SparHeadingProps>,
    default: undefined,
  },
  text: {
    type: String,
    default: undefined,
  },
  colorTheme: {
    type: String as PropType<ColorTheme | null>,
    default: ColorTheme.dark,
  },
  backgroundImage: {
    type: Object as PropType<SparDamAsset>,
    default: undefined,
  },
  sliderItems: {
    type: Array as PropType<SparContentSliderItemProps[]>,
    required: true,
  },
});

const sliderOptions: Ref<Partial<GlideOptions | undefined>> = computed(() => {
  if (!props.sliderItems) return;
  return {
    type: "carousel",
    perView: Math.min(props.sliderItems.length, 4),
    bound: true,
    breakpoints: {
      [customBreakpoints.md]: {
        perView: 1,
      },
      [customBreakpoints.lg]: {
        perView: 2,
      },
      [customBreakpoints.xl]: {
        perView: 3,
      },
    },
  };
});
</script>

<style lang="scss">
@use "./SparContentSlider.scss";
</style>
