import { IconPosition } from "~/types/icon.types";

export const ButtonVariant = {
  primary: "primary",
  secondary: "secondary",
  light: "light",
  clear: "clear",
  custom: "custom",
} as const;
export type ButtonVariant = (typeof ButtonVariant)[keyof typeof ButtonVariant];

export const ButtonSize = {
  small: "small",
  medium: "medium",
  large: "large",
} as const;
export type ButtonSize = (typeof ButtonSize)[keyof typeof ButtonSize];

export const SparButtonType = {
  button: "button",
  submit: "submit",
  reset: "reset",
} as const;
export type SparButtonType = (typeof SparButtonType)[keyof typeof SparButtonType];

export interface SparButtonProps {
  type?: SparButtonType;
  icon?: string;
  iconPosition?: IconPosition;
  variant?: ButtonVariant;
  size?: ButtonSize;
  label?: string;
  fullWidth?: boolean;
  disabled?: boolean;
  iconOnly?: boolean;
  loading?: boolean;
  toscaPrefix?: string;
}
