<template>
  <section class="banner">
    <spar-link v-bind="links" :icon="undefined">
      <div v-if="bannerImage" class="banner__media-img-wrapper">
        <spar-adaptive-image
          v-if="backgroundImg"
          class="banner__media-img"
          :image="backgroundImg"
        />
      </div>
      <div
        class="banner__data-container"
        :class="positionCtaBtn && getButtonContainerClass(positionCtaBtn)"
      >
        <p v-if="!bannerImage" class="banner__headline">{{ headline }}</p>

        <div
          v-if="links?.label"
          class="banner__link-btn btn btn--primary btn--icon btn--icon-right"
          :class="{ 'btn--light': !bannerImage }"
        >
          {{ links.label }}
          <spar-icon-sprite class="link__icon" symbol="arrow-right" />
        </div>
      </div>
    </spar-link>
  </section>
</template>

<script lang="ts" setup>
import {
  SparAdaptiveImage,
  type SparDamAsset,
  SparLink,
  type SparLinkProps,
  SparIconSprite,
} from "~/components/shared";
import { PositionCtaBtn, type SparBannerProps } from "./SparBanner.types";

const props: SparBannerProps = defineProps({
  links: {
    type: Object as PropType<SparLinkProps>,
    default: undefined,
  },
  headline: {
    type: String,
    required: true,
  },
  bannerImage: {
    type: Boolean,
    default: false,
  },
  positionCtaBtn: {
    type: String as PropType<PositionCtaBtn | null>,
    default: undefined,
  },
  backgroundImg: {
    type: Object as PropType<SparDamAsset>,
    default: undefined,
  },
});

//creating the correct css class for the btn
const getButtonContainerClass = (positionCtaBtn: PositionCtaBtn) => {
  if (!props.bannerImage) return;
  if (positionCtaBtn === PositionCtaBtn.center) return "banner__cta-btn--centered";
  if (positionCtaBtn === PositionCtaBtn.right) return "banner__cta-btn--right";
  if (positionCtaBtn === PositionCtaBtn.left) return "banner__cta-btn--left";
};
</script>

<style lang="scss">
@use "./SparBanner.scss";
</style>
