import type { SparSelectOptions } from "~/components/shared";
import useI18n from "~/composables/i18n/useI18n";
import type { Country } from "~/types/occ.types";

export default function () {
  const { $t } = useI18n();

  const getCountryOptions = (countries: Country[]): SparSelectOptions => {
    if (!countries) return [];

    return countries
      .map((country) => ({
        key: country.isocode,
        value: country.name || "",
      }))
      .filter((country) => country.key) as SparSelectOptions;
  };

  const getTitleOptions = (): SparSelectOptions => {
    // TODO use {{baseUrl}}/{{baseSiteId}}/titles?fields=DEFAULT instead to get the titles, but they come unordered from BE
    return [
      { key: "com", value: $t("form.salutation.company") },
      { key: "mr", value: $t("form.salutation.mr") },
      { key: "ms", value: $t("form.salutation.mrs") },
      // { key: "mrandmrs", value: "Mr. and Mrs." },
      // { key: "intersex", value: "Intersex" },
    ];
  };

  const getSecondTitleOptions = (): SparSelectOptions => {
    return [
      { key: "ba", value: $t("form.title.ba") },
      { key: "ing", value: $t("form.title.ing") },
      { key: "magfh", value: $t("form.title.magfh") },
      { key: "di", value: $t("form.title.di") },
      { key: "ma", value: $t("form.title.ma") },
      { key: "difh", value: $t("form.title.difh") },
      { key: "dr", value: $t("form.title.dr") },
      { key: "bsc", value: $t("form.title.bsc") },
      { key: "msc", value: $t("form.title.msc") },
      { key: "mag", value: $t("form.title.mag") },
      { key: "mba", value: $t("form.title.mba") },
      { key: "ddr", value: $t("form.title.ddr") },
      { key: "mmag", value: $t("form.title.mmag") },
      { key: "mas", value: $t("form.title.mas") },
      { key: "llm", value: $t("form.title.llm") },
    ];
  };

  return {
    getCountryOptions,
    getTitleOptions,
    getSecondTitleOptions,
  };
}
