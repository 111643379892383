<template>
  <div class="buy-box-big">
    <div
      v-if="buyBox.prices?.promoText"
      class="buy-box-big__promo-badge"
      data-tosca="pdp-big-promo-badge"
    >
      {{ buyBox.prices?.promoText }}
    </div>

    <div v-if="buyBox.prices" class="buy-box-big__price-wrapper">
      <div
        v-if="buyBox.prices?.recommendedRetailPriceString"
        class="buy-box-big__uvp"
        data-tosca="pdp-uvp-price"
      >
        {{ buyBox.prices.recommendedRetailPriceString }}
      </div>
      <div class="buy-box-big__savings">
        <div
          v-if="buyBox.prices?.savingsString"
          class="buy-box-big__savings-string"
          data-tosca="pdp-price-savings"
        >
          {{ buyBox.prices?.savingsString }}
        </div>
      </div>
      <div
        v-if="buyBox.prices?.insteadPriceString"
        class="buy-box-big__instead-price"
        data-tosca="pdp-instead-price"
      >
        {{ buyBox.prices.insteadPriceString }}
      </div>
      <div class="buy-box-big__price-detail">
        <div
          v-if="buyBox.prices.salesPriceString"
          class="buy-box-big__price"
          data-tosca="pdp-price"
        >
          {{ buyBox.prices.salesPriceString }}
        </div>
        <div
          v-if="buyBox.prices.referencePriceString"
          class="buy-box-big__reference-price"
          data-tosca="pdp-reference-price"
        >
          {{ buyBox.prices.referencePriceString }}
        </div>
      </div>
      <div class="buy-box-big__price-info">
        <div v-if="buyBox.prices.taxText" class="buy-box-big__price-info">
          {{ buyBox.prices.taxText }}
        </div>
        <spar-link
          :link="$t('pdp.product.plus_delivery_costs.link')"
          target="_blank"
          :title="$t('pdp.product.plus_delivery_costs.label')"
          tosca-prefix="pdp-delivery-costs"
          >{{ $t("pdp.product.plus_delivery_costs.label") }}</spar-link
        >
      </div>
      <div class="buy-box-big__tax-info">
        <div v-if="buyBox.prices?.priceValidUntilString" class="buy-box-big__tax-text">
          {{ buyBox.prices.priceValidUntilString }}
        </div>
      </div>

      <ul v-if="documents.length" class="buy-box-big__documents">
        <li v-for="(document, index) in documents" :key="index">
          <spar-link
            :link="document.servletBaseUrl"
            :label="getDocumentLabel(document)"
            target="_blank"
            tosca-prefix="pdp-datasheet"
          />
        </li>
      </ul>

      <!-- HYBS-15857 never show alert if voucherDigital -->
      <spar-alert
        v-if="
          mainInfo.excludeDeliveryAbroad &&
          buyBox.priceType !== DeliveryModeVoucher.VoucherDigital &&
          buyBox.priceType !== PriceType.T
        "
        :type="SparAlertType.info"
        :is-closable="false"
        :tosca-prefix="`buy-box-delivery-abroad-not-possible`"
        class="buy-box-big__delivery-alert"
      >
        {{ $t("plp.product.tile.excludeDeliveryAbroad.label") }}
      </spar-alert>

      <div v-if="additionalServices.length">
        <spar-additional-service
          v-for="(service, index) in additionalServices"
          :key="index"
          v-model="selectedServices"
          :service="service"
          :service-id="service.mainInfo.productId"
          :title="service.mainInfo.name1"
          :description="service.productDescription?.productMaintenanceText?.values[0].value"
          :price-string="service.prices.P?.prices?.salesPriceString"
          tosca-prefix="pdp"
        />
      </div>
    </div>

    <div
      class="buy-box-big__cart-actions"
      :class="{
        'buy-box-big__cart-actions-alert':
          mainInfo.excludeDeliveryAbroad &&
          buyBox.priceType !== DeliveryModeVoucher.VoucherDigital &&
          buyBox.priceType !== PriceType.T,
      }"
    >
      <div class="buy-box-big__area">
        <spar-button
          data-tosca="pdp-favorite"
          class="pdp-product__wishlist"
          :variant="ButtonVariant.custom"
          :title="$t('wishlist.add_item')"
          :aria-label="$t('wishlist.add_item')"
          @click="toggleWishlist"
        >
          <spar-icon-sprite
            :class="{ 'pdp-product__wishlist--heart-filled': wishlistSymbol === 'heart-filled' }"
            :symbol="wishlistSymbol"
          />
        </spar-button>

        <spar-quantity-button
          tosca-prefix="pdp"
          class="buy-box-big__add-to-cart-btn"
          :spar-product-type="sparProductType"
          :boundaries="boundaries"
          :product-info="mainInfo"
          :availability-type="buyBox.priceType"
          :additional-services="selectedServices"
          layout="big"
          @quantity-set="selectedServices = []"
        />
      </div>

      <div
        v-if="
          availability.eComAvailability.currentlyReservable &&
          buyBox.priceType === AvailabilityPossibility.P
        "
        class="buy-box-big__reservable"
      >
        <spar-button
          data-tosca="pdp-reserve-in-market"
          :label="$t('pdp.product.reserve_in_market')"
          class="buy-box-big__reservable-btn btn--secondary"
          @click="reserveInMarket"
          >{{ $t("pdp.product.reserve_in_market") }}</spar-button
        >
      </div>
    </div>

    <div v-if="buyMessage" class="buy-box-big__availability-text" data-tosca="pdp-availability">
      <div class="buy-box-big__availability-text1">
        <spar-icon-sprite :class="getIconStyling(props.buyMessage || '')" symbol="check-rounded" />
        {{ $t(`plp.product.tile.availability.${buyMessage}`) }}
      </div>
      <div v-if="mainInfo?.deliveryTimeException" class="buy-box-big__availability-deliverytime">
        {{ mainInfo.deliveryTimeException }}
      </div>
      <span v-else class="buy-box-big__availability-deliverytime">{{ getDeliveryModeTime() }}</span>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { usePdpProduct } from "~/components/feature/SparPdp/SparPdpProduct/usePdpProduct";
import { SparButton, ButtonVariant, SparIconSprite } from "~/components/shared";
import SparAdditionalService from "~/components/shared/SparAdditionalService/SparAdditionalService.vue";
import { SparAlertType } from "~/components/shared/SparAlert/SparAlert.types";
import SparAlert from "~/components/shared/SparAlert/SparAlert.vue";
import SparLink from "~/components/shared/SparLink/SparLink.vue";
import type { SparQuantityButtonBoundaries } from "~/components/shared/SparQuantityButton/SparQuantityButton.types";
import SparQuantityButton from "~/components/shared/SparQuantityButton/SparQuantityButton.vue";
import useI18n from "~/composables/i18n/useI18n";
import {
  AvailabilityPossibility,
  DeliveryModeVoucher,
  type ProductAvailability,
  type ProductMainInfo,
  PriceType,
  SparProductType,
  type SparProduct,
  type ProductPicture,
  type ProductPriceItem,
} from "~/utils/mdsa/integration/mdsa.types";
import type { SparBuyBoxBigProps } from "./SparBuyBoxBig.types";

const props: SparBuyBoxBigProps = defineProps({
  additionalServices: {
    type: Array as PropType<SparProduct[]>,
    default: () => [],
  },
  availability: {
    type: Object as PropType<ProductAvailability>,
    default: null,
  },
  boundaries: {
    type: Object as PropType<SparQuantityButtonBoundaries>,
    default: () => ({}),
  },
  buyBox: {
    type: Object as PropType<ProductPriceItem>,
    required: true,
  },
  buyMessage: {
    type: String,
    default: "",
  },
  buyable: {
    type: Boolean,
    default: true,
  },
  documents: {
    type: Array as PropType<ProductPicture[]>,
    default: () => [],
  },
  mainInfo: {
    type: Object as PropType<ProductMainInfo>,
    required: true,
  },
  sparProductType: {
    type: String,
    required: true,
  },
});

const { $t } = useI18n();
const { getDocumentLabel } = usePdpProduct();

const selectedServices = ref([]);

const getDeliveryModeTime = () => {
  const deliveryModeMessage = props.mainInfo.deliveryModes?.filter((deliveryMode) => {
    if (
      props.sparProductType === SparProductType.Voucher ||
      props.sparProductType === SparProductType.GWK
    ) {
      return props.buyBox.priceType === deliveryMode.deliveryModeId;
    }

    return props.buyBox.priceType === deliveryMode.associatedAvailabilityType;
  });

  if (deliveryModeMessage?.length) {
    return `${deliveryModeMessage[0].defaultDeliveryTime}`;
  }
};

const getIconStyling = (buymessage: string) => {
  if (!buymessage) return;
  if (buymessage === "buyable") return "buy-box-big__availability-icon--green";
  if (buymessage === "stock") return "buy-box-big__availability-icon--red";
};

const reserveInMarket = () => {
  // TODO do whatever reserveInMarket should do
  Log.log(LogArea.buyBox, "add to market");
};

const isWishlist = ref(false);
const wishlistSymbol = ref("heart");
const toggleWishlist = () => {
  isWishlist.value = !isWishlist.value;
  wishlistSymbol.value = isWishlist.value ? "heart-filled" : "heart";
};
</script>

<style lang="scss">
@use "./SparBuyBoxBig.scss";
</style>
