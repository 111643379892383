import { default as indexyRhJJDcA5AMeta } from "~/pages/content/index.vue?macro=true";
export default [
  {
    name: indexyRhJJDcA5AMeta?.name ?? "contentpage",
    path: indexyRhJJDcA5AMeta?.path ?? "/:slug*",
    meta: indexyRhJJDcA5AMeta || {},
    alias: indexyRhJJDcA5AMeta?.alias || [],
    redirect: indexyRhJJDcA5AMeta?.redirect || undefined,
    component: () => import("~/pages/content/index.vue").then(m => m.default || m)
  }
]