<template>
  <article class="product-tile" data-tosca="plp-article-area">
    <div class="product-tile__top">
      <spar-link class="product-title__link" :link="mainInfo.shopUrl" :title="mainInfo.name2">
        <span class="product-tile__info">
          <span v-if="mainInfo.name1" data-tosca="plp-row1-brand" class="product-tile__brand">
            {{ mainInfo.name1 }}
          </span>
          <span v-if="mainInfo.name2" data-tosca="plp-row2-title" class="product-tile__title">
            {{ mainInfo.name2 }}
          </span>
          <span
            v-if="mainInfo.name3 || mainInfo.name4"
            data-tosca="plp-additional-info"
            class="product-tile__aditional-info"
          >
            {{ mainInfo.name3 }}&nbsp;{{ mainInfo.name4 }}
          </span>
        </span>
      </spar-link>

      <spar-button
        class="product-tile__wishlist btn--clear"
        data-tosca="plp-favorite"
        :variant="ButtonVariant.custom"
        :title="$t('wishlist.add_item')"
        :aria-label="$t('wishlist.add_item')"
        @click="toggleWishlist"
      >
        <spar-icon-sprite :symbol="wishlistSymbol"></spar-icon-sprite>
      </spar-button>

      <spar-link
        class="product-tile__image-link product-title__link"
        :link="mainInfo.shopUrl"
        :title="mainInfo.name2"
        :variant="LinkVariant.link"
      >
        <div class="product-tile__image-wrapper">
          <spar-adaptive-image
            data-tosca="plp-image"
            object-fit="contain"
            class="product-tile__image"
            :src="tilePicture.assetUrl"
            :alt="tilePicture.altText"
          ></spar-adaptive-image>
        </div>
      </spar-link>

      <div v-if="badgeSlots.length && badgeSlots[0]" class="product-tile__badges">
        <div
          v-for="(badge, badgeIndex) in badgeSlots[0].badgeSlot1"
          :key="badgeIndex"
          class="product-tile__badge-img-wrapper"
        >
          <spar-adaptive-image
            class="product-tile__badge-img"
            :src="badge.badgeDamUrl"
            :alt="badge.badgeAltText"
          ></spar-adaptive-image>
        </div>
      </div>
    </div>

    <div class="product-tile__buy-box">
      <spar-buy-box v-bind="props" :box-format="BuyBoxFormat.small" />
    </div>
  </article>
</template>

<script lang="ts" setup>
import SparAdaptiveImage from "~/components/shared/SparAdaptiveImage/SparAdaptiveImage.vue";
import { ButtonVariant } from "~/components/shared/SparButton/SparButton.types";
import SparButton from "~/components/shared/SparButton/SparButton.vue";
import { BuyBoxFormat } from "~/components/shared/SparBuyBox/SparBuyBox.types";
import SparBuyBox from "~/components/shared/SparBuyBox/SparBuyBox.vue";
import SparIconSprite from "~/components/shared/SparIconSprite/SparIconSprite.vue";
import { LinkVariant } from "~/components/shared/SparLink/SparLink.types";
import SparLink from "~/components/shared/SparLink/SparLink.vue";
import useI18n from "~/composables/i18n/useI18n";
import {
  type ProductMainInfo,
  type ProductPicture,
  type ProductAvailability,
  type ProductBadgeSlot,
  AvailabilityPossibility,
  type SparProduct,
} from "~/utils/mdsa/integration/mdsa.types";
import type { SparProductTileProps } from "./SparProductTile.types";

const { $t } = useI18n();

const props: SparProductTileProps = defineProps({
  availabilityTypes: {
    type: Array as PropType<AvailabilityPossibility[]>,
    default: null,
  },
  availability: {
    type: Object as PropType<ProductAvailability>,
    default: null,
  },
  mainInfo: {
    type: Object as PropType<ProductMainInfo>,
    required: true,
  },
  tilePicture: {
    type: Object as PropType<ProductPicture>,
    default: null,
  },
  badgeSlots: {
    type: Array as PropType<ProductBadgeSlot[]>,
    default: null,
  },
  minOrderQuantityP: {
    type: Number,
    default: 0,
  },
  minOrderQuantityT: {
    type: Number,
    default: 0,
  },
  maxOrderQuantityP: {
    type: Number,
    default: 0, // value must always be provided by hybris/mdsa
  },
  maxOrderQuantityT: {
    type: Number,
    default: 0, // value must always be provided by hybris/mdsa
  },
  prices: {
    type: Object as PropType<SparProduct["prices"]>,
    required: true,
  },
  sparProductType: {
    type: String,
    default: "",
  },
});

const wishlistSymbol = ref("heart");

const toggleWishlist = () => {
  wishlistSymbol.value = "heart-filled";
};
</script>

<style lang="scss">
@use "./SparProductTile.scss";
</style>
