<template>
  <div class="sub-navigation-categories">
    <div class="sub-navigation-categories__header">
      <spar-adaptive-image
        class="sub-navigation-categories__icon"
        :src="subNavigation?.categoryIconUrl"
        :alt="subNavigation?.label"
      />
      <p class="sub-navigation-categories__title">{{ subNavigation?.label }}</p>
    </div>

    <hr class="sub-navigation-categories__separator" />

    <ul class="sub-navigation-categories__categories">
      <li
        v-for="(child, index) in subNavigation?.childCategories"
        :key="index"
        class="sub-navigation-categories__category"
      >
        <spar-link
          class="sub-navigation-categories__category-title"
          :class="{ 'sub-navigation-categories__title--active': index === activeIndex }"
          @click="setActiveIndex(index)"
        >
          {{ child.label }}
        </spar-link>

        <transition>
          <ul
            v-show="child.childCategories?.length && index === activeIndex"
            class="sub-navigation-categories__sub-categories"
          >
            <li
              v-for="(subChild, subIndex) in child.childCategories"
              :key="subIndex"
              class="sub-navigation-categories__sub-category"
            >
              <spar-link
                class="sub-navigation-categories__sub-category-title"
                :link="subChild.link"
              >
                {{ subChild.label }}
              </spar-link>
            </li>
          </ul>
        </transition>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import type { NavigationNode } from "~/components/sections/SparHeader/SparMainNavigation/SparMainNavigation.types";
import { SparLink, SparAdaptiveImage } from "~/components/shared";
import type { SparSubNavigationProps } from "./SparSubNavigation.types";

const props: SparSubNavigationProps = defineProps({
  subNavigation: {
    type: Object as PropType<NavigationNode>,
    required: true,
  },
});

const activeIndex = ref<number>(props.subNavigation?.childCategories?.length ? 0 : -1);

const setActiveIndex = (index: number) => {
  activeIndex.value = activeIndex.value === index ? -1 : index;
};
</script>

<style scoped lang="scss">
@use "./SparSubNavigation.scss";
</style>
