<template>
  <main class="pdp__wrapper container">
    <div class="pdp">
      <spar-breadcrumbs :breadcrumbs="breadcrumbs" tosca-prefix="pdp" />
      <section class="pdp__sections">
        <template v-if="content?.[0]?.topSections">
          <render-sections :content="content[0].topSections" />
        </template>
      </section>
      <spar-pdp-product v-if="product" :product="product">
        <template v-if="content?.[0]?.crossSelling" #crossSelling>
          7 - Placeholder Cross Selling
        </template>
      </spar-pdp-product>
      <section id="recom1" class="pdp__turbo-1">9 - Turbo recommendations</section>
      <section class="pdp__sections">
        <template v-if="content?.[0]?.middleSections">
          <render-sections :content="content[0].middleSections" />
        </template>
      </section>
      <section id="recom2" class="pdp__turbo-2">11 - Turbo recommendations</section>
      <section class="pdp__sections">
        <template v-if="content?.[0]?.bottomSections">
          <render-sections :content="content[0].bottomSections" />
        </template>
      </section>
    </div>
  </main>
</template>

<script lang="ts" setup>
import type { ProductPage } from "@shop-storefront/utils";
import SparBreadcrumbs from "~/components/feature/SparBreadcrumbs/SparBreadcrumbs.vue";
import RenderSections from "~/components/sections/RenderSections/RenderSections.vue";
import { ContentstackPageParams } from "~/plugins/contentstack/contentstack.types";
import ContentType from "~/utils/contentstack/constants/content-types.constants";
import { productPageReferences } from "~/utils/contentstack/constants/references.constants";
import type { SparProduct } from "~/utils/mdsa/integration/mdsa.types";
import { resolveMdsaProduct } from "~/utils/mdsa/resolver/mdsa-product.resolver";
import SparPdpProduct from "./SparPdpProduct/SparPdpProduct.vue";

const { $contentstack, $indexes } = useNuxtApp();
const product = ref<SparProduct | null>(null);

const {
  params: { productId },
  fullPath,
} = useRoute();

const { data: content } = await useAsyncData(ContentType.productPage, async () => {
  return await $contentstack.getEntries<ProductPage>({
    type: ContentType.productPage,
    customQuery: {
      [ContentstackPageParams.slug]: `p/${productId}`,
    },
    includeReference: productPageReferences as string[],
  });
});

const breadcrumbs = computed(() => {
  // const mainCategory = product.value?.categories?.[0];

  // TODO: Add product.categoryId when available
  // if (mainCategory?.categoryId) return $indexes.getBreadcrumbs(mainCategory?.categoryId);
  return $indexes.getBreadcrumbs("bltc4188012f2a10f4c"); // Dummy breadcrumbs
});

// const { data: product } = await $externalFetch<SparProduct>(
//   `https://mockoon-mdsa.apps.ocpt01.spar.local.at/mdsa/v3/AT/products/${productId}`
// );

onMounted(() => {
  // TODO - little hack to enable prefill of add-to-cart-button until MDSA-service is not available
  setTimeout(async () => {
    try {
      const fetchedData = await $fetch(
        `https://mockoon-mdsa.apps.ocpt01.spar.local.at/mdsa/v3/AT/p/${productId}`,
      );

      //@ts-expect-error Only mock data
      product.value = await resolveMdsaProduct(fetchedData.data.products[0]);
    } catch {
      // show error page
      const { show404Page } = useErrorPage();
      show404Page(ErrorPageType.product, fullPath);
    }
  }, 400);

  // TODO !!!! use server side call if we do not use mockdata from mockoon any longer
  // const product = $mdsa.searchMdsaProduct({
  //   query: productId as string,
  // });
});

// if (!product.value) {
//   console.log(product.value, "baa");
//   const { show404Page } = useErrorPage();
//   show404Page(ErrorPageType.content);
// }
</script>

<style lang="scss">
@use "./SparPdp.scss";
</style>
