<template>
  <section class="cookie-list">
    <spar-heading v-if="heading" v-bind="heading"></spar-heading>
    <div class="cookie-list__content">
      <div :id="name"></div>
    </div>
  </section>
</template>

<script lang="ts" setup>
import { SparHeading, type SparHeadingProps } from "~/components/shared";
import { useLoadScript } from "~/composables/load-script/useLoadScript";
import type { SparCookieListProps } from "./SparCookieList.types";

const props: SparCookieListProps = defineProps({
  apiLink: {
    type: String,
    required: true,
  },
  heading: {
    type: Object as PropType<SparHeadingProps>,
    default: undefined,
  },
  name: {
    type: String,
    required: true,
  },
});

onMounted(() => {
  if (!props.apiLink) return;

  const { loadScript } = useLoadScript();
  loadScript(props.apiLink, {}, true);
});
</script>

<style lang="scss">
@use "./SparCookieList.scss";
</style>
