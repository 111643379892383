// Adapted from jquery.pstrength.spar.js

const options = {
  minChar: 8,
  scores: [10, 15, 30, 40],
};

export const Strength = {
  veryWeak: "veryWeak",
  weak: "weak",
  medium: "medium",
  strong: "strong",
  veryStrong: "veryStrong",
} as const;
export type Strength = (typeof Strength)[keyof typeof Strength];

export const checkPasswordStrength = (password: string) => {
  const score = runPassword(password);

  if (score <= options.scores[0]) return Strength.veryWeak;
  if (score > options.scores[0] && score <= options.scores[1]) return Strength.weak;
  if (score > options.scores[1] && score <= options.scores[2]) return Strength.medium;
  if (score > options.scores[2] && score <= options.scores[3]) return Strength.strong;
  return Strength.veryStrong;
};

const runPassword = (password: string) => {
  let score = 0;

  // PASSWORD LENGTH
  if (password.length < options.minChar) {
    // Password too short
    score = score - 100;
  } else if (password.length >= options.minChar && password.length <= options.minChar + 2) {
    // Password Short
    score = score + 6;
  } else if (password.length >= options.minChar + 3 && password.length <= options.minChar + 4) {
    // Password Medium
    score = score + 12;
  } else if (password.length >= options.minChar + 5) {
    // Password Large
    score = score + 18;
  }
  if (password.match(/[a-z]/)) {
    // [verified] at least one lower case letter
    score = score + 1;
  }
  if (password.match(/[A-Z]/)) {
    // [verified] at least one upper case letter
    score = score + 5;
  }

  // NUMBERS
  if (password.match(/\d+/)) {
    // [verified] at least one number
    score = score + 5;
  }
  if (password.match(/(.*[0-9].*[0-9].*[0-9])/)) {
    // [verified] at least three numbers
    score = score + 7;
  }

  // SPECIAL CHAR
  if (password.match(/.[!,@,#,$,%,^,&,*,?,_,~]/)) {
    // [verified] at least one special character
    score = score + 5;
  }
  // [verified] at least two special characters
  if (password.match(/(.*[!,@,#,$,%,^,&,*,?,_,~].*[!,@,#,$,%,^,&,*,?,_,~])/)) {
    score = score + 7;
  }

  // COMBOS
  if (password.match(/([a-z].*[A-Z])|([A-Z].*[a-z])/)) {
    // [verified] both upper and lower case
    score = score + 2;
  }
  if (password.match(/([a-zA-Z])/) && password.match(/([0-9])/)) {
    // [verified] both letters and numbers
    score = score + 3;
  }
  // [verified] letters, numbers, and special characters
  if (
    password.match(/([a-zA-Z0-9].*[!,@,#,$,%,^,&,*,?,_,~])|([!,@,#,$,%,^,&,*,?,_,~].*[a-zA-Z0-9])/)
  ) {
    score = score + 3;
  }
  return score;
};
