<template>
  <div class="content-page">
    <template v-if="content?.[0]?.sections">
      <render-sections :content="content[0].sections" />
    </template>
  </div>
</template>

<script setup lang="ts">
import type { LogoutPage } from "@shop-storefront/utils";
import RenderSections from "~/components/sections/RenderSections/RenderSections.vue";
import useGigya from "~/composables/auth/useGigya";
import { ContentstackPageParams } from "~/plugins/contentstack/contentstack.types";
import { useAuthStore } from "~/stores/auth.store";
import ContentType from "~/utils/contentstack/constants/content-types.constants";
import { logoutPageReferences } from "~/utils/contentstack/constants/references.constants";

const { $contentstack } = useNuxtApp();
const authStore = useAuthStore();
const router = useRouter();
const { executeWhenIsReady } = useGigya();
const { getSlug } = useRoutes();

const { data: content, refresh: refreshContent } = await useAsyncData("logoutPage", async () => {
  return await $contentstack.getEntries<LogoutPage>({
    type: ContentType.logoutPage,
    customQuery: {
      [ContentstackPageParams.slug]: getSlug("logout"),
    },
    includeReference: logoutPageReferences as string[],
  });
});

const logoutCallback = () => {
  authStore.logout();
  router.push("/");
};

onMounted(() => {
  refreshContent();

  executeWhenIsReady(() => {
    window.gigya.accounts.logout({
      callback: logoutCallback,
    });
  });
});
</script>
