<template>
  <div
    v-if="cartContext && entry.product"
    :id="`product-${entry.product.code}`"
    class="product-row"
    data-tosca="cart-product-row"
  >
    <div
      v-if="showCategoryTitle && entry.product.rootCategory?.name"
      class="product-row__category-title-wrapper"
    >
      <div
        class="product-row__category-title"
        :data-tosca="`product-row-category-${entry.product.rootCategory.name.toLowerCase()}`"
      >
        {{ entry.product.rootCategory.name }}
      </div>
    </div>

    <div class="product-row__content">
      <div
        v-if="
          cartContext.updateCartItemStatus?.entry?.product?.code === entry.product.code &&
          cartContext.updateCartItemStatus &&
          cartContext.updateCartItemStatus.statusCode !== NotificationType.Success
        "
        class="product-row__update-error"
      >
        <spar-alert
          :type="SparAlertType.error"
          :tosca-prefix="`cart-alert-${cartContext.updateCartItemStatus.statusCode}`"
          @close="cartContext.setCartItemStatus(null)"
        >
          <span
            v-html="
              $t(`cart.product.update.${cartContext.updateCartItemStatus.statusCode}`, {
                productName: entry.product.name || '',
                givenValue: updatedAmount.toString(),
                inventoryValue: cartContext.updateCartItemStatus.quantity?.toString() || '',
                maxOrderQuantityForProductType:
                  cartContext.updateCartItemStatus.maxOrderQuantityForProductType,
                sparProductTypeName: cartContext.updateCartItemStatus.sparProductTypeName,
              })
            "
          >
          </span>
        </spar-alert>
      </div>
      <div class="product-row__left">
        <div class="product-row__image-container">
          <spar-link :link="entry.product.url" :title="entry.product.name">
            <spar-adaptive-image
              v-if="entry.product.images && entry.product.images.length"
              class="product-row__image"
              :src="entry.product.images[0].url"
              :alt="entry.product.images[0].altText"
              tosca-prefix="cart-product"
            />
          </spar-link>
          <spar-button
            v-if="entry.product.code"
            data-tosca="cart-favorite"
            class="product-row__favorite-btn btn--clear"
            icon-only
            :icon="isFavorite ? 'heart-filled' : 'heart'"
            :aria-pressed="isFavorite"
            :variant="ButtonVariant.custom"
            @click="addToFavorites(entry.product.code)"
          ></spar-button>
        </div>
        <div class="product-row__info-container">
          <spar-link
            class="product-row__product-link"
            :link="entry.product.url"
            :title="entry.product.name"
            :label="entry.product.name"
          >
            <div class="product-row__name-1" data-tosca="cart-product-row1">
              {{ entry.product.name }}
            </div>
            <div class="product-row__name-2" data-tosca="cart-product-row2">
              {{ entry.product.name2 }}
            </div>
          </spar-link>
          <div class="product-row__delivery-info" data-tosca="cart-product-info">
            <span
              class="product-row__stock-indicator"
              data-tosca="cart-delivery-info-icon"
              :class="{
                'product-row__stock-indicator--in-stock': isInStock,
                'product-row__stock-indicator--low-stock': isLowStock,
                'product-row__stock-indicator--out-of-stock': isOutOfStock,
              }"
            ></span
            >{{ $t("cart.entry.stock_level." + entry.product.stock?.stockLevelStatus) }}
            <spar-adaptive-image
              v-if="entry.deliveryMode?.icon"
              class="product-row__delivery-mode"
              :src="entry.deliveryMode.icon.url"
              :alt="entry.deliveryMode?.name"
              :tosca-prefix="'cart-product-delivery-' + entry.deliveryMode?.name"
            />
          </div>
          <spar-button
            data-tosca="cart-delete-product"
            class="product-row__delete-btn btn--clear"
            icon-position="left"
            icon="trash"
            :variant="ButtonVariant.custom"
            @click="deleteProduct()"
            >{{ $t("cart.entry.delete.btn") }}</spar-button
          >
        </div>
      </div>
      <div class="product-row__right">
        <div class="product-row__price-container">
          <span class="product-row__price" data-tosca="cart-single-price">{{
            entry.basePrice?.formattedValue
          }}</span>
        </div>
        <div class="product-row__quantity-container">
          <spar-quantity-button
            v-if="entry.deliveryMode?.code"
            :product-info="mainInfo"
            :spar-product-type="entry.product.sparProductType"
            :cart-entry-update="true"
            :given-base-store="cartContext.bs"
            :availability-type="entry.deliveryMode.code"
            :entry-number="entry.entryNumber"
            :boundaries="getMinMax()"
          ></spar-quantity-button>
        </div>
        <div class="product-row__totals-container">
          <span class="product-row__price" data-tosca="cart-total-price">{{
            entry.totalPrice?.formattedValue
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {
  ButtonVariant,
  NotificationType,
  SparAdaptiveImage,
  SparAlert,
  SparAlertType,
  SparButton,
  SparLink,
} from "~/components/shared";
import SparQuantityButton from "~/components/shared/SparQuantityButton/SparQuantityButton.vue";
import { useCheckoutStore } from "~/stores/checkout.store";
import type { Cart, OrderEntry } from "~/types/occ.types";
import { StockLevel } from "./SparProductRow.types";

const props = defineProps({
  entry: {
    type: Object as PropType<OrderEntry>,
    required: true,
  },
  cart: {
    type: Object as PropType<Cart>,
    required: true,
  },
  showCategoryTitle: {
    type: Boolean,
    required: true,
  },
});

const updatedAmount = ref(0);
const isFavorite = ref(false);

const { cartContext } = storeToRefs(useCheckoutStore());

const getMinMax = () => {
  return {
    min: props.entry.product?.minOrderQuantity || 0,
    max: props.entry.product?.maxOrderQuantity || 0,
  };
};

const isInStock = computed(
  () => props.entry.product?.stock?.stockLevelStatus === StockLevel.inStock,
);
const isLowStock = computed(
  () => props.entry.product?.stock?.stockLevelStatus === StockLevel.lowStock,
);
const isOutOfStock = computed(
  () => props.entry.product?.stock?.stockLevelStatus === StockLevel.outOfStock,
);

const mainInfo = computed(() => {
  return {
    name2: props.entry.product?.name2 || "",
    productId: props.entry.product?.code || "",
    salesUnit: props.entry.product?.salesUnit || "",
  };
});

const deleteProduct = async () => {
  if (!props.entry.entryNumber) return;
  await cartContext.value?.removeFromCart(props.entry.entryNumber);
  await cartContext.value?.validateCart();
};

// TODO add/refactor functionality
const addToFavorites = (productCode: string) => {
  isFavorite.value = !isFavorite.value;
  Log.log(LogArea.cart, "add product to favorites", productCode);
};
</script>

<style lang="scss">
@use "./SparProductRow.scss";
</style>
